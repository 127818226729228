import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { IPackagingGroup, IPackagingGroups } from "../models/PackagingGroups";
import { IProductCatalogLookupDto } from "../../products/models/ProductCatalog";
import {  IExportPackagingGroupItemsCommand } from "../models/PackagingGroups";

async function getPackagingGroups(
  plantCode: string
): Promise<IPackagingGroups> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IPackagingGroups>(
      `${backendConfig.url}/packaginggroups/${plantCode}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getPackagingGroups:", error);
    throw error;
  }
}

async function getPackagingGroup(
  plantCode: string,
  packagingGroupName: string
): Promise<IPackagingGroup> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IPackagingGroup>(
      `${backendConfig.url}/packaginggroups/${plantCode}/${packagingGroupName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getPackagingGroup:", error);
    throw error;
  }
}

async function deletePackagingGroup(
  plantCode: string,
  packagingGroupName: string
): Promise<IPackagingGroup> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.delete<IPackagingGroup>(
      `${backendConfig.url}/packaginggroups/${plantCode}/${packagingGroupName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in deletePackagingGroup:", error);
    throw error;
  }
}

async function deletePackagingGroupItem(
  plantCode: string,
  packagingGroupName: string,
  packagingGroupItemName: string
): Promise<IPackagingGroup> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.delete<IPackagingGroup>(
      `${backendConfig.url}/packaginggroupitems/${plantCode}/${packagingGroupName}/${packagingGroupItemName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in deletePackagingGroupItem:", error);
    throw error;
  }
}

async function setPackagingGroup(
  plantCode: string,
  packagingGroupData: any
): Promise<IPackagingGroups> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.put<IPackagingGroups>(
      `${backendConfig.url}/packaginggroups/${plantCode}`,
      packagingGroupData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in setPackagingGroup:", error);
    throw error;
  }
}

async function setPackagingGroupItem(
  plantCode: string,
  packagingGroupItemData: any
): Promise<IPackagingGroups> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.put<IPackagingGroups>(
      `${backendConfig.url}/packaginggroupitems/${plantCode}`,
      packagingGroupItemData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in setPackagingGroupItem:", error);
    throw error;
  }
}

async function getPackagingLookupsWorker(actionRoute: string): Promise<{ key: string; description: string }[]> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.get<IProductCatalogLookupDto[]>(
      `${backendConfig.url}/packaginglookups/${actionRoute}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error getting packaging lookups (" + actionRoute + "):", error);
    throw error;
  }
}

async function getPackageShapesLookup(): Promise<{ key: string; description: string }[]> {
  return await getPackagingLookupsWorker("packageshapes");
}

async function getBundleTypesLookup(): Promise<{ key: string; description: string }[]> {
  return await getPackagingLookupsWorker("bundletypes");
}

async function getProductShapesLookup(): Promise<{ key: string; description: string }[]> {
  return await getPackagingLookupsWorker("productshapes");
}

async function getModifierEffectsLookup(): Promise<{ key: string; description: string }[]> {
  return await getPackagingLookupsWorker("modifiereffects");  
}  

async function getModifierFormulasLookup(): Promise<{ key: string; description: string }[]> {
  return await getPackagingLookupsWorker("modifierformulas");
}

async function exportPackagingGroup(factory: string, packagingGroupName: string) {
  const backendConfig = await getConfig("backend");  
  return axios.get(
      `${backendConfig.url}/packaginggroups/${factory}/${packagingGroupName}/export/all`,
      {
        responseType: "blob",
      }
  ).then((response) => response.data);
}

async function exportPackagingGroupItems(factory: string, packagingGroupName: string, exportCommand: IExportPackagingGroupItemsCommand) {
  const backendConfig = await getConfig("backend");  
  return axios.post(
      `${backendConfig.url}/packaginggroups/${factory}/${packagingGroupName}/export`,
      exportCommand,
      {
          headers: {
              "Content-Type": "application/json",
          },
          responseType: "blob",
      }
  ).then((response) => response.data);
}

async function importPackagingGroup(factory: string, packagingGroupName: string, file:any, dryRun:boolean) {
  const backendConfig = await getConfig("backend");  
  const formData = new FormData();
  formData.append("file", file);
  return axios.put(
    `${backendConfig.url}/packaginggroups/${factory}/${packagingGroupName}/import?dryRun=${dryRun}`, formData,{
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  ).then((response) => response.data);
}

export {
  getPackagingGroups,
  getPackagingGroup,
  deletePackagingGroup,
  deletePackagingGroupItem,
  setPackagingGroup,
  setPackagingGroupItem,
  getPackageShapesLookup,
  getBundleTypesLookup,
  getProductShapesLookup,
  getModifierEffectsLookup,
  getModifierFormulasLookup,
  exportPackagingGroup,
  exportPackagingGroupItems,
  importPackagingGroup,
};
