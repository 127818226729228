function countItemOccurrences(contents: string[]) {
  const counts: Record<string, number> = {};

  for (const item of contents) {
    if (counts[item]) {
      counts[item]++;
    } else {
      counts[item] = 1;
    }
  }

  return counts;
}

export default countItemOccurrences;
