import { Modal } from "react-bootstrap";
import ModifierAddDialogHeader from "./ModifierDialogHeader";
import ModifierAddForm from "./ModifierAddForm";

const ModifierAddDialog = ({
  onHide,
  onSuccess,
  plant,
  productGroup,
  productType,
  editModifierData,
}: any) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <ModifierAddDialogHeader />
      <ModifierAddForm
        onHide={onHide}
        onSuccess={onSuccess}
        plant={plant}
        productGroup={productGroup}
        productType={productType}
        editModifierData={editModifierData}
      />
    </Modal>
  );
};

export default ModifierAddDialog;
