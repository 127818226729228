import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, useLocation } from 'react-router-dom';
import { IAlertModel, IProductGroup } from '../../models/LeadTimes';
import { ProductEditDialog } from '../product-edit-dialog/ProductEditDialog';
import { ProductsLoadingDialog } from '../products-loading-dialog/ProductsLoadingDialog';
import { ProductsUpdateLeadtimeDialog } from '../products-update-leadtime-dialog/ProductsUpdateLeadtimeDialog';
import { ProductsUpdateExtraLeadtimeDialog } from '../products-update-leadtime-dialog/ProductsUpdateExtraLeadtimeDialog';
import ProductsCard from '../ProductsCard';
import ProductsProvider from '../ProductsContext';
import { useQuery } from '../../components/QueryParamHook';
import * as actions from '../redux/ProductsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LeadTimesImportModal from '../components/leadtimes-import-modal/LeadTimesImportModal';
import { usePlant } from '../../components/PlantHook';
import { isScheduledLeadTimesEnabled } from '../../utils/IsAvailableOnFeatureFlag';

interface Props {
  history: RouteComponentProps['history'];
}

const ProductGroupPage = (props: Props) => {
  const query = useQuery();
  const plantCode = usePlant();
  const dispatch = useDispatch();
  const { state } = useLocation<{ productGroup: IProductGroup }>();
  const [productGroupName, setProductGroupName] = useState<string>('');
  const [showImportModal, setShowImportModal] = useState(false);
  const [hasLeadTimesUpdated, setHasLeadTimesUpdated] = useState(false);
  const { alert } = useSelector(
    (state: any) => ({
      alert: (state.products?.error as IAlertModel) || null,
    }),
    shallowEqual
  );

  const productsUIEvents = {
    openEditArticleDialog: (product: any) => {
      //todo: isPremiumLayFlat is obsolete, can be removed
      const { id, isPremiumLayFlat } = product;
      props.history.push(
        `/leadtimes/${id}/edit?isPremiumLayFlat=${isPremiumLayFlat}`
      );
    },
    openUpdateArticlesLeadtimeBatchDialog: (productGroupName: string) => {
      props.history.push(`/leadtimes/updateBatchLeadtimes`);
    },
    openUpdateArticlesExtraLeadtimeBatchDialog: (productGroupName: string) => {
      props.history.push(`/leadtimes/updateBatchExtraLeadtimes`);
    },
  };

  const downloadLeadTimesHandler = async () => {
    await dispatch(
      actions.downloadLeadTimesPerProductGroup(plantCode, productGroupName)
    );
  };

  const showImportModalHandler = () => {
    setShowImportModal(true);
  };

  useEffect(() => {
    if (state?.productGroup.name) {
      setProductGroupName(state.productGroup.name);
    }
  }, [state?.productGroup.name]);

  return (
    <ProductsProvider
      productsUIEvents={productsUIEvents}
      productGroupName={state?.productGroup.name ?? productGroupName}
    >
      <LeadTimesImportModal
        show={showImportModal}
        setShowImportModal={setShowImportModal}
        setHasLeadTimesUpdated={setHasLeadTimesUpdated}
        plantCode={plantCode}
        canScheduleLeadTimes={isScheduledLeadTimesEnabled} // schedule lead times feature flag - set to true when ready for production
      />
      <ProductsLoadingDialog />
      <Route
        path='/leadtimes/:id/edit'
        render={({ history, match }) => (
          <ProductEditDialog
            show={match != null}
            id={match?.params.id ?? ''}
            isPremiumLayFlat={
              query.get('isPremiumLayFlat') === undefined
                ? undefined
                : query.get('isPremiumLayFlat') === 'true'
            }
            onHide={() => {
              history.push(`/leadtimes/${productGroupName}`);
            }}
          />
        )}
      />
      <Route
        path='/leadtimes/updateBatchLeadtimes'
        render={({ history, match }) => (
          <ProductsUpdateLeadtimeDialog
            show={match != null}
            onHide={() => {
              history.push(`/leadtimes/${productGroupName}`);
            }}
            maxProductsToDisplay={10}
          />
        )}
      />
      <Route
        path='/leadtimes/updateBatchExtraLeadtimes'
        render={({ history, match }) => (
          <ProductsUpdateExtraLeadtimeDialog
            show={match != null}
            onHide={() => {
              history.push(`/leadtimes/${productGroupName}`);
            }}
            maxProductsToDisplay={10}
          />
        )}
      />
      <ProductsCard
        leadTimesAlert={alert}
        downloadLeadTimesHandler={downloadLeadTimesHandler}
        showImportModalHandler={showImportModalHandler}
        hasLeadTimesUpdated={hasLeadTimesUpdated}
      />
    </ProductsProvider>
  );
};

export default ProductGroupPage;
