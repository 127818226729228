/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, ReactElement } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthPage } from "./authentication";

export const Routes: FC<{ pathname: string }> = ({ pathname }): ReactElement => {
  const isAuthorized = useIsAuthenticated();
  const { inProgress } = useMsal();
  const props: any = {};

  return (
    <Switch>
      {!isAuthorized && inProgress === 'none' && (
        <>
          <Redirect to="/auth/login" />
          <Route>
            <AuthPage />
          </Route>
        </>
      )}
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        // if authorized and current path is `/auth/login` then redirect to `/delivery-options`, this covers
        // the case when user logs in for first time. Page refreshes will redirect users to the current path.
        // TODO: Would be nice to add a Home/Welcome screen instead of delivery options screen
        <Redirect from="/auth" to={pathname?.includes("/auth") ? "/delivery-options" : pathname} />
      )}
      <Route path="/error" component={ErrorsPage} />      
      {isAuthorized && (
        <Layout>
          <BasePage {...props} />
        </Layout>
      )}
    </Switch>
  );
};
