import { useState, useEffect, ChangeEvent } from 'react';
import { FieldArray } from "formik";
import { Spinner } from "react-bootstrap";
import { Checkbox } from "../components/CheckboxSimple";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { getCatalogLookupsBulk } from '../products/api/ProductCatalogApi';
import { IProductCatalogLookupBulkDto, IProductCatalogLookupDto, ProductCatalogLookupType } from '../products/models/ProductCatalog';

function SelectProductCombinations({ productCodes = [] as { key: string, description: string }[], onProductCombinationsChanged = (productCombinationsInput: any) => {}, setRequiredOptions = (options: string[]) => {}, isLocked = false, excludedProductOptions = [] as string[] }) {
  interface IProductOptionValue {
    key: string;
    description: string;
}

  interface IProductOptionWithValues {
      productOption: {
          key: string;
          description: string;
      };
      possibleOptionValues: IProductOptionValue[];
  }

  const [isLoadingProductOptions, setIsLoadingProductOptions] = useState(false);

  //allValues
  const [productOptionsWithValues, setProductOptionsWithValues] = useState<IProductOptionWithValues[]>([]);
  const [bulkLookupsHive, setBulkLookupsHive] = useState<Array<IProductCatalogLookupBulkDto>>([]);

  //selectedValues
  const [productCodesWithDescription, setProductCodesWithDescription] = useState<Array<{ key: string, description: string }>>([]);
  const [selectedProductCodes, setSelectedProductCodes] = useState<Array<{ key: string; description: string }>>([]);
  const [selectedProductOptionsWithValues, setSelectedProductOptionsWithValues] = useState<{ [productOption: string]: string[] }>({})
  const [selectedRequiredOptions, setSelectedRequiredOptions] = useState<Array<{ productOption: string; required: boolean }>>([]);
  const [isSelectAllProductCodesChecked, setIsSelectAllProductCodesChecked] = useState(false);

  const getCatalogOptionKeys = () => {
    const productCodesKeys = productCodes.map(x => x.key);
    const product_ids: (number | null)[] = [];
    const optionKeys: IProductCatalogLookupDto[] = [];
    bulkLookupsHive.forEach(dto => {
      if (dto.lookupType === ProductCatalogLookupType.productCode || dto.lookupType.toString() === "productCode") {
        const ids = dto.children.filter(l => productCodesKeys.some(productCode => l.key.toLowerCase() == productCode.toLowerCase()))
          .map(l => l.id);
        product_ids.push(...ids);
      }
    });
    bulkLookupsHive.forEach(dto => {
      if (dto.lookupType === ProductCatalogLookupType.optionKey || dto.lookupType.toString() === "optionKey") {
        if (product_ids.includes(dto.parentId)) {
          optionKeys.push(...dto.children.filter(x => !optionKeys.some(y => y.key == x.key)));
        }
      }
    });
    return optionKeys;
  };

  const getCatalogOptionValues = (optionKey: string) => {
    const productCodesKeys = productCodes.map(x => x.key);
    const product_ids: (number | null)[] = [];
    const optionOptions: IProductCatalogLookupDto[] = [];
    bulkLookupsHive.forEach(dto => {
      if (dto.lookupType === ProductCatalogLookupType.productCode || dto.lookupType.toString() === "productCode") {
        const ids = dto.children.filter(l => productCodesKeys.some(productCode => l.key.toLowerCase() == productCode.toLowerCase()))
          .map(l => l.id);
          product_ids.push(...ids);
      }
    });
    const option_ids: (number | null)[] = [];
    bulkLookupsHive.forEach(dto => {
      if (dto.lookupType === ProductCatalogLookupType.optionKey || dto.lookupType.toString() === "optionKey") {
        if (product_ids.includes(dto.parentId)) {
          const ids = dto.children.filter(l => l.key.toLowerCase() == optionKey.toLowerCase())
            .map(l => l.id);
          option_ids.push(...ids);
        }
      }
    });
    bulkLookupsHive.forEach(dto => {
      if (dto.lookupType === ProductCatalogLookupType.optionValue || dto.lookupType.toString() === "optionValue") {
        if (option_ids.includes(dto.parentId)) {
          optionOptions.push(...dto.children.filter(x => !optionOptions.some(y => y.key == x.key)));
        }
      }
    });
    return optionOptions;
  };

  async function loadAllProductOptionsAndPossibleProductValuesForSelectedProductType(): Promise<IProductOptionWithValues[]> {
    try {
      if (productCodes?.length > 0) {
        // Step 1: Fetch all product options for the selected product type
        const productOptions = getCatalogOptionKeys();

        const productOptionsWithRequired = productOptions
          .filter(x => !excludedProductOptions.includes(x.key) && x.isRequired === true)
          .map(x => ({ productOption: x.key, required: true }));

        setSelectedRequiredOptions(productOptionsWithRequired);

        setRequiredOptions(productOptionsWithRequired.filter(x => x.required).map(x => x.productOption));
    
        // Step 2: For each product option, fetch all possible values and format them
        const optionsWithValuesPromises = productOptions.map(async (option) => {
            const values = getCatalogOptionValues(option.key);

            // Create a Set to track unique keys
            const uniqueKeys = new Set();
            const possibleOptionValues = values.filter(value => {
                const isUnique = !uniqueKeys.has(value.key);
                if (isUnique) uniqueKeys.add(value.key);
                return isUnique;
            }).map(value => ({
                key: value.key,
                description: (value.description === "" || value.description == null) ? value.key : value.description,
            }));

            return {
                productOption: {
                    key: option.key,
                    description: option.description,
                },
                possibleOptionValues,
            };
        });

        const optionsWithValues = await Promise.all(optionsWithValuesPromises);
        return optionsWithValues;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error in loadAllProductOptionsAndPossibleProductValuesForSelectedProductType:", error);
      setIsLoadingProductOptions(false);
      throw error;
    }
  }

  const handleProductCodesDropdownChange = (selectedProductCodes: Array<{ key: string; description: string }>) => {
    setSelectedProductCodes(selectedProductCodes);
  };

  const handleProductOptionsWithValuesDropdownChange = (optionKey: string) => (selectedOptions: Array<{ key: string; description: string }>) => {
    setSelectedProductOptionsWithValues(prev => ({
      ...prev,
      [optionKey]: selectedOptions.map(option => option.key),
    }));
  };

  const handleSelectAllProductCodesCheckbox = () => {
    const newState = !isSelectAllProductCodesChecked;
    setIsSelectAllProductCodesChecked(newState);

    if (newState) {
      setSelectedProductCodes(productCodes);
    } else {
      setSelectedProductCodes([]);
    }
  };

  useEffect(() => {
    const fetchProductOptionsWithValues = async () => {
      try {
        const response = await loadAllProductOptionsAndPossibleProductValuesForSelectedProductType();
        setProductOptionsWithValues(response);
        setIsLoadingProductOptions(false);
      } catch (error) {
        console.error("Failed to load product options with values:", error);
      }
    };
    setIsLoadingProductOptions(true);
    setIsSelectAllProductCodesChecked(false);
    setSelectedProductOptionsWithValues({});
    fetchProductOptionsWithValues();
  }, [productCodes]);

  useEffect(() => {
    const fetchBulkLookups = async () => {
      var hive = await getCatalogLookupsBulk();
      setBulkLookupsHive(hive);
    }
    fetchBulkLookups();
  }, []);

  const getProductCodeDescription = (productCode: string): string => {
    let response = "";
    bulkLookupsHive.forEach(dto => {
      if (dto.lookupType === ProductCatalogLookupType.productCode || dto.lookupType.toString() === "productCode") {
        let currIdx: number = dto.children.findIndex(l => l.key.toLowerCase() == productCode.toLowerCase());
        if (currIdx >= 0)
        {
          response = dto.children[currIdx].description;
        }
      }
    });
    return response;
  };
  
  useEffect(() => {
    const validatedSelectedProductCodes = selectedProductCodes.filter(selectedCode =>
      productCodes.includes(selectedCode)
    );

    setProductCodesWithDescription(productCodes.map(x => ({ key: x.key, description: getProductCodeDescription(x.key) })));

    setSelectedProductCodes(validatedSelectedProductCodes);
  }, [productCodes]);

  
  useEffect(() => {
    handleProductCombinationsChanged();
  }, [selectedProductCodes, selectedRequiredOptions, selectedProductOptionsWithValues]);

  const handleProductCombinationsChanged = () => {
    const productCombinations = {
      productCodes: selectedProductCodes.map(productCode => productCode.key),
      options: Object.entries(selectedProductOptionsWithValues).map(([optionKey, optionValues]) => ({
        optionName: optionKey,
        isRequired: !!selectedRequiredOptions.find(option => option.productOption === optionKey)?.required,
        optionValues: optionValues
      })).filter(option => option.optionValues.length > 0)
    };
    onProductCombinationsChanged(productCombinations);
  };

  interface ErrorMessages {
    [key: string]: string;
  }

  const [pagesErrorMessages, setPagesErrorMessages] = useState<ErrorMessages>({});

  const handlePagesInputChange = (numericOption: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    var isValid = validatePagesInput(value);
    if (isValid != null && isValid)
    {
      const numberArray = value.split(',')
        .map(s => s.trim())
        .filter(s => isInteger(s));

      setSelectedProductOptionsWithValues(prev => ({
        ...prev,
        [numericOption]: numberArray,
      }));
      setPagesErrorMessages(prevMessages => ({
        ...prevMessages,
        [numericOption]: ""
    }));
    }
    else {
      setPagesErrorMessages(prevMessages => ({
        ...prevMessages,
        [numericOption]: "Must be a comma-separated list of positive integers"
      }));
    }
    handleProductCombinationsChanged();
  };

  const isInteger = (str: string): boolean => {
    return /^\d+$/.test(str);
  };

  const validatePagesInput = (value: string) => {
    const isValid = /^(\d+,)*\d+$/.test(value);
    return isValid;
  };

  return (
    <>
      {isLoadingProductOptions && (
        <div className="text-left mt-2">
          <div>Loading Product Options...</div>
          <Spinner animation="border" role="status" variant="primary"></Spinner>
        </div>
      )}
      <label className="h5 mt-4">Product codes:</label>
      <div>
        <Checkbox
          label=" Select All / Unselect All"
          isChecked={isSelectAllProductCodesChecked}
          onToggle={handleSelectAllProductCodesCheckbox}
        />
      </div>
      <div>
        <MultiSelectDropdown 
        options={productCodesWithDescription}
        selectedOptions={selectedProductCodes} 
        onSelect={handleProductCodesDropdownChange}
        isLocked={isLocked}
        controlId="productCodesDropdown"/>
      </div>
      <br/>
      <table className="table table-bordered">
        <thead className="text-center">
          <tr>            
            <th className="col-5">Option name</th>
            <th>Possible option values</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray name="optionItems">
            {({ push, remove }) => (
              <>
                {productOptionsWithValues.filter(optionItem => !excludedProductOptions.includes(optionItem.productOption.key)).map((optionItem, index) => (
                  <tr key={index}>                    
                    <td>
                      <div>
                        <label>{optionItem.productOption.description}</label>
                      </div>
                    </td>
                    <td>
                      {optionItem.productOption.key.toLowerCase() === "pages" ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="24,36,48"
                            onChange={(event) => handlePagesInputChange(optionItem.productOption.key, event)}
                          />
                          {pagesErrorMessages[optionItem.productOption.key] && (
                            <div className="text-danger">{pagesErrorMessages[optionItem.productOption.key]}</div>
                          )}
                        </div>
                      ) : (
                        <MultiSelectDropdown
                          options={optionItem.possibleOptionValues}
                          selectedOptions={selectedProductOptionsWithValues[optionItem.productOption.key] ? 
                            selectedProductOptionsWithValues[optionItem.productOption.key].map(key => ({
                              key: key,
                              description: optionItem.possibleOptionValues.find(value => value.key === key)?.description || "Description not found"
                            })) : []
                          }
                          onSelect={handleProductOptionsWithValuesDropdownChange(optionItem.productOption.key)}
                          isLocked={false}
                          controlId={"optionValuesCombo_" + optionItem.productOption.key}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </FieldArray>
        </tbody>
      </table>
    </>
  );
}

export default SelectProductCombinations;
