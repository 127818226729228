import { FC, ReactElement, useState } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format, toZonedTime } from 'date-fns-tz';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../_metronic/_partials/dropdowns";
import { useDispatch } from "react-redux";
import * as actions from "./redux/ComponentsActions";
import { usePlant, useProductionSites } from "./PlantHook";
import { useMsal } from "@azure/msal-react";
import { isScheduledLeadTimesEnabled } from "../utils/IsAvailableOnFeatureFlag";
import { ProductionSite, ProductionSiteList } from './redux/ComponentsSlice';

type RolesPlants = {
  plants: string[];
  roles: string[];
};

const rolePlants: RolesPlants[] = [
  { plants: ["YPB"], roles: ["YPBWriteAccess", "YPBReadAccess"] },
  { plants: ["NLH"], roles: ["NLHWriteAccess", "NLHReadAccess"] },
  { plants: ["RRD"], roles: ["RRDWriteAccess", "RRDReadAccess"] },
  { plants: ["ELA"], roles: ["ELAWriteAccess", "ELAReadAccess"] },
  { plants: ["ELA_SE"], roles: ["ELAWriteAccess", "ELAReadAccess"] },
  { plants: ["RAV"], roles: ["RAVWriteAccess", "RAVReadAccess"] },
  { plants: ["ORWO"], roles: ["ORWOWriteAccess", "ORWOReadAccess"] },
  { plants: ["PAT"], roles: ["PATWriteAccess", "PATReadAccess"] },
  { plants: ["KHS"], roles: ["KHSWriteAccess", "KHSReadAccess"] },
  { plants: ["EKT"], roles: ["EKTWriteAccess", "EKTReadAccess"] },
  { plants: ["WFR"], roles: ["WFRWriteAccess", "WFRReadAccess"] },
  { plants: ["PP"], roles: ["PPWriteAccess", "PPReadAccess"] },
  { plants: ["MM"], roles: ["MMWriteAccess", "MMReadAccess"] },
  { plants: ["JONDO"], roles: ["JONDOWriteAccess", "JONDOReadAccess"] },
  { plants: ["YPB", "NLH", "ELA", "ELA_SE", "SYM", "RAV", "ORWO", "PAT", "KHS", "EKT", "WFR", "PP", "MM", "JONDO"], roles: ["Developer"] },
];

const ProductionSiteFlags = [
  {
    plantCode: "YPB",
    flag: toAbsoluteUrl("/media/svg/flags/237-netherlands.svg")
  },
  {
    plantCode: "NLH",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg")
  },
  {
    plantCode: "ELA",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
  },
  {
    plantCode: "ELA_SE",
    flag: toAbsoluteUrl("/media/svg/flags/184-sweden.svg")
  },
  {
    plantCode: "RAV",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
  },
  {
    plantCode: "ORWO",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
  },
  {
    plantCode: "PAT",
    flag: toAbsoluteUrl("/media/svg/flags/016-spain.svg")
  },
  {
    plantCode: "KHS",
    flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
  },
  {
    plantCode: "EKT",
    flag: toAbsoluteUrl("/media/svg/flags/184-sweden.svg")
  },
  {
    plantCode: "WFR",
    flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg")
  },
  {
    plantCode: "PP",
    flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg")
  },
  {
    plantCode: "MM",
    flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg")
  },
  {
    plantCode: "JONDO",
    flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg")
  }
];

export function GetPlants(): ProductionSiteList {
  let productionSites = useProductionSites();

  let plants = productionSites.map(x => {
    return {
      plantCode: x.plantCode,
      timeZoneName: x.timeZoneName,
      timeZoneLocation: x.timeZoneLocation,
      plantSupportsCarrierConfiguration: x.plantSupportsCarrierConfiguration,
      currentOffset: x.currentOffset,
      flag: ProductionSiteFlags.find(y => y.plantCode === x.plantCode)?.flag,
      isActive: x.isActive
    } as ProductionSite;
  });

  return plants;
}

export const PlantSelectorDropdown: FC<{ props: any }> = ({
  props,
}): ReactElement => {
  const [currentTime, setCurrentTime] = useState(new Date().toISOString());

  setInterval(() => setCurrentTime(new Date().toISOString()), 60000);

  const { accounts }: any = useMsal();
  const plantCode = usePlant();
  const dispatch = useDispatch();

  let plants = GetPlants();

  var roles = accounts[0].idTokenClaims.roles ?? [];
  var auxPlants: string[] = [];

  // find the plants available based on the roles of the user.
  roles.forEach((role: string) => {
    rolePlants
      .filter((rp) => rp.roles.find((r) => r === role))
      .forEach((rp) => {
        auxPlants = auxPlants.concat(rp.plants);
      });
  });

  // map the list of plants to show the selection properly
  let availablePlants = plants.filter((x) => auxPlants.indexOf(x.plantCode) !== -1);
  availablePlants = availablePlants.sort((a, b) => a.plantCode.localeCompare(b.plantCode));
  const currentPlant =
    availablePlants.find((x) => x.plantCode === plantCode) ?? availablePlants[0];

  const setPlant = (plantCode: string, plantSupportsCarrierConfiguration: boolean) => {
    dispatch(actions.selectPlant(plantCode, plantSupportsCarrierConfiguration));
  };

  return availablePlants.length < 1 ? (
    <></>
  ) : (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <div className="topbar-item">
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-5"
            id="kt_quick_user_toggle"
          >
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='language-panel-tooltip'>Select Plant</Tooltip>
              }
            >
              <div>
                <div className='d-flex flex-row bd-highlight'>
                  {currentPlant.flag &&
                    <span className='mr-1 pt-2'>
                      <span className='symbol symbol-20 mr-2'>
                        <img
                          className='h-25px w-25px rounded'
                          src={currentPlant.flag}
                          alt={currentPlant.plantCode}
                        />
                      </span>
                    </span>
                  }
                  <span className='navi-text pt-3'>{currentPlant.plantCode}</span>
                  {isScheduledLeadTimesEnabled && currentPlant.timeZoneLocation &&
                    <span className='ml-5 bd-highlight text-left'>
                      <div className='font-small'>Local factory time</div>
                      <div className='font-size-base font-weight-bolder'>{format(toZonedTime(currentTime, currentPlant.timeZoneLocation), 'HH:mm (zzz)', { timeZone: currentPlant.timeZoneLocation })}</div>
                    </span>}
                </div>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-left dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          {availablePlants.map((plant) => (
            <Dropdown.Item
              key={plant.plantCode}
              onClick={() => setPlant(plant.plantCode, plant.plantSupportsCarrierConfiguration ?? false)}
            >
              <li
                key={plant.plantCode}
                className={clsx("navi-item d-flex align-items-center", {
                  active: plant.plantCode === currentPlant.plantCode,
                })}
              >
                {plant.flag &&
                  <span className="symbol symbol-20 mr-3">
                    <img src={plant.flag} alt={plant.plantCode} />
                  </span>
                }
                <span className="navi-text">{plant.plantCode}</span>
              </li>
            </Dropdown.Item>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};
