import React from 'react';

type Props = {
  copy: string;
  customStyle?: string;
  color?: string;
};

const EditIconWithCopy = ({ copy, customStyle, color }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_458_172'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='24'
          height='25'
        >
          <rect y='0.5' width='24' height='24' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_458_172)'>
          <path
            d='M4 24.5C3.45 24.5 2.97917 24.3041 2.5875 23.9125C2.19583 23.5208 2 23.05 2 22.5C2 21.95 2.19583 21.4791 2.5875 21.0875C2.97917 20.6958 3.45 20.5 4 20.5H20C20.55 20.5 21.0208 20.6958 21.4125 21.0875C21.8042 21.4791 22 21.95 22 22.5C22 23.05 21.8042 23.5208 21.4125 23.9125C21.0208 24.3041 20.55 24.5 20 24.5H4ZM5 18.5C4.71667 18.5 4.47917 18.4041 4.2875 18.2125C4.09583 18.0208 4 17.7833 4 17.5V15.175C4 15.0416 4.025 14.9125 4.075 14.7875C4.125 14.6625 4.2 14.55 4.3 14.45L13.05 5.69996L16.8 9.44996L8.05 18.2C7.95 18.3 7.8375 18.375 7.7125 18.425C7.5875 18.475 7.45833 18.5 7.325 18.5H5ZM6 16.5H6.9L14 9.44996L13.05 8.49996L6 15.6V16.5ZM17.925 8.34996L14.175 4.59996L15.975 2.79996C16.1583 2.59996 16.3917 2.50413 16.675 2.51246C16.9583 2.5208 17.1917 2.61663 17.375 2.79996L19.725 5.14996C19.9083 5.3333 20 5.56246 20 5.83746C20 6.11246 19.9083 6.34996 19.725 6.54996L17.925 8.34996Z'
            fill={color ? color : '#38BFC4'}
          />
        </g>
      </svg>
    </div>
    <div>{copy}</div>
  </div>
);

export default EditIconWithCopy;
