import { usePlant } from "../../components/PlantHook";
import {
  useFetchProductCategories,
  useFetchProductCategory,
} from "../hooks/useFetchProductCategoriesData";
import BootstrapTable from "react-bootstrap-table-next";
import { match as Match, useHistory } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import EditIcon from "../../svg/EditIcon";
import { Button, Spinner, Alert } from "react-bootstrap";
import isEmpty from "../../utils/isEmpty";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import DeleteIcon from "../../svg/DeleteIcon";
import DeleteDialog from "../../packaging/components/DeleteDialog";
import {
  deleteProductCategory,
  deleteProductCategoryItem,
} from "../api/ProductsApi";
import ProductCategoryItemAddDialog from "../components/productCategory/productCategoryItem/ProductCategoryItemAddDialog";
import ModifierAddDialog from "../components/modifiers/ModifierAddDialog";
import { deleteProductModifier } from "../api/ModifiersApi";
import * as actions from "../redux/ProductGroupActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IExportProductsAndModifiersCommand, IAlertModel } from "../models/ProductGroup";
import CuboidIcon from "../../svg/CuboidIcon";
import RollIcon from "../../svg/RollIcon";

interface IRouteParams {
  productCategory: string;
}
interface IProductGroupDetailsProps {
  match: Match<IRouteParams>;
}

const ProductGroupDetails = ({
  match,
}: IProductGroupDetailsProps): JSX.Element => {
  const { productCategory: productGroup } = match.params;

  const dispatch = useDispatch();

  const { alert, apiCallInProgress } = useSelector(
    (state: any) => ({
      alert: state.productGroup?.alert || null,
      apiCallInProgress: state.productGroup?.apiCallInProgress || false
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.dismissAlert());
  }, []);

  const plant = usePlant();
  const history = useHistory();
  const { refetchProductCategories } = useFetchProductCategories(plant);
  const { productCategory, refetchProductCategory, isLoading } =
    useFetchProductCategory(plant, productGroup) || {};

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isAddModifierDialogOpen, setAddModifierDialogOpen] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isDeleteModifierPopupOpen, setDeleteModifierPopupOpen] =
    useState(false);
  const [selectedArticleCode, setSelectedArticleCode] = useState<any>(null);
  const [selectedModifierName, setSelectedModifierName] = useState<any>(null);

  const [selectedArticle, setSelectedArticle] = useState<any>(null);
  const [isSingleItemDeletePopupOpen, setSingleItemDeletePopupOpen] =
    useState(false);

  const [editModifierData, setEditModifierData] = useState(null);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedModifiers, setSelectedModifiers] = useState<string[]>([]);

  const handleEditClick = (singleProduct: any) => {
    setSelectedArticle(singleProduct);
    setEditDialogOpen(true);
  };

  const toggleDeleteClick = () => {
    setDeletePopupOpen(!isDeletePopupOpen);
  };
  const toggleSingleItemDeleteClick = () => {
    setSingleItemDeletePopupOpen(!isSingleItemDeletePopupOpen);
  };
  const toggleModifierDeleteClick = () => {
    setDeleteModifierPopupOpen(!isDeleteModifierPopupOpen);
  };

  const handleDeleteProductCategoryClick = async () => {
    await deleteProductCategory(plant, productGroup);
    setDeletePopupOpen(false);
    history.push(`/products`);
    refetchProductCategories();
  };

  const handleDeleteProductCategoryItem = async (articleCode: string) => {
    setSingleItemDeletePopupOpen(true);
    setSelectedArticleCode(articleCode);
  };

  const handleDeleteProductModifier = async (modifierName: string) => {
    setSelectedModifierName(modifierName);
    setDeleteModifierPopupOpen(true);
  };

  const handleDeleteModifierConfirm = async () => {
    try {
      await deleteProductModifier(plant, productGroup, selectedModifierName);
      await refetchProductCategory();
      setDeleteModifierPopupOpen(false);
    } catch (error) {
      console.error("Error deleting product modifier, error");
    }
  };
  const handleDeleteProductCategoryItemConfirm = async (
    articleCode: string
  ) => {
    try {
      await deleteProductCategoryItem(plant, productGroup, articleCode);

      await refetchProductCategory();
      setSingleItemDeletePopupOpen(false);
    } catch (error) {
      console.error("Error deleting product group item:", error);
    }
  };

  const handleCancelClick = () => {
    setEditDialogOpen(false);
    setAddDialogOpen(false);
  };

  const handleProductModifierModalCancel = () => {
    setAddModifierDialogOpen(false);
    setEditModifierData(null);
  };

  const handleAddProductCategoryItem = () => {
    setSelectedArticleCode(null);
    setAddDialogOpen(true);
  };

  const handleAddProductModifier = () => {
    setAddModifierDialogOpen(true);
  };

  const handleModifierEdit = (selectedModifier: any) => {
    setEditModifierData(selectedModifier);
    setAddModifierDialogOpen(true);
  };

  const handleProductSelected = (articleCode: string) => {
    setSelectedProducts(currentSelectedProducts => {
      if (currentSelectedProducts.includes(articleCode)) {
        return currentSelectedProducts.filter(code => code !== articleCode);
      } else {
        return [...currentSelectedProducts, articleCode];
      }
    });
  };

  const handleModifierSelected = (modifierName: string) => {
    setSelectedModifiers(currentSelectedModifiers => {
      if (currentSelectedModifiers.includes(modifierName)) {
        return currentSelectedModifiers.filter(name => name !== modifierName);
      } else {
        return [...currentSelectedModifiers, modifierName];
      }
    });
  };

  const selectAllProductsToggle = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedProducts(products?.map(article => article.articleCode) || []);
    } else {
      setSelectedProducts([]);
    }
  };

  const selectAllModifiersToggle = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedModifiers(productModifiersWithSizes?.map(modifier => modifier.name) || []);
    } else {
      setSelectedModifiers([]);
    }
  };

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  const handleExport = async () => {
    if (selectedProducts.length === 0 && selectedModifiers.length === 0) {
      const alertModel = {
        title: "Selection Required",
        message: "Please select products and/or modifiers to export.",
        variant: "danger",
      } as IAlertModel;
      await dispatch(actions.showAlert(alertModel));
      return;
    }
    const exportCommand: IExportProductsAndModifiersCommand = {
      Products: selectedProducts,
      ProductModifiers: selectedModifiers,
    };
    await dispatch(actions.exportProductsAndModifiers(plant, productGroup, exportCommand));
  }

  const handleFormSubmitSuccess = async () => {
    try {
      // Call the refetch function to update the packaging group data
      refetchProductCategory();
    } catch (error) {
      console.error(
        "Error in handleFormSubmitSuccess on ProductGroupDetails.tsx :",
        error
      );
    }
  };

  const products = productCategory?.productCategory?.articles.map(
    (article) =>
    ({
      articleCode: article?.articleCode,
      size: {
        breadthMm: article?.size?.breadthMm,
        heightMm: article?.size?.heightMm,
        lengthMm: article?.size?.lengthMm
      },
      weightGr: article?.size?.weightGr,
      defaultModifierNames: article?.defaultModifierNames || [],
      versionedModifierNames: article?.productOptionsBasedModifierNames || {},
      additionalModifiers: article?.productOptionsOverrides || [],
      selected: selectedProducts.includes(article.articleCode),
    } || [])
  );

  const extractValue = (modifier: any, key: string) => {
    if (modifier[key]?.values) {
      const valueKey = Object.keys(modifier[key].values)[0];
      return modifier[key].values[valueKey];
    }
    return null;
  };

  const productModifiersWithSizes =
    productCategory?.productCategory?.productSpecsModifiers.map(
      (modifier) =>
      ({
        name: modifier?.name,
        modifier: modifier,
        width: extractValue(modifier, "breadthModifier"),
        height: extractValue(modifier, "heightModifier"),
        length: extractValue(modifier, "lengthModifier"),
        weight: extractValue(modifier, "weightModifier"),
        sequence: modifier.sequence,
        shapeModifier: modifier.shapeModifier,
        selected: selectedModifiers.includes(modifier.name),
      } || [])
    );
  productModifiersWithSizes?.sort((m1, m2) => m1.sequence >= m2.sequence ? 1 : -1);

  const representModifier = (specificModifier: any, value: any, unit: string): any => {
    switch (specificModifier?.modifierType) {
      case "Add":
        return (<>+{value}{unit}</>);
      case "Override":
        return (<>={value}{unit}</>);
      case "PagesWeightForPrints":
        return (<>fn(Pages)</>);
      case "PagesWeight":
        return (<>fn(Pages)</>);
      case "WeightForRectangularArea":
        return (<>+{value}{unit}/m<sup>2</sup></>);
      case "WeightForRectangularCircumference":
        return (<>+{value}{unit}/m</>);
      case "BookHeight":
        return (<>BookHeight({value}{unit})</>);
    }
  }

  const productColumns = [
    {
      dataField: "articleCode",
      text: "Code",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: string) => <strong style={{ fontSize: '1.1em' }}>{cell}</strong>
    },
    {
      dataField: "size",
      text: "L x W x H (mm)",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `BASE product dimensions that could be changed by Modifiers`,
      headerClasses: "break-header text-center",
      classes: "text-center",
      style: { 'width': '130px' },
      formatter: (cell: {
        lengthMm: number;
        breadthMm: number;
        heightMm: number;
      }) => `${cell.lengthMm} x ${cell.breadthMm} x ${cell.heightMm}`
    },
    {
      dataField: "weightGr",
      text: "Weight (g)",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `BASE weight that could be changed by Modifiers`,
      headerClasses: "break-header text-center",
      classes: "text-center",
      formatter: (cell: number) => `${cell}`
    },
    {
      dataField: "defaultModifierNames",
      text: "Default Modifiers",
      headerClasses: "break-header text-center",
      classes: "text-center",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `These Modifiers will be applied REGARDLESS of product options`,
      formatter: (cell: string[]) => (
        <>
          {cell.length === 0 ? "" : cell.map((item, index) => (
            <Fragment key={index}>
              {item}
              {index < cell.length - 1 && <br />}
            </Fragment>
          ))}
        </>
      )
    },
    {
      dataField: "versionedModifierNames",
      text: "Versioned Modifiers",
      headerClasses: "break-header text-center",
      classes: "text-center",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `This specific (VERSIONED) Modifier will be applied IF product has option with matching name and value`,
      formatter: (cell: Record<string, string[]>) => (
        <>
          {Object.keys(cell).length === 0 ? "" :
            Object.entries(cell).map(([key, values], entryIndex) =>
              values.map((value, valueIndex) => (
                <Fragment key={`${entryIndex}-${valueIndex}`}>
                  {value}
                  {valueIndex < values.length - 1 && <br />}
                </Fragment>
              ))
            )
          }
        </>
      )
    },
    {
      dataField: "additionalModifiers",
      text: "Additional Modifiers",
      headerClasses: "break-header text-center",
      classes: "text-center",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `IF product has options {...} THEN -> USE additional Modifiers`,
      formatter: (cell: { inputOptions: Record<string, string>, overrideOptions: Record<string, string> }[]) => (
        <>
          {cell.length === 0 ? "" :
            cell.map(({ inputOptions, overrideOptions }, index) => {
              const input = Object.entries(inputOptions).map(([key, value], i) => (
                <Fragment key={i}>
                  {key} = {value}{i < Object.entries(inputOptions).length - 1 && <strong style={{ fontSize: '1.2em' }}>, </strong>}
                </Fragment>
              ));
              const filteredOutput = Object.entries(overrideOptions).filter(
                ([key, value]) => !(key in inputOptions && inputOptions[key] === value)
              );
              const output = filteredOutput.map(([key, value], i) => (
                <Fragment key={i}>
                  <strong>use</strong> {key}-{value}{i < filteredOutput.length - 1 && <strong style={{ fontSize: '1.2em' }}>, </strong>}
                </Fragment>
              ));
              return (
                <div key={index} style={{ marginBottom: '0.5em' }}>
                  {`{`}{input.length > 0 ? input : ''}{`}`}
                  <strong style={{ fontSize: '1.2em' }}>{` → `}</strong>
                  {`{`}{output.length > 0 ? output : ''}{`}`}
                </div>
              );
            })}
        </>
      ),
    },
    {
      dataField: "action",
      text: "",
      classes: "text-center",
      headerClasses: "break-header text-center",
      formatter: (cell: any, row: any) => (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => handleEditClick(row)}
        >
          <EditIcon />
        </button>
      ),
    },
    {
      dataField: "delete",
      classes: "text-center",
      headerClasses: "text-center",
      text: "",
      formatter: (cell: any, row: { articleCode: string }) => (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => handleDeleteProductCategoryItem(row.articleCode)}
        >
          <DeleteIcon />
        </button>
      ),
    },
    {
      dataField: "select",
      text: "Select",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: any, row: any) => (
        <input
          type="checkbox"
          checked={row.selected}
          onChange={() => handleProductSelected(row.articleCode)}
        />
      ),
      headerFormatter: () => (
        <>
          <label htmlFor="chkBoxSelectAll">select all</label><br />
          <input
            type="checkbox"
            checked={selectedProducts.length === products?.length}
            onChange={(e) => selectAllProductsToggle(e.target.checked)}
            id="chkBoxSelectAll"
          />
        </>
      ),
    },
  ];

  const getToolTipForModifier = (cell: any) => {
    if (!cell || cell === undefined || cell === null) {
      return "undefined";
    }

    return "under construction";
    //return `${cell!=undefined&&cell.indexOf('_')>0 ? "VERSIONED" : ""} Modifier is applied in calculation IF product has option '${cell!=undefined ? cell.split('-')[0] : ''}' with value '${cell==undefined ?'' :cell.split('-')[1].indexOf('_')>0 ? cell.split('-')[1].substring(0, cell.split('-')[1].indexOf('_')) : cell.split('-')[1]}' ${cell!=undefined&&cell.indexOf('_')>0 ? "AND this modifier is assigned to a product" : ""}`;    
  };

  const modifierColumns = [
    {
      dataField: "sequence",
      text: "Sequence",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `Modifiers are applied in sequence, from lowest to highest`,
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "name",
      text: "Key",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => getToolTipForModifier(cell),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "modifierType",
      text: "Type",
      formatter: (cell: any, row: any) => (
        <>{{
          "Default": <span title="Always applied">Always</span>,
          "ProductOptionBased": <span title="Applied IF product has matching option name and value">Conditional</span>
        }[row.modifier.modifierType as string]}</>
      ),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "shapeModifier",
      text: "Changes shape",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `Effect on product shape`,
      formatter: (cell: any, row: any) => (
        <>{{
          "Cuboid": <CuboidIcon />,
          "Roll": <RollIcon />
        }[row.shapeModifier as string]}</>
      ),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "length",
      text: "Length (mm)",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `Effect on product Length`,
      formatter: (cell: any, row: any) => (
        <>{representModifier(row.modifier.lengthModifier, row.length, "")}</>
      ),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "breadth",
      text: "Breadth (mm)",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `Effect on product Breadth (Width)`,
      formatter: (cell: any, row: any) => (
        <>{representModifier(row.modifier.breadthModifier, row.width, "")}</>
      ),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "height",
      text: "Height (mm)",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `Effect on product Height`,
      formatter: (cell: any, row: any) => (
        <>{representModifier(row.modifier.heightModifier, row.height, "")}</>
      ),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "weight",
      text: "Weight",
      title: (cell: any, row: any, rowIndex: any, colIndex: any) => `Effect on product Weight`,
      formatter: (cell: any, row: any) => (
        <>{representModifier(row.modifier.weightModifier, row.weight, "g")}</>
      ),
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "action",
      text: "",
      formatter: (cell: any, row: any) => (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => handleModifierEdit(row)}
        >
          <EditIcon />
        </button>
      ),
      classes: "text-right pr-15",
      headerClasses: "text-right pr-18",
    },
    {
      dataField: "delete",
      classes: "text-center",
      headerClasses: "text-center",
      text: "",
      formatter: (cell: any, row: any) => (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => handleDeleteProductModifier(row.name)}
        >
          <DeleteIcon />
        </button>
      ),
    },
    {
      dataField: "select",
      text: "Select",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: any, row: any) => (
        <input
          type="checkbox"
          checked={row.selected}
          onChange={() => handleModifierSelected(row.name)}
        />
      ),
      headerFormatter: () => (
        <input
          type="checkbox"
          checked={selectedModifiers.length === productModifiersWithSizes?.length}
          onChange={(e) => selectAllModifiersToggle(e.target.checked)}
        />
      ),
    },
  ];

  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
          <p>{alert.additionalInfo}</p>
        </Alert>
      )}
      {apiCallInProgress && (
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="font-weight-bold font-size-h3">{productGroup}</div>
        <Button variant="outline-primary" onClick={handleExport} className="d-flex align-items-center btn-sm">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} className="svg-icon-md" />
          <span className="ml-2">Export</span>
        </Button>
      </div>
      <div className="font-weight-bold font-size-lg mt-20">PRODUCTS</div>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <p className="mt-2">Loading...</p>
        </div>
      ) : isEmpty(products) ? (
        <div className="text-center mt-10 p-10">
          <p>
            No products have been added yet to this product group. You can use Add button to start adding base product sizes and dimension modifiers
          </p>
        </div>
      ) : (
        <div>
          {" "}
          <BootstrapTable
            wrapperClasses="table-responsive column-width-control"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={true}
            striped={true}
            remote
            keyField="timestamp"
            data={products || []}
            columns={productColumns}
            defaultSorted={[{ dataField: "articleCode", order: "asc" }]}
          ></BootstrapTable>
        </div>
      )}
      <button
        title="Add new product item"
        className="btn btn-light btn-hover-primary btn-sm mx-4"
        onClick={handleAddProductCategoryItem}
        style={{
          borderRadius: "12px",
          border: "2px solid #38BFC4",
          backgroundColor: "white",
          color: "#38BFC4",
          display: "flex",
          alignItems: "center",
          paddingLeft: "8px",
        }}
      >
        <span className="svg-icon svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
            title="New product group"
            className="svg-icon-md"
          />
        </span>
        <span style={{ marginLeft: "8px" }}>Add Product</span>
      </button>
      <div className="font-weight-bold font-size-lg mt-20">
        DIMENSION MODIFIERS
      </div>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <p className="mt-2">Loading...</p>
        </div>
      ) : isEmpty(productModifiersWithSizes) ? (
        <div className="text-center mt-10 p-10">
          <p>
            The product modifiers are not yet configured. You can use Add button to start configuring effects of product options on product size and weight.
          </p>
        </div>
      ) : (
        <>
          <div>
            {" "}
            <BootstrapTable
              wrapperClasses="table-responsive column-width-control"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={true}
              striped={true}
              remote
              keyField="productModifier"
              data={productModifiersWithSizes || []}
              columns={modifierColumns}
              defaultSorted={[{ dataField: "sequence", order: "asc" }]}
            ></BootstrapTable>
          </div>
        </>
      )}
      <button
        title="Add modifier"
        className="btn btn-light btn-hover-primary btn-sm mx-4"
        onClick={handleAddProductModifier}
        style={{
          borderRadius: "12px",
          border: "2px solid #38BFC4",
          backgroundColor: "white",
          color: "#38BFC4",
          display: "flex",
          alignItems: "center",
          paddingLeft: "8px",
        }}
      >
        <span className="svg-icon svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
            title="New modifier"
            className="svg-icon-md"
          />
        </span>
        <span style={{ marginLeft: "8px" }}>Add Modifier</span>
      </button>
      {isEditDialogOpen && (
        <ProductCategoryItemAddDialog
          productGroup={productGroup}
          productType={productCategory?.productCategory.productType}
          onHide={handleCancelClick}
          onSuccess={handleFormSubmitSuccess}
          plant={plant}
          singleProduct={selectedArticle}
        />
      )}
      <div className="delete-section mt-10">
        <h3 className="mt-10">DELETE PRODUCT GROUP</h3>
        <div>
          This will delete this product group with all of its base sizes and modifiers.
        </div>
        <Button className="mt-5" onClick={toggleDeleteClick} variant="danger">
          <span>Delete entire group</span> <DeleteIcon />
        </Button>
      </div>
      {isDeletePopupOpen && (
        <DeleteDialog
          isOpen={isDeletePopupOpen}
          onHide={toggleDeleteClick}
          onDelete={handleDeleteProductCategoryClick}
          title="WARNING"
          message="Deleting this product group cannot be undone"
        />
      )}
      {isAddDialogOpen && (
        <ProductCategoryItemAddDialog
          productGroup={productGroup}
          productType={productCategory?.productCategory.productType}
          onHide={handleCancelClick}
          onSuccess={handleFormSubmitSuccess}
          plant={plant}
        />
      )}
      {isSingleItemDeletePopupOpen && selectedArticleCode && (
        <DeleteDialog
          isOpen={isSingleItemDeletePopupOpen}
          onHide={toggleSingleItemDeleteClick}
          onDelete={() => {
            handleDeleteProductCategoryItemConfirm(selectedArticleCode);
          }}
          title="WARNING"
          message="Deleting this item cannot be undone"
        />
      )}
      {isAddModifierDialogOpen && (
        <ModifierAddDialog
          productGroup={productGroup}
          productType={productCategory?.productCategory.productType}
          onHide={handleProductModifierModalCancel}
          onSuccess={handleFormSubmitSuccess}
          plant={plant}
          editModifierData={editModifierData}
        />
      )}
      {isDeleteModifierPopupOpen && (
        <DeleteDialog
          isOpen={isDeleteModifierPopupOpen}
          onHide={toggleModifierDeleteClick}
          onDelete={() => {
            handleDeleteModifierConfirm();
          }}
          title="WARNING"
          message="Deleting this modifier cannot be undone"
        />
      )}
    </>
  );
};

export default ProductGroupDetails;
