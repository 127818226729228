import { FC, ReactElement, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const PackagingGroupDialogHeader = () => {
  return (
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
        New Packaging Group
      </Modal.Title>
    </Modal.Header>
  );
};

const PackagingItemDialogHeader: FC<{itemName: string|null|undefined}> = ({
  itemName,
}): ReactElement => {
  const defaultTitle: string = "Edit Packaging Item";
  const [title, setTitle] = useState<string>(defaultTitle);
  useEffect(() => {
    if (itemName != null && itemName !== undefined && itemName?.trim().length > 0) {
      setTitle(defaultTitle + " " + itemName);
    }
  },[itemName]);
  return (
    <>
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
      {title}
      </Modal.Title>
    </Modal.Header>
    </>
  );
};

export { PackagingGroupDialogHeader, PackagingItemDialogHeader };
