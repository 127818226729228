import { debounce } from "lodash";
import { ChangeEvent, FC, ReactElement, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../redux/RoutingRulesActions";

export const TestCases: FC<{
  testCases: string;
  saveTestCases: () => void;
}> = ({ testCases, saveTestCases }): ReactElement => {
  const dispatch = useDispatch();

  const textChangedHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let testCases = event.target.value;
    dispatch(actions.changeTestCases(testCases));
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(textChangedHandler, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => debouncedChangeHandler.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <h4>Test Cases</h4>
          </div>
          <div className="col-lg-2">
            <button
              type="submit"
              onClick={saveTestCases}
              className="btn btn-primary btn-elevate"
            >
              Save tests
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <textarea
              id="currentRules"
              defaultValue={testCases}
              rows={15}
              cols={144}
              style={{
                fontFamily: "Courier",
                font: "Courier",
                wordWrap: "normal",
                whiteSpace: "pre",
              }}
              onChange={debouncedChangeHandler}
              key={"currentTestCases_" + (10000 + Math.random() * (1000000 - 10000))}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};
