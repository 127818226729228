import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import countItemOccurrences from "../../utils/countItemOccurrences";

async function calculatePackages(calculateData: any): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.post<any>(
      `${backendConfig.url}/packaging/calculate`,
      calculateData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const modifedData = response.data.map((item: any) => {
      const { contents } = item;
      const itemCounts = countItemOccurrences(contents);

      // transform contents array to string so it is in good format for displaying in BootstrapTable
      const productAndCountAsString = Object.entries(itemCounts)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");

      return {
        ...item,
        productAndCountAsString: productAndCountAsString,
      };
    });

    return modifedData;
  } catch (error) {
    console.error("Error in calculatePackages:", error);
    throw error;
  }
}

export { calculatePackages };
