/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { FC, ReactElement, useState } from 'react';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { Link } from 'react-router-dom';
import {
  IProductGroups,
  IProductGroup,
} from '../../models/LeadTimes';
import LeadTimesImportModal from '../components/leadtimes-import-modal/LeadTimesImportModal';
import { LeadTimesExportImportCard } from '../components/leadtimes-export-import-card/LeadTimesExportImportCard';

const ListsWidgetProductGroups: FC<IProductGroups> = ({
  leadTimesPerGroup,
  downloadLeadTimesHandler,
  plantCode,
  setHasLeadTimesUpdated,
  alert,
}): ReactElement => {
  const [showImportModal, setShowImportModal] = useState(false);

  const showImportModalHandler = () => {
    setShowImportModal(true);
  };
  const downloadLabel = 'Download all Product Lead times';
  const importLabel = 'Import all Product Lead times';

  return (
    <>
      {/* begin::List Widget 14 */}
      <div className='card card-custom card-stretch gutter-b'>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title font-weight-bolder text-dark'>Products</h3>
        </div>
        {/* end::Header */}
        <LeadTimesExportImportCard
          alert={alert}
          downloadHandler={downloadLeadTimesHandler}
          downloadLabel={downloadLabel}
          showImportModalHandler={showImportModalHandler}
          uploadLabel={importLabel}
        />
        <LeadTimesImportModal
          show={showImportModal}
          setShowImportModal={setShowImportModal}
          setHasLeadTimesUpdated={setHasLeadTimesUpdated}
          plantCode={plantCode}
          canScheduleLeadTimes={false} // for now scheduling lead times at the plant level for all product categories is not enabled
        />
        {/* begin::Body */}
        <div className='card-body pt-2 px-20'>
          {leadTimesPerGroup &&
            leadTimesPerGroup.map((group: IProductGroup) => {
              const uniqueLeadTimesArr = Array.from(
                new Set(
                  group.leadTimes.map((p) => p.leadTime)
                )
              );
              const uniqueExtraLeadTimesArr = Array.from(
                new Set(
                  group.leadTimes.map((p) => p.extraLeadTime)
                )
              );
              const haveExtraLeadTime =
                uniqueExtraLeadTimesArr.reduce((acc, curr) => acc + curr, 0) >
                0;
              const colorExtraLeadTime = haveExtraLeadTime
                ? 'text-warning'
                : 'text-primary';

              const productImage =
                '/media/product-group/' +
                group.name.toLowerCase() +
                '.webp';

              return (
                <div
                  className='d-flex flex-wrap align-items-center mb-10'
                  key={group.name}
                >
                  <div className='symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4'>
                    <div
                      className='symbol-label'
                      style={{
                        backgroundImage: `url('${toAbsoluteUrl(
                          productImage
                        )}')`,
                      }}
                    ></div>
                  </div>

                  <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                    <Link
                      className='text-dark-75 font-weight-bolder text-hover-primary font-size-lg'
                      to={{
                        pathname: `/leadtimes/${group.name}`,
                        state: { productGroup: group },
                      }}
                    >
                      <span className='menu-text'> {group.name}</span>
                    </Link>

                    <span className='text-muted font-weight-bold font-size-sm my-1'>
                      Regular lead time:{' '}
                      <span className='text-primary font-weight-bold'>
                        {uniqueLeadTimesArr.join(' / ')} days
                      </span>
                    </span>
                    <span className='text-muted font-weight-bold font-size-sm'>
                      Extra lead time:{' '}
                      <span
                        className={'font-weight-bold ' + colorExtraLeadTime}
                      >
                        {uniqueExtraLeadTimesArr.join(' / ')} days
                      </span>
                    </span>
                  </div>

                  <div className='d-flex align-items-center py-lg-0 py-2'>
                    <div className='d-flex flex-column text-right'>
                      <span className='text-dark-75 font-weight-bolder font-size-h4'>
                        {group.leadTimes?.length}
                      </span>
                      <span className='text-muted font-weight-bolder font-size-sm'>
                        Products
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
};

export default ListsWidgetProductGroups;
