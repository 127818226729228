import { Modal } from "react-bootstrap";
import { PackagingGroupDialogHeader } from "./PackagingGroupDialogHeader";
import PackagingGroupAddForm from "./PackagingGroupAddForm";

const PackagingGroupAddDialog = ({ onHide, onSuccess, plant, packagingGroupNameUniquenessCheck }: any) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <PackagingGroupDialogHeader />
      <PackagingGroupAddForm
        onHide={onHide}
        onSuccess={onSuccess}
        plant={plant}
        packagingGroupNameUniquenessCheck={packagingGroupNameUniquenessCheck}
      />
    </Modal>
  );
};

export default PackagingGroupAddDialog;
