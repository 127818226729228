import React from 'react';

type Props = {
  active: boolean;
  copy: string;
  customStyle?: string;
  color?: string;
};

const activeOnIcon = (
  <svg width="41" height="24" viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="40" height="20" rx="10" fill="#17B26A" />
    <circle cx="30.5" cy="10" r="8" fill="white" />
  </svg>
)

const activeOffIcon = (
  <svg width="41" height="24" viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="40" height="20" rx="10" fill="#CCCCCC" />
    <circle cx="10.5" cy="10" r="8" fill="white" />
  </svg>
)

const ActiveIconWithCopy = ({ active, copy, customStyle }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      {(active) ? activeOnIcon : activeOffIcon}
    </div>
    <div>{copy}</div>
  </div>
);

export default ActiveIconWithCopy;
