import { useState, useEffect, useCallback } from "react";
import { getDeliveryGroup, getDeliveryGroups, getDeliveryGroupPreview } from "../api/DeliveryGroupApi";
import { IDeliveryGroup, IDeliveryGroupItem } from "../models/DeliveryGroup";

const useFetchDeliveryGroups = (plant: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryGroups, setDeliveryGroups] = useState<
    IDeliveryGroup[] | null
  >(null);
  const [error, setError] = useState(false);

  const fetchDeliveryGroups = useCallback(async () => {
    try {
      const data = await getDeliveryGroups(plant);
      setDeliveryGroups(data.deliveryGroups);
      setIsLoading(false);
      setError(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(true);
    }
  }, [plant]);

  useEffect(() => {
    fetchDeliveryGroups();
  }, [fetchDeliveryGroups, plant]);

  const refetchDeliveryGroups = async () => {
    setIsLoading(true);
    setError(false);
    try {
      await fetchDeliveryGroups();
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (error) {
    return {
      isLoading: false,
      deliveryGroups: null,
      error,
      refetchDeliveryGroups,
    };
  }

  return { isLoading, deliveryGroups, refetchDeliveryGroups };
};

const useFetchDeliveryGroup = (plant: string, deliveryGroupName: string) => {
  const [deliveryGroupItems, setDeliveryGroupItems] = useState<
    IDeliveryGroupItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchDeliveryGroup = useCallback(async () => {
    try {
      const data = await getDeliveryGroupPreview(plant, deliveryGroupName);
      setDeliveryGroupItems(data.deliveryGroupItems);
      console.log(data);
      setIsLoading(false);
      setError(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(true);
    }
  }, [plant, deliveryGroupName]);

  useEffect(() => {
    fetchDeliveryGroup();
  }, [fetchDeliveryGroup, plant]);

  const refetchDeliveryGroup = async () => {
    setIsLoading(true);
    setError(false);
    try {
      await fetchDeliveryGroup();
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (error) {
    return {
      isLoading: false,
      deliveryGroupItems: null,
      error,
      refetchDeliveryGroup,
    };
  }

  return { isLoading, deliveryGroupItems, refetchDeliveryGroup };
};

export { useFetchDeliveryGroups, useFetchDeliveryGroup };
