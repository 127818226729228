import { ICarrierOverrideApi, ICarrierApi } from "../models/CarrierOverrideApiModels"
import { CarrierOverride, CarrierOverrideStatus, Carrier, ZipCodeRange } from "../models/CarrierOverrideModels";
import moment from "moment";

export const mapCarrierOverrideToICarrierOverrideApi = (source: CarrierOverride) : ICarrierOverrideApi => {
    
    const zipCodeRanges = mapZipCodeRangesFromStringToArray(source.zipCodeRangesString);

    return {
        id: source.id,
        plantCode: source.plantCode,
        country: source.country,
        promisedDateFrom: source.promisedDateFrom ? moment.utc(source.promisedDateFrom).format() : undefined,
        promisedDateTo: source.promisedDateTo ? moment.utc(source.promisedDateTo).format() : undefined,
        validFrom: moment(source.validFrom).utc().format(),
        validTo: moment(source.validTo).utc().format(),
        carrierName: source.carrierName,
        overrideCarrierName: source.overrideCarrierName,
        disabledOn:  source.enabled ? undefined : moment().utc().format(),
        description: source.description,
        zipCodeRanges: zipCodeRanges,
        marketingPromised: source.marketingPromised,
    } as ICarrierOverrideApi;
}

export const mapICarrierOverrideApiToCarrierOverride = (source: ICarrierOverrideApi) : CarrierOverride => {
    const dateTimeFormat = "YYYY-MM-DDTHH:mm";
    const dateFormat = "YYYY-MM-DD";
    const zipCodeRangesString: string = source.zipCodeRanges.map(zipCodeRange => {
        return zipCodeRange.zipCodeStart + "-" + zipCodeRange.zipCodeEnd
    }).join(" / ");
    
    return {
        id: source.id,
        plantCode: source.plantCode,
        country: source.country,
        promisedDateFrom: source.promisedDateFrom ? moment(source.promisedDateFrom).format(dateFormat) : undefined,
        promisedDateTo: source.promisedDateTo ? moment(source.promisedDateTo).format(dateFormat) : undefined,
        validFrom: moment(source.validFrom).format(dateTimeFormat),
        validTo: moment(source.validTo).format(dateTimeFormat),
        carrierName: source.carrierName,
        overrideCarrierName: source.overrideCarrierName,
        description: source.description,
        status: carrierOverrideStatus(moment(source.validFrom).format(), moment(source.validTo).format(), source.disabledOn),
        enabled: source.disabledOn ? false : true,
        zipCodeRangesString: zipCodeRangesString,
        marketingPromised: source.marketingPromised
    } as CarrierOverride;
}

export const carrierOverrideStatus = (validFrom: string, validTo: string, disabledOn?: string): CarrierOverrideStatus | undefined => {    
    if (disabledOn){
        return CarrierOverrideStatus.Disabled;
    }
    else if (moment().isBetween(validFrom, validTo, undefined, "[]")) {
        return CarrierOverrideStatus.Active;
    }
    else if (moment().isBefore(validFrom)) {
        return CarrierOverrideStatus.Inactive;
    }
    else if (moment().isAfter(validTo)){
        return CarrierOverrideStatus.Expired;
    }
    else {
        return undefined;
    }
  };

export const mapICarrierApiToCarrier = (source: ICarrierApi) : Carrier => {    
    return {
        carrierName: source.carrierName
    } as Carrier;
}

export const mapZipCodeRangesFromStringToArray = (zipCodeRangesString: string): ZipCodeRange[] => {
    // zipCodeRangesString passed regex check, and it should come prepaired for this splitting part
    if (zipCodeRangesString === "") {
        return [];
    }
    const zipCodeRanges = zipCodeRangesString.split("/");
    
    return zipCodeRanges.map(zipCodeRange => {
        if (zipCodeRange) {
            zipCodeRange.trim();
            const singleZipCode = zipCodeRange.split("-");        
            return {
                zipCodeStart: singleZipCode[0].trim(),
                zipCodeEnd: singleZipCode[1].trim()
            } as ZipCodeRange;
        }
    }) as ZipCodeRange[];
}