import React from 'react';

function SingleSelectDropdownNoTs({ options, onSelect, initialValue={key:'',description:''}, isLocked, dataContext={} }) {
  const [selectedValue, setSelectedValue] = React.useState(initialValue && initialValue.key ? initialValue : '');
  const [showChooseOne, setShowChooseOne] = React.useState(initialValue.key === "" && -1 === options.findIndex(o => o.key === ""));

  const handleChange = (event) => {
    const selectedOption = options.find(option => option?.key === event.target.value);
    setShowChooseOne(false);
    setSelectedValue(selectedOption);
    onSelect(selectedOption, dataContext);
  };


  return (
    <div>
      <select disabled={isLocked} onChange={handleChange} value={selectedValue?.key}>
        {showChooseOne && 
        <option value="">Choose one...</option>
        }
        {options.map(option => (
          <option key={option?.key} value={option?.key}>{option?.description}</option>
        ))}
      </select>
    </div>
  );
}

export default SingleSelectDropdownNoTs;
