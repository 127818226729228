import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import countItemOccurrences from "../../utils/countItemOccurrences";

async function calculateProductDimensions(calculationRequest: any, factory: string): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.post<any>(
      `${backendConfig.url}/calculateproductdimensions/generate/${factory}`,
      calculationRequest,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const modifedData = response.data.items.map((item: any) => {
      const { articleCode, dimensions, options, shape } = item;

      const productOptions = Object.entries(options)
        .map(([key, value]) => `${articleCode} - ${key}: ${value}`)
        .join(", ");

      const dimensionsAndWeight = `${dimensions.lengthMm} x ${dimensions.breadthMm} x ${dimensions.heightMm} (${dimensions.weightGr}g)`;

      return {
        ...item,
        dimensionsAndWeight,
        productOptions
      };
    });

    return modifedData;
  } catch (error) {
    console.error("Error in calculatePackages:", error);
    throw error;
  }
}

async function calculateProductDimensionsForExport(calculationRequest: any, factory: string): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.post<any>(
      `${backendConfig.url}/calculateproductdimensions/export/${factory}`,
      calculationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          "responseType": "blob"
        },
      }
    );

    return response;

  } catch (error) {
    console.error("Error in calculateProductDimensionsForExport:", error);
    throw error;
  }
}

export { calculateProductDimensions, calculateProductDimensionsForExport };
