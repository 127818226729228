import axios from "axios";
import { getConfig } from "../../modules/Config/Config";

async function getDeliveryOptions(data: any): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.post<any>(
      `${backendConfig.url}/deliveryoptions`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getDeliveryOptions:", error);
    throw error;
  }
}

async function getRequestByOrderCode(orderCode: string): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.get<any>(
      `${backendConfig.url}/deliveryoptions/getRequest/${orderCode}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getRequestByOrderCode:", error);
    throw error;
  }
}

export { getDeliveryOptions, getRequestByOrderCode };
