import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { ArticleType, Brand, Channel } from "../models/RoutingRuleDropdownData";

type ArticleTypeResponse = {
  articleTypes: ArticleType[]
}

type BrandsResponse = {
  brands: Brand[]
}

type ChannelResponse = {
  channels: Channel[]
}

async function getArticleTypes(): Promise<ArticleType[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<ArticleTypeResponse>(`${backendConfig.url}/productcatalog/articletypes`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.articleTypes);
}

async function getBrands(): Promise<Brand[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<BrandsResponse>(`${backendConfig.url}/brands/brands`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.brands);
}

async function getChannels(): Promise<Channel[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<ChannelResponse>(`${backendConfig.url}/brands/channels`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.channels);
}

export { getArticleTypes, getBrands, getChannels };
