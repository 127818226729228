import { SVGProps } from "react";

function BoxIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="45"
      viewBox="0 0 24 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.01176 38.14H7.53176C8.35176 38.14 8.84176 37.59 8.84176 36.89C8.84176 36.17 8.35176 35.69 7.53176 35.69H6.01176V38.14ZM5.99176 41.33H7.75176C8.56176 41.33 9.07176 40.78 9.07176 40.06C9.07176 39.35 8.47176 38.83 7.71176 38.83H5.99176V41.33ZM8.72176 38.43V38.45C9.25176 38.67 9.91176 39.16 9.91176 40.07C9.91176 41.19 9.04176 42 7.80176 42H5.45176C5.34176 42 5.26176 41.91 5.26176 41.81V35.19C5.26176 35.09 5.34176 35 5.45176 35H7.66176C8.88176 35 9.69176 35.79 9.69176 36.84C9.69176 37.65 9.18176 38.15 8.72176 38.43ZM10.9439 39.68C10.9439 38.38 11.9839 37.28 13.2639 37.28C14.5439 37.28 15.5839 38.38 15.5839 39.68C15.5839 41 14.5439 42.11 13.2639 42.11C11.9839 42.11 10.9439 41 10.9439 39.68ZM11.6739 39.68C11.6739 40.64 12.3539 41.44 13.2639 41.44C14.1739 41.44 14.8439 40.64 14.8439 39.68C14.8439 38.74 14.1739 37.95 13.2639 37.95C12.3539 37.95 11.6739 38.74 11.6739 39.68ZM16.4924 42C16.2924 42 16.2324 41.84 16.3424 41.7L17.8824 39.65L16.3824 37.68C16.2724 37.53 16.3424 37.38 16.5324 37.38H16.9624C17.0424 37.38 17.1124 37.42 17.1424 37.47L18.3024 39.07H18.3224L19.5224 37.47C19.5624 37.42 19.6224 37.38 19.7024 37.38H20.1324C20.3224 37.38 20.3924 37.53 20.2824 37.68L18.7824 39.63L20.2924 41.7C20.4024 41.85 20.3224 42 20.1424 42H19.7624C19.6924 42 19.6224 41.95 19.5824 41.9L18.3224 40.16H18.3024L17.0524 41.91C17.0124 41.96 16.9424 42 16.8724 42H16.4924Z"
        fill="black"
      />
      <mask
        id="mask0_458_419"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_458_419)">
        <path
          d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V8.7C2.71667 8.51667 2.47917 8.28333 2.2875 8C2.09583 7.71667 2 7.38333 2 7V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V7C22 7.38333 21.9042 7.71667 21.7125 8C21.5208 8.28333 21.2833 8.51667 21 8.7V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 9V20H19V9H5ZM20 7V4H4V7H20ZM10 14H14C14.2833 14 14.5208 13.9042 14.7125 13.7125C14.9042 13.5208 15 13.2833 15 13C15 12.7167 14.9042 12.4792 14.7125 12.2875C14.5208 12.0958 14.2833 12 14 12H10C9.71667 12 9.47917 12.0958 9.2875 12.2875C9.09583 12.4792 9 12.7167 9 13C9 13.2833 9.09583 13.5208 9.2875 13.7125C9.47917 13.9042 9.71667 14 10 14Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default BoxIcon;