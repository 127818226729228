import { Modal } from "react-bootstrap";

const ProductCategoryDialogHeader = () => {
  return (
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
        Add/Edit Product Group
      </Modal.Title>
    </Modal.Header>
  );
};

export default ProductCategoryDialogHeader;
