import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Spinner } from "react-bootstrap";
import DeliveryGroupEditDialog from "../components/DeliveryGroupEditDialog";
import { usePlant } from "../../components/PlantHook";
import { useFetchDeliveryGroup } from "../hooks/useFetchDeliveryGroupsData";
import { useHistory, useParams } from "react-router-dom";

import EditIcon from "../../svg/EditIcon";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import isEmpty from "../../utils/isEmpty";
import { deleteDeliveryGroup } from "../api/DeliveryGroupApi";
import DeleteDeliveryGroupDialog from "../components/DeleteDeliveryGroupDialog";
import DeleteIcon from "../../svg/DeleteIcon";

const EditBundleGroupPage = (props: any) => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isDeleteGroupPopupOpen, setDeleteDeliveryGroupPopupOpen] = useState(false);

  const [selectedDeliveryGroupItem, setSelectedDeliveryGroupItem] = useState<any>(null);

  const { deliveryGroup } = useParams<{ deliveryGroup: string }>();
  const history = useHistory();

  const plant = usePlant();

  const { deliveryGroupItems, refetchDeliveryGroup, isLoading } =
    useFetchDeliveryGroup(plant, deliveryGroup);

  // comma separated articleCodes and uppercase pap
  deliveryGroupItems?.map((item) => {
    return item.articleCodesString = item.articleCodes.join(", ").replace("pap", "PAP")
  })

  // comma separated productOptions and uppercase pap
  deliveryGroupItems?.map((item) => {
    if (item.productOptions != undefined) {
      return item.productOptionsString = item.productOptions!.join(", ")
    }
    return ""
  })

  const handleFormSubmitSuccess = async () => {
    try {
      // Call the refetch function to update the delviery group data
      refetchDeliveryGroup();
    } catch (error) {
      console.error(
        "Error in handleFormSubmitSuccess on DeliveryGroupPage.tsx :",
        error
      );
    }
  };

  const handleEditClick = (singleDeliveryGroupItem: any) => {
    setSelectedDeliveryGroupItem(singleDeliveryGroupItem);
    setEditDialogOpen(true);
  };


  const handleDeleteCancelClick = () => {
    setDeletePopupOpen(false);
    setDeleteDeliveryGroupPopupOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteDeliveryGroupPopupOpen(true);
  }

  const handleDeleteGroupClick = async () => {
    await deleteDeliveryGroup(plant, deliveryGroup);
    setDeletePopupOpen(false);
    history.push(`/deliverygroups/${deliveryGroup}`);
    await refetchDeliveryGroup();
  };
  const handleCancelClick = () => {
    setEditDialogOpen(false);
  };

  const columns = [
    {
      dataField: "articleCodesString",
      text: "Product Codes",
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "productOptionsString",
      text: "Product Options",
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "maxCount",
      text: "Max items per bundle",
      classes: "text-center",
      headerClasses: "text-center",
    },
    // {
    //   dataField: "edit",
    //   classes: "text-center",
    //   headerClasses: "text-center",
    //   text: "View",
    //   formatter: (cell: any, row: { group: string }) => (
    //     <button
    //       type="button"
    //       className="btn btn-link"
    //       onClick={() => handleEditClick(row)}
    //     >
    //       <EditIcon />
    //     </button>
    //   ),
    // }
  ];

  return (
    <>
      <Card {...props}>
        <CardHeader title={"Bundle Group: " + deliveryGroup} {...props}></CardHeader>
        <CardBody {...props}>
          <h1>View Bundle Group</h1>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p className="mt-2">Loading...</p>
            </div>
          ) : isEmpty(deliveryGroupItems) ? (
            <div className="text-center mt-10 p-10">
              <p>
                Oops! The delivery group is not yet configured. Please add the
                necessary data.
              </p>
            </div>
          ) : (
            <BootstrapTable
              wrapperClasses="table-responsive column-width-control"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              striped={true}
              keyField="name"
              data={deliveryGroupItems || []}
              columns={columns}
              defaultSorted={[{ dataField: "name", order: "asc" }]}
            ></BootstrapTable>
          )}
          {/* <span style={{ marginLeft: "8px" }}>Add Delivery Group Item</span>
          <div className="delete-section mt-10">
            <h3 className="mt-10">DELETE DELIVERY GROUP</h3>
            <div>
              This will delete this delivery group. This operation is irreversible.
            </div>
            <Button
              className="mt-5"
              onClick={handleDeleteClick}
              variant="danger"
            >
              <span>Delete</span> <DeleteIcon />
            </Button>
          </div>
          {isEditDialogOpen && (
            <DeliveryGroupEditDialog
              onHide={handleCancelClick}
              deliveryGroup={deliveryGroup}
              selectedDeliveryGroupItem={
                selectedDeliveryGroupItem !== null ? selectedDeliveryGroupItem : {}
              }
              onSuccess={handleFormSubmitSuccess}
              plant={plant}
            />
          )} */}
          {/* Group Delete Modal */}
          {/* {isDeleteGroupPopupOpen && (
            <DeleteDeliveryGroupDialog
              isOpen={isDeleteGroupPopupOpen}
              onHide={handleDeleteCancelClick}
              onDelete={handleDeleteGroupClick}
              title="WARNING"
              message="Deleting this delivery group cannot be undone"
            />
          )} */}
          {isEditDialogOpen && (
            <DeliveryGroupEditDialog
              onHide={handleCancelClick}
              deliveryGroup={deliveryGroup}
              selectedDeliveryGroupItem={
                selectedDeliveryGroupItem !== null ? selectedDeliveryGroupItem : {}
              }
              onSuccess={handleFormSubmitSuccess}
              plant={plant}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default EditBundleGroupPage;
