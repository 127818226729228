import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
} from "../../../_metronic/_partials/controls";
import { CardActions } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { IPackagingGroup } from "../models/PackagingGroups";
import { Alert, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFetchPackagingGroups } from "../hooks/useFetchPackagingData";
import { usePlant } from "../../components/PlantHook";
import isEmpty from "../../utils/isEmpty";
import GearIcon from "../../svg/GearIcon";
import { useEffect, useState } from "react";
import PackagingGroupAddDialog from "../components/PackagingGroupAddDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PackagingGroupActions";
import ImportFromFile from "../../components/ImportFromFile";
import { Field } from "formik";

const PackagingPage = (props: any) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { alert, apiCallInProgress } = useSelector(
    (state: any) => ({
      alert: state.packagingGroup?.alert || null,
      apiCallInProgress: state.packaingGroup?.apiCallInProgress || false
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.dismissAlert());
  }, []);

  const plant = usePlant();

  const { isLoading, packagingGroups, error, refetchPackagingGroups } =
    useFetchPackagingGroups(plant);

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const packagingGroupNameUniquenessCheck = (newPackagingGroupName: string): boolean => {
    return !packagingGroups?.some(pg => pg.group === newPackagingGroupName);
  };

  const [packagingGroupNameToImport, setPackagingGroupNameToImport] = useState("");

  const handleAddNewPackagingGroupClick = (x: any) => {
    setAddDialogOpen(true);
  };

  const handleFormCancelClick = () => {
    setAddDialogOpen(false);
  };

  const handleFormSubmitSuccess = async () => {
    try {
      // Call the refetch function to update the packaging group data
      refetchPackagingGroups();
    } catch (error) {
      console.error(
        "Error in handleFormSubmitSuccess on PackagingPage.tsx :",
        error
      );
    }
  };

  const handleEditClick = (row: { group: string }) => {
    const newUrl = `/packaging/${row.group}`;
    history.push(newUrl);
  };

  const handleExportClick = async (row: { group: string }) => {
    await dispatch(actions.exportPackagingGroup(plant, row.group));
  };

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  const handleImport = async (file: File, dryRun: boolean): Promise<{ success: boolean, messages: string[] }> => {
    if (packagingGroupNameToImport === '') {
      return { success: false, messages: ["Please, enter a group name."] };
    }
    const response = await actions.importPackagingGroup(plant, packagingGroupNameToImport, file, dryRun);
    if (response !== undefined) {
      if (!dryRun) {
        refetchPackagingGroups();
      }
      return { success: true, messages: [`Number of Packaging Group Items ${response.packagingGroupItemsCount}.`] };
    } else {
      return { success: false, messages: ["An error occurred"] };
    }
  }

  const columns: ColumnDescription<any, any>[] = [
    {
      dataField: "group",
      text: "Group name",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "numberOfItemsInGroup",
      text: "Item count",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "edit",
      text: "",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: any, row: { group: string }) => {
        if (row.group && row.group !== "") {
          return (
            <>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleEditClick(row)}
              >
                <GearIcon />
              </button>
              <button
                type="button"
                className="btn btn-link"
                title="Export"
                onClick={() => handleExportClick(row)}
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} className="svg-icon-md" />
              </button>
            </>
          );
        }
        return "";
      },
    },
  ];

  const flattenedPackagingGroups = packagingGroups?.map(
    (packagingGroup: IPackagingGroup) => {
      return {
        group: packagingGroup.group,
        categoriesToPack:
          packagingGroup.categoriesToPack &&
            packagingGroup.categoriesToPack.length > 0
            ? packagingGroup.categoriesToPack[0]
            : "EMPTY",

        numberOfItemsInGroup:
          packagingGroup.packagingItems && packagingGroup.packagingItems.length,
      };
    }
  );

  if (error) {
    return (
      <Card {...props}>
        <CardHeader title="Available packaging overview" {...props}>
          <CardActions></CardActions>
        </CardHeader>
        <CardBody {...props}>
          <div className="text-center">
            <p>Something went wrong. Please try again later, and if the problem persists - let us know in the #plant-tech-support slack channel.</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
          <p>{alert.additionalInfo}</p>
        </Alert>
      )}
      {apiCallInProgress && (
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      <Card {...props}>
        <CardHeader title="Available packaging overview" {...props}>
          <CardActions>
            <ImportFromFile formTitle="Import Packaging Group" enableDryRun={true} onImport={handleImport} onReset={() => setPackagingGroupNameToImport("")}>
              <div className="form-group">
                <div>
                  <Field
                    component={Input}
                    name="productGroupName"
                    value={packagingGroupNameToImport}
                    onChange={(event: any) => {
                      setPackagingGroupNameToImport(event.target.value);
                    }}
                    placeholder="Packaging Group Name"
                    label="Packaging Group Name"
                  />
                </div>
              </div>
            </ImportFromFile>
          </CardActions>
        </CardHeader>
        <CardBody {...props}>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p className="mt-2">Loading...</p>
            </div>
          ) : isEmpty(packagingGroups) ? (
            <div className="text-center">
              <p>
                No packaging groups have been defined yet for this factory. You can use Add button to start configuring available packaging.
              </p>
            </div>
          ) : (
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              striped={true}
              keyField="group"
              data={flattenedPackagingGroups || []}
              columns={columns}
              defaultSorted={[{ dataField: "description", order: "asc" }]}
            ></BootstrapTable>
          )}
          <button
            title="Add Packaging Group"
            className="btn btn-light btn-hover-primary btn-sm mx-4"
            onClick={handleAddNewPackagingGroupClick}
            style={{
              borderRadius: "12px",
              border: "2px solid #38BFC4",
              backgroundColor: "white",
              color: "#38BFC4",
              display: "flex",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <span className="svg-icon svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                title="New packaging group"
                className="svg-icon-md"
              />
            </span>
            <span style={{ marginLeft: "8px" }}>Add Packaging Group</span>
          </button>
          {isAddDialogOpen && (
            <PackagingGroupAddDialog
              onSuccess={handleFormSubmitSuccess}
              onHide={handleFormCancelClick}
              plant={plant}
              packagingGroupNameUniquenessCheck={packagingGroupNameUniquenessCheck}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default PackagingPage;
