import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { CarrierOverride, CarrierOverrideStatus } from "../../models/CarrierOverrideModels";
import moment from "moment";

export const dateTimeColumnFormatter = (cellContent: any) => {  
  const format = "MMM Do YYYY, H:mm";
  return cellContent ? moment(cellContent).format(format) : "";
};

export const dateColumnFormatter = (cellContent: any) => {  
  const format = "MMM Do YYYY";
  return cellContent ? moment(cellContent).format(format) : "";
};

export const actionsColumnFormatter = (
  cellContent: any,
  carrierOverride: CarrierOverride,
  rowIndex: any,
  { handleEditClick }: { handleEditClick: any }
) => {
  return (
    <button
      title="Edit carrier override"
      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      onClick={() => handleEditClick(carrierOverride)}
      disabled={carrierOverride.status === CarrierOverrideStatus.Expired}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          title="Edit carrier override"
        />
      </span>
    </button>
  );
};

export const statusColumnFormatter = (cellContent?: CarrierOverrideStatus) => {
  if(cellContent) {
    let labelStyle = "";
    let tooltipTitle = "";
    switch(cellContent) {
      case CarrierOverrideStatus.Active:
        labelStyle = "label-light-primary";
        tooltipTitle = "Rule is currently valid";
        break;
      case CarrierOverrideStatus.Inactive:
        labelStyle = "label-light-warning";
        tooltipTitle = "Rule will become active when ‘Valid From' is reached";
        break;
      case CarrierOverrideStatus.Expired:
        labelStyle = "label-light-success";
        tooltipTitle = "'Valid To’ is in the past";
        break;
      case CarrierOverrideStatus.Disabled:
        labelStyle = "";
        tooltipTitle = "Rule has been manually disabled";
        break;
      default:
        break;
    }
    return (
      <span className={`label label-lg label-inline ${labelStyle}`} title={tooltipTitle}>{cellContent ? CarrierOverrideStatus[cellContent] : ""}</span>
    );
  }
  return "";
};
