import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { getPackagingGroups, setPackagingGroup } from "../api/PackagingApi";

let uniquenessCheck: ((newProductGroupName: string) => boolean) | undefined = undefined;

const validationSchema = Yup.object().shape({
  packagingGroupName: Yup.string()
    .nullable()
    .required("Packaging Group Name is required")
    .test("is-unique", "Such Packaging Group already exists. Please choose another name.", (value) => uniquenessCheck === undefined || !value || uniquenessCheck(value)),
});

const PackagingGroupAddForm = ({ onHide, onSuccess, plant, packagingGroupNameUniquenessCheck }: any) => {

  uniquenessCheck = packagingGroupNameUniquenessCheck;

  const onHideClick = () => {
    onHide();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          packagingGroupName: "",
          categoriesToPack: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setStatus }) => {
          try {
            await setPackagingGroup(plant, {
              packagingGroupName: values.packagingGroupName,
              categoriesToPack: [values.categoriesToPack],
            });
            setStatus("success");
            setTimeout(() => {
              onHide(); // Close the modal after a successful submission
              onSuccess();
            }, 1500);
            getPackagingGroups(plant);
          } catch (error) {
            setStatus("error");
          }
        }}
      >
        {({ handleSubmit, handleChange, status }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group">
                  <div>
                    <Field
                      name="packagingGroupName"
                      component={Input}
                      placeholder="ex. Small Envelopes or Mug Boxes or Poster Tubes ..."
                      label="Package Group Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row"></div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHideClick}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            {status === "success" && (
              <div className="text-success text-center mt-3 p-10">
                Saved successfully!
              </div>
            )}
            {status === "error" && (
              <div className="text-danger text-center mt-3 p-10">
                An error occurred.
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default PackagingGroupAddForm;
