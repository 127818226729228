import { Field, FieldArray, useFormikContext } from "formik"
import { Input, Select } from "../../../../_metronic/_partials/controls"
import { GetPlants } from "../../../components/PlantSelectorDropdown"
import { IRoutingRule } from "../../models/RoutingRuleSetModel"

const RoutingRuleFactoryEditBlock = () => {
    const { values } = useFormikContext()
    const rule = values as IRoutingRule
    const factoryList = GetPlants().map((x) => x.plantCode)

    return (
        <div className="border border-secondary mt-6">
            <p className="routing-rule-text-smallcaps ml-4 mt-4">Factory split</p>
            <div className="row border-top border-secondary p-4 mx-0">
                <div className="col-7 bg-secondary rounded p-4">
                    <FieldArray name={`decision`}>
                        {({ push: addFactory, remove: removeFactory }) => {
                            return (<>
                                {rule.decision.map((siteData, index) => (
                                    <div key={index} className="form-row py-1">
                                        <div className="form-group col-3 border border-secondary rounded mb-3">
                                            <Field
                                                component={Input}
                                                name={`decision[${index}].weight`}
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-auto mt-3">% of products go to</div>
                                        <div className="form-group col-4 border border-secondary rounded mb-3">
                                            <Field
                                                component={Select}
                                                name={`decision[${index}].productionSite`}
                                            >
                                                <option key={"select"} value="">select Factory</option>
                                                {factoryList.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </Field>
                                        </div>
                                        <button
                                            type="button"
                                            className="col-1 form-control border-0 bg-transparent text-bold text-left"
                                            onClick={() => { removeFactory(index) }}
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                                <div className="form-row mt-1">
                                    <button
                                        type="button"
                                        className="text-primary border-0 bg-transparent"
                                        onClick={() => {
                                            addFactory({
                                                productionSite: "",
                                                weight: 0
                                            })
                                        }}>
                                        <u>Add factory</u>
                                    </button>
                                </div>
                            </>
                            )
                        }}
                    </FieldArray>
                </div>
            </div>
        </div>
    )
}

export default RoutingRuleFactoryEditBlock