import React, { createContext, ReactNode, useState, useContext, useMemo } from "react";
import { conditionTypes, IRoutingRule, IRoutingRuleSet } from "./models/RoutingRuleSetModel";
import { shallowEqual, useSelector } from "react-redux";
import { RoutingRulesData } from "./redux/RoutingRulesSlice";
import { TestCase } from "./models/TestSuiteModel";
import { ArticleType } from "./models/RoutingRuleDropdownData";

export interface routingRuleContextProps extends RoutingRulesData {
    getArticleType: (article: string) => ArticleType | undefined,
    selectedRoutingRule: SelectedRoutingRule,
    setSelectedRoutingRule: (selectedRoutingRule: SelectedRoutingRule) => void,
    selectedTestCase: SelectedTestCase,
    setSelectedTestCase: (selectedTestCase: SelectedTestCase) => void,
    uiEvents: RoutingRuleUIEvents
}

type RoutingRuleUIEvents = {
    showRoutingRulesPage: () => void,
    showRuleDetailsModal: () => void,
    hideRuleDetailsModal: () => void,
    showRuleEditModal: () => void,
    hideRuleEditModal: () => void,
    showTestCasePage: () => void,
    showTestCaseEditModal: () => void,
    hideTestCaseEditModal: () => void
}

export enum SelectionReason {
    Default = "default",
    New = "new",
    Clone = "clone"
}

type SelectedRoutingRule = {
    routingRule: IRoutingRule,
    selectionReason: SelectionReason
}

type SelectedTestCase = {
    testCase: TestCase,
    selectionReason: SelectionReason
}

type Lookup = { [key: string]: string }

const initialRoutingRule = {
    id: "",
    name: "",
    sequenceNumber: 0,
    conditions: {
        conditionType: conditionTypes.conditionGroup
    },
    decision: []
}

const initialRoutingRuleSelection = {
    routingRule: initialRoutingRule,
    selectionReason: SelectionReason.Default
} as SelectedRoutingRule

const initialTestCaseSelection = {
    testCase: {},
    selectionReason: SelectionReason.Default
} as SelectedTestCase

const initialRoutingRuleSet = {
} as IRoutingRuleSet

const initialState: routingRuleContextProps = {
    alert: null,
    articleTypes: [],
    brands: [],
    changedRoutingRules: [],
    changedTestCases: [],
    channels: [],
    getArticleType: () => undefined,
    nrApiCallsInProgress: 0,
    routingRuleSet: initialRoutingRuleSet,
    selectedRoutingRule: initialRoutingRuleSelection,
    setSelectedRoutingRule: () => { },
    selectedTestCase: initialTestCaseSelection,
    setSelectedTestCase: () => { },
    testCases: [],
    uiEvents: {
        showRoutingRulesPage: () => { },
        showRuleDetailsModal: () => { },
        hideRuleDetailsModal: () => { },
        showRuleEditModal: () => { },
        hideRuleEditModal: () => { },
        showTestCasePage: () => { },
        showTestCaseEditModal: () => { },
        hideTestCaseEditModal: () => { }
    }
};

const RoutingRuleContext = createContext<routingRuleContextProps>(initialState);

export function useRoutingRuleContext() {
    return useContext(RoutingRuleContext);
}

type Props = {
    children: ReactNode,
    routingRulesUIEvents: RoutingRuleUIEvents
};

const RoutingRuleProvider = (props: Props) => {
    const [selectedRoutingRule, setSelectedRoutingRule] = useState<SelectedRoutingRule>(initialRoutingRuleSelection)
    const [selectedTestCase, setSelectedTestCase] = useState<SelectedTestCase>(initialTestCaseSelection)

    const { routingRulesData } = useSelector(
        (state: any) => ({
            routingRulesData: state.routingRulesData as RoutingRulesData
        }),
        shallowEqual
    );

    const articleTypeLookups = useMemo(() => {
        const articleTypeLookups: Lookup = {}
        routingRulesData.articleTypes.forEach((at) => at.articles.forEach((a) => articleTypeLookups[a.toLowerCase()] = at.name))
        return articleTypeLookups
    }, [routingRulesData.articleTypes])

    const getArticleType = (article: string) => {
        if (!article) {
            return undefined
        }
        const articleTypeName = articleTypeLookups[article.toLowerCase()] ?? ""
        return routingRulesData.articleTypes.find(at => at.name === articleTypeName)
    }

    const value = {
        ...routingRulesData,
        getArticleType,
        selectedRoutingRule,
        setSelectedRoutingRule,
        selectedTestCase,
        setSelectedTestCase,
        uiEvents: props.routingRulesUIEvents
    };
    return <RoutingRuleContext.Provider value={value}>{props.children}</RoutingRuleContext.Provider>;
}

export default RoutingRuleProvider;