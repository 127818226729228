import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { RootState } from "../../../redux/store";
import { LoadingDialog } from "../../../_metronic/_partials/controls";
import { useAppSelector } from "../../hooks";

export function ProductsLoadingDialog() {
  // Products Redux state
  const isLoading = useAppSelector((state: RootState) => state.products.listLoading, shallowEqual)
  // looking for loading/dispatch
  useEffect(() => { }, [isLoading]);
  return <LoadingDialog isLoading={isLoading} text="Loading ..." />;
}
