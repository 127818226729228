import {
  getLeadTimes,
  downloadLeadTimes as downloadLeadTimesApi,
  downloadLeadTimesPerProductGroup as downloadLeadTimesPerProductGroupApi,
} from '../api/ProductLeadTimeApi';
import { IAlertModel } from '../../models/LeadTimes';
import { Product } from "../ProductsContext";
import { getProducts, getProductsWithSchedules, getProduct, putProduct, putProducts, deleteScheduleWithId, exportScheduleWithId } from "../api/ProductsApi";
import { mapProductToIProductPut, mapIProductToProduct } from "../mappers/ProductsMapper";
import { productsSlice, callTypes } from "./ProductsSlice";
import moment from "moment";

const { actions } = productsSlice;
const errorTitle = 'Error while calling API';

const fetchProducts = (productGroupName: string, plant: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(actions.startCall({ callType: callTypes.list }));
        return getProducts(productGroupName, plant)
            .then(response => {
                const { totalCount, entities } = response;
                dispatch(actions.productsFetched({ totalCount, entities }));
            })
            .catch(error => {
                error.clientMessage = "Can't find products";
                dispatch(actions.catchError({ error, callType: callTypes.list }));
            });
    };

const fetchProductsWithSchedules = (productGroupName: string, plant: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(actions.startCall({ callType: callTypes.list }));
        return getProductsWithSchedules(productGroupName, plant)
            .then(response => {

                const { totalCount, entities, schedules } = response;
                dispatch(actions.productsFetched({ totalCount, entities, schedules }));
            })
            .catch(error => {
                error.clientMessage = "Can't find products";
                dispatch(actions.catchError({ error, callType: callTypes.list }));
            });
    };

const fetchProduct = (articleCode: string, productGroupName: string, plant: string, isPremiumLayFlat?: boolean) =>
    async (dispatch: (arg0: any) => void) => {
        if (!articleCode) {
            return dispatch(actions.productFetched({ productForEdit: undefined }));
        }
        dispatch(actions.startCall({ callType: callTypes.action }));
        return getProduct(articleCode, plant, isPremiumLayFlat)
            .then(response => {
                const product = mapIProductToProduct(response);
                dispatch(actions.productFetched({ productForEdit: product }));
            })
            .catch(error => {
                error.clientMessage = "Can't find product";
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    };

const fetchProductGroupLeadtimes = (plant: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(actions.startCall({ callType: callTypes.list }));
        return getLeadTimes(plant)
            .then(response => {
                let { leadTimesPerGroup } = response;
                leadTimesPerGroup = leadTimesPerGroup
                    .filter(group => group.leadTimes?.length)
                    .sort((a,b) => a.name.localeCompare(b.name))
                dispatch(actions.productGroupLeadTimesFetched({ leadTimesPerGroup }));
            })
            .catch(error => {
                error.clientMessage = "Can't find products";
                dispatch(actions.catchError({ error, callType: callTypes.list }));
            });
    };

const updateProduct = (product: Product, productGroupName: string, plant: string) =>
    (dispatch: any) => {
        dispatch(actions.startCall({ callType: callTypes.action }));
        const putProductRequest = mapProductToIProductPut(product, productGroupName, plant)
        return putProduct(putProductRequest)
            .then(() => {
                dispatch(actions.productUpdated({ product }));
            })
            .catch(error => {
                error.clientMessage = "Can't update product";
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    };

const updateProductsLeadtime = (products: Product[], leadtime: number, productGroupName: string, plant: string) =>
    (dispatch: any) => {
        dispatch(actions.startCall({ callType: callTypes.action }));
        const putProductsRequest = products.map(a => {
            let productPut = mapProductToIProductPut(a, productGroupName, plant);
            productPut.productionLeadTimeDays = leadtime;
            return productPut;
        });
        let ids = products.map(a => a.id ?? '').filter(x => x !== '');
        return putProducts(putProductsRequest)
            .then(() => {
                dispatch(actions.productsLeadtimeUpdated({ ids: ids, leadtime: leadtime }));
            })
            .catch(error => {
                error.clientMessage = "Can't update products status";
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    };

const updateProductsExtraLeadtime = (products:Product[], extraLeadTime:number, productGroupName: string, plant:string) =>
    (dispatch: any) => {
        dispatch(actions.startCall({ callType: callTypes.action }));
        const putProductsRequest = products.map(a => {
            let productPut = mapProductToIProductPut(a, productGroupName, plant);
            productPut.productionExtraLeadTimeDays = extraLeadTime;
            return productPut;
        });
        let ids = products.map(a => a.id??'').filter(x=>x!=='');
        return putProducts(putProductsRequest)
            .then(() => {
                dispatch(actions.productsExtraLeadtimeUpdated({ ids: ids, extraLeadTime: extraLeadTime }));
            })
            .catch(error => {
                error.clientMessage = "Can't update products status";
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    };

    const deleteSchedule = (plantCode:string, productGroup: string, scheduleId:string) =>
    (dispatch: any) => {
        dispatch(actions.startCall({ callType: callTypes.action }));
        return deleteScheduleWithId(plantCode, productGroup, scheduleId)
            .then(deleteScheduleResult => {
                dispatch(actions.scheduleDeleted({ scheduleId: scheduleId, deleteScheduleResult: deleteScheduleResult }));
            })
            .catch(error => {
                error.clientMessage = `Cannot delete schedule with ID ${scheduleId}`;
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    };

    const exportSchedule = (plantCode:string, productGroup: string, scheduleId:string, scheduleName:string) =>
    (dispatch: any) => {
        dispatch(actions.startCall({ callType: callTypes.action }));
        return exportScheduleWithId(plantCode, productGroup, scheduleId)
            .then(exportScheduleResult => {
                dispatch(actions.scheduleExported({ scheduleId: scheduleId, exportScheduleResult: exportScheduleResult }));

                const uniqueUrl = `leadtimes-${plantCode}-${productGroup}-${scheduleName}`;
                const url = window.URL.createObjectURL(new Blob([exportScheduleResult]));
                const link = document.createElement('a');
                link.href = url;
                const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
                link.download = `${uniqueUrl}-${dateTime}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                error.clientMessage = `Cannot export schedule with ID ${scheduleId}`;
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    };

    const deleteScheduleClosed = () =>
    (dispatch: any) => {
        dispatch(actions.deleteScheduleClosed());
    };

export const downloadLeadTimes =
  (plantCode: string) => async (dispatch: (args: any) => void) => {
    dispatch(actions.startCall({ callType: callTypes.download }));
    try {
      const leadTimes = await downloadLeadTimesApi(plantCode);
      dispatch(actions.leadTimesDownloaded());
      const uniqueUrl = `leadtimes-${plantCode}`;

      const url = window.URL.createObjectURL(new Blob([leadTimes]));
      const link = document.createElement('a');
      link.href = url;
      const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
      link.download = `${uniqueUrl}-${dateTime}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      const error = {
        title: errorTitle,
        message: "Can't download lead times",
        variant: 'danger'
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error, callType: callTypes.download }));
    }
  };

export const downloadLeadTimesPerProductGroup =
  (plantCode: string, productGroupName: string) => async (dispatch: (args: any) => void) => {
    dispatch(actions.startCall({ callType: callTypes.download }));
    try {
      const leadTimes = await downloadLeadTimesPerProductGroupApi(plantCode, productGroupName);
      dispatch(actions.leadTimesDownloaded());
      const uniqueUrl = `leadtimes-${plantCode}-${productGroupName}`;

      const url = window.URL.createObjectURL(new Blob([leadTimes]));
      const link = document.createElement('a');
      link.href = url;
      const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
      link.download = `${uniqueUrl}-${dateTime}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      const error = {
        title: errorTitle,
        message: "Can't download lead times per group",
        variant: 'danger'
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error, callType: callTypes.download }));
    }
  };

export {
  fetchProducts,
  fetchProductsWithSchedules,
  fetchProduct,
  updateProduct,
  updateProductsLeadtime,
  updateProductsExtraLeadtime,
  fetchProductGroupLeadtimes,
  deleteSchedule,
  deleteScheduleClosed,
  exportSchedule
};