import { Modal } from "react-bootstrap";
import { DeliveryGroupDialogHeader } from "./DeliveryGroupDialogHeader";
import DeliveryGroupAddForm from "./DeliveryGroupAddForm";

const DeliveryGroupAddDialog = ({ onHide, onSuccess, plant }: any) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <DeliveryGroupDialogHeader />
      <DeliveryGroupAddForm
        onHide={onHide}
        onSuccess={onSuccess}
        plant={plant}
      />
    </Modal>
  );
};

export default DeliveryGroupAddDialog;
