import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { ICarrierOverrideApi, ICarrierApiList, ICarrierConfigurationsApiList, ICarrierApi } from "../models/CarrierOverrideApiModels";

async function getCarrierOverrides(plantCode: string): Promise<ICarrierOverrideApi[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<ICarrierOverrideApi[]>(`${backendConfig.url}/carrieroverriderules/${plantCode}`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function getCarrierOverrideById(plantCode: string, id?: string): Promise<ICarrierOverrideApi> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<ICarrierOverrideApi>(`${backendConfig.url}/carrieroverriderules/${plantCode}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function createCarrierOverride(carrierOverride: ICarrierOverrideApi): Promise<any> {
  const backendConfig = await getConfig("backend");
  
  return axios
    .post(
      `${backendConfig.url}/carrieroverriderules/${carrierOverride.plantCode}`,
      carrierOverride,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
}

async function updateCarrierOverride(carrierOverride: ICarrierOverrideApi): Promise<any> {
  const backendConfig = await getConfig("backend");
  
  return axios
    .put(
      `${backendConfig.url}/carrieroverriderules/${carrierOverride.plantCode}/${carrierOverride.id}`,
      carrierOverride,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => response.data);
}

async function deleteCarrierOverride(plantCode: string, ruleId: string) : Promise<any> {
  const backendConfig = await getConfig("backend");
  
  return axios
    .delete(
      `${backendConfig.url}/carrieroverriderules/${plantCode}/${ruleId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
}

async function getCarriers(plantCode: string): Promise<ICarrierApiList> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<ICarrierApiList>(`${backendConfig.url}/carrier/${plantCode}`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function getCarrierConfigurations(plantCode: string): Promise<ICarrierApi[]> {
    const backendConfig = await getConfig("backend");

    return axios
        .get<ICarrierConfigurationsApiList>(`${backendConfig.url}/carrierconfiguratons/${plantCode}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response) => response.data.carrierConfigurations);
}

export { getCarrierOverrides, getCarrierOverrideById, createCarrierOverride, updateCarrierOverride, deleteCarrierOverride, getCarriers, getCarrierConfigurations };
