import { useMemo } from "react"
import { conditionTypes, ICondition } from "../../models/RoutingRuleSetModel"
import BootstrapTable from "react-bootstrap-table-next"

type Props = {
    condition?: ICondition, //with ConditionType === conditionGroup
    width: string
}

type DetailRow = {
    logic: string,
    condition: DetailCondition,
    isInnerGroup: boolean
    key: string
}

type DetailCondition = {
    variable: string,
    operator: string,
    values: string,
}

const getConditionDetails = (conditionGroup?: ICondition) => {
    const result = [] as DetailRow[]

    if (!(conditionGroup?.conditions) || conditionGroup.conditionType !== conditionTypes.conditionGroup || conditionGroup.conditions.length === 0) {
        return result;
    }

    conditionGroup.conditions.forEach((con, i) => {
        if (con.conditionType === conditionTypes.conditionGroup) {
            con.conditions?.forEach((con2, i2) => {
                const detailRow2 = {
                    logic: (i2 === 0) ? (conditionGroup.logicalOperator ?? '') + ' group' : (con.logicalOperator ?? ''),
                    isInnerGroup: (i2 === 0) ? false : true,
                    condition: {
                        variable: con2.variableName ?? '',
                        operator: con2.conditionOperator ?? '',
                        values: con2.evaluationValues?.join(', ') ?? '',
                    },
                    key: crypto.randomUUID()
                }
                result.push(detailRow2)
            })
        } else if (con.conditionType === conditionTypes.condition) {
            const detailRow = {
                logic: (i === 0) ? "where" : (conditionGroup.logicalOperator ?? ''),
                condition: {
                    variable: con.variableName ?? '',
                    operator: con.conditionOperator ?? '',
                    values: con.evaluationValues?.join(', ') ?? '',
                },
                isInnerGroup: false,
                key: crypto.randomUUID()
            }
            result.push(detailRow)
        }
    })

    return result
}

const RoutingRuleConditionsDetail = (props: Props) => {
    const conditions = props.condition?.conditions
    const hasConditions = conditions && conditions.length > 0

    const conditionDetails = getConditionDetails(props.condition)

    const columns = [
        {
            dataField: 'logic',
            text: 'Trigger',
            sort: false,
            classes: 'width-set-30 py-1',
            formatter: (cell: string, row: DetailRow) => {
                return (
                    <div className={(row.isInnerGroup) ? "text-primary" : "lighter"}>{cell}</div>
                )
            },
            align: (cell: string, row: DetailRow) => {
                if (row.isInnerGroup) return 'right';
                return 'left';
            }
        },
        {
            dataField: 'condition',
            text: 'condition',
            headerAttrs: {
                hidden: true
            },
            sort: false,
            classes: 'width-set-70 py-1',
            formatter: (cell: DetailCondition) => {
                return (
                    <>
                        <div className="d-flex">
                            <div className="text-success mr-2 text-capitalize">{cell.variable}</div>
                            <div className="lighter">{cell.operator}</div>
                        </div>
                        <div className="d-flex">
                            <div className="mr-2"></div>
                            <div>{cell.values}</div>
                        </div>
                    </>
                )
            }
        }]

    return (
        <>
            {!hasConditions && <div>ALWAYS</div>}
            {hasConditions && <BootstrapTable
                wrapperClasses={`table-responsive ${props.width}`}
                classes='table-borderless'
                bordered={false}
                bootstrap4
                keyField='key'
                data={conditionDetails}
                columns={columns}
            >
            </BootstrapTable>
            }
        </>
    )
}

export default RoutingRuleConditionsDetail