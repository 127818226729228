import axios from 'axios';
import { format } from 'date-fns';
import { getConfig } from '../../modules/Config/Config';
import { IProductGroups, IScheduleData } from '../../models/LeadTimes';

async function downloadLeadTimes(plantCode: string): Promise<any> {
  const backendConfig = await getConfig('backend');

  return axios
    .get<string>(
      `${
        backendConfig.url
      }/leadtime/${plantCode.toUpperCase()}/export?random=${new Date().getTime()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          responseType: 'blob',
        },
      }
    )
    .then((response) => response.data);
}

async function downloadLeadTimesPerProductGroup(
  plantCode: string,
  productGroupName: string
): Promise<any> {
  const backendConfig = await getConfig('backend');

  return axios
    .get<string>(
      `${
        backendConfig.url
      }/leadtime/${plantCode.toUpperCase()}/${productGroupName}/export?random=${new Date().getTime()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          responseType: 'blob',
        },
      }
    )
    .then((response) => response.data);
}

async function getLeadTimes(plantCode: string): Promise<IProductGroups> {
  const backendConfig = await getConfig('backend');

  return axios
    .get<IProductGroups>(
      `${backendConfig.url}/leadtime/${plantCode.toUpperCase()}/productgroups`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => response.data);
}

async function uploadLeadTimes(
  file: any,
  plantCode: string,
  dryRun: boolean,
  fileSchedulingData?: IScheduleData
): Promise<any> {
  const backendConfig = await getConfig('backend');
  const formData = new FormData();
  formData.append('file', file);

  const url = new URL(`${backendConfig.url}/leadtime/${plantCode}/import`);
  url.searchParams.append('dryRun', dryRun.toString());

  if (fileSchedulingData) {
    url.searchParams.append('scheduleId', fileSchedulingData.id || '');
    url.searchParams.append('startDate', fileSchedulingData.startDate ? format(new Date(fileSchedulingData.startDate), 'yyyy-MM-dd HH:mm') : '');
    url.searchParams.append('endDate', fileSchedulingData.endDate ? format(new Date(fileSchedulingData.endDate), 'yyyy-MM-dd HH:mm') : '');
    url.searchParams.append('scheduleName', fileSchedulingData.name || '');
  }

  return axios
    .put<string>(url.toString(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data);
}

async function calculateProductLeadTimes(calculationRequest: any, factory: string): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.post<any>(
      `${backendConfig.url}/leadtime/${factory}/testLeadTimeCombinations`,
      calculationRequest,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const modifiedData = response.data.productLeadTimeCombinationCalculations.map((item: any) => {
      const { productCode, options } = item;

      const productOptions = Object.entries(options)
        .map(([key, value]) => `${productCode} - ${key}: ${value}`)
        .join(", ");

      return {
        ...item,
        productOptions
      };
    });

    return modifiedData;
  } catch (error) {
    console.error("Error in calculateProductLeadTimes:", error);
    throw error;
  }
}

async function calculateProductLeadTimesForExport(calculationRequest: any, factory: string): Promise<any> {
  try {
    const backendConfig = await getConfig("backend");

    const response = await axios.post<any>(
      `${backendConfig.url}/leadtime/${factory}/testLeadTimeCombinations/export`,
      calculationRequest,
      {
        headers: {
          "Content-Type": "application/json",
          "responseType": "blob"
        },
      }
    );

    return response;

  } catch (error) {
    console.error("Error in calculateProductLeadTimesForExport:", error);
    throw error;
  }
}

export {
  getLeadTimes,
  downloadLeadTimes,
  uploadLeadTimes,
  downloadLeadTimesPerProductGroup,
  calculateProductLeadTimes,
  calculateProductLeadTimesForExport,
};
