import { Button, Modal } from "react-bootstrap";

export const DeleteDialog = ({
  isOpen,
  onHide,
  onDelete,
  title,
  message,
}: any) => {
  return (
    <>
      <Modal show={isOpen}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button onClick={onDelete} variant="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteDialog;
