import { createSlice } from "@reduxjs/toolkit";
import { IAlertModel } from "../models/CarrierOverrideApiModels";
import { CarrierOverride, Carrier } from "../models/CarrierOverrideModels";

type CarrierOverrideState = {
  carrierOverrides: CarrierOverride[],
  carrierOverrideForEdit?: CarrierOverride,  
  showEdit: boolean,
  alert?: IAlertModel,
  carriers: Carrier[],
}

const initialCarrierOverrideState : CarrierOverrideState = {
  carrierOverrides: [],
  carrierOverrideForEdit: undefined,  
  showEdit: false,
  alert: undefined,
  carriers: [],
};

export const carrierOverrideSlice = createSlice({
  name: "carrierOverride",
  initialState: initialCarrierOverrideState,
  reducers: {
    startCall: (state) => {
      state.alert = undefined;
      state.showEdit = false;
    },
    catchError: (state, action) => {
      const { error } = action.payload;
      state.showEdit = false;
      state.alert = error;
    },
    carrierOverrides: (state, action) => {
      const { carrierOverridesMapped } = action.payload;
      state.alert = undefined;
      state.carrierOverrides = carrierOverridesMapped;
      state.showEdit = false;
    },
    carrierOverrideCreated: (state, action) => {
      state.alert = action.payload.alert;
      state.showEdit = false;
      state.carrierOverrides.push(action.payload.carrierOverride);
    },
    carrierOverrideUpdated: (state, action) => {
      state.alert = action.payload.alert;
      state.showEdit = false;
      state.carrierOverrides = state.carrierOverrides.map((carrierOverride: CarrierOverride) => {
        if (carrierOverride.id === action.payload.updatedCarrierOverrideRule.id) {
          return action.payload.updatedCarrierOverrideRule;
        }
        return carrierOverride;
      });
    },
    carrierOverrideDeleted: (state, action) => {
      state.alert = action.payload.alert;
      state.showEdit = false;
      state.carrierOverrides.splice(state.carrierOverrides.findIndex(carrierOverride => carrierOverride.id === action.payload.ruleId));      
    },
    carrierOverrideSelected: (state, action) => {
      const { carrierOverride } = action.payload;
      state.alert = undefined;
      state.carrierOverrideForEdit = carrierOverride;
      state.showEdit = true;
    },
    carrierOverrideUnselected: (state) => {
      state.alert = undefined;
      state.showEdit = false;
      state.carrierOverrideForEdit = undefined;
    },
    carriers: (state, action) => {
      const { carriersMapped } = action.payload;      
      state.carriers = carriersMapped;      
    },
  },
});
