import { createSlice } from '@reduxjs/toolkit';
import { IRoutingRule, IRoutingRuleSet } from '../models/RoutingRuleSetModel';
import { RoutingRulesAlert } from '../models/RoutingRulesAlert';
import { TestCase, TestResult, TestCaseResult } from '../models/TestSuiteModel';
import { ArticleType, Brand, Channel } from '../models/RoutingRuleDropdownData';


export interface RoutingRulesData {
    routingRuleSet: IRoutingRuleSet,
    changedRoutingRules: ChangedRoutingRule[],
    testCases: TestCase[],
    changedTestCases: ChangedTestCase[],
    articleTypes: ArticleType[],
    brands: Brand[],
    channels: Channel[],
    nrApiCallsInProgress: number,
    alert: RoutingRulesAlert | null;
};

export type ChangedRoutingRule = {
    ruleId: string,
    originalRule?: IRoutingRule
}

export type ChangedTestCase = {
    testCaseId: string,
    originalTestCase?: TestCase
}

const initialRoutingRulesState: RoutingRulesData = {
    routingRuleSet: {
        name: "",
        description: "",
        timestamp: "",
        version: 0,
        decisionRules: []
    },
    changedRoutingRules: [],
    testCases: [],
    changedTestCases: [],
    articleTypes: [],
    brands: [],
    channels: [],
    nrApiCallsInProgress: 0,
    alert: null
};

export const callTypes = {
    get: 'get',
    post: 'post',
    put: 'put'
};

export const routingRulesSlice = createSlice({
    name: 'routingRulesData',
    initialState: initialRoutingRulesState,
    reducers: {
        catchError: (state, action) => {
            state.alert = action.payload.alert;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            }
        },
        startCall: (state, action) => {
            state.alert = null;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = state.nrApiCallsInProgress + 1;
            }
        },
        dismissAlert: (state) => {
            state.alert = null;
        },
        routingRulesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.routingRuleSet = action.payload;
        },
        testCasesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.testCases = action.payload;
        },
        articleTypesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const articleTypes = action.payload.map((at: any) => {
                return {
                    ...at,
                    articles: at.articles.map((a: string) => a.toLowerCase())
                }
            }) as ArticleType[];
            articleTypes.sort((a: ArticleType, b: ArticleType) => a.name.localeCompare(b.name));
            articleTypes.forEach(at => at.articles.sort())
            state.articleTypes = articleTypes;
        },
        brandsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const brands = [...action.payload] as Brand[];
            brands.sort((a: Brand, b: Brand): number => {
                return a.name.localeCompare(b.name)
            });
            brands.forEach(brand => brand.channels.sort())
            state.brands = brands
        },
        channelsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const channels = [...action.payload] as Channel[];
            channels.sort((a: Channel, b: Channel): number => {
                //sort alphabelically on Brand, then channel
                if (a.brand !== b.brand) {
                    return a.brand.localeCompare(b.brand)
                }
                return a.name.localeCompare(b.name)
            });
            state.channels = channels
        },
        deleteRoutingRule: (state, action) => {
            const ruleId = action.payload
            state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.filter((rule) => rule.id !== ruleId)
        },
        deleteTestCase: (state, action) => {
            const testCaseId = action.payload
            state.testCases = state.testCases.filter((test) => test.id !== testCaseId)
        },
        markRoutingRuleChanged: (state, action) => {
            const ruleId = action.payload.ruleId
            const isDeletion = action.payload.isDeletion
            //changed rule is not yet found in changes list, so it needs to be added
            if (!(state.changedRoutingRules.some((changedRule) => changedRule.ruleId === ruleId))) {
                const originalRule = state.routingRuleSet.decisionRules.find((rule) => rule.id === ruleId)
                state.changedRoutingRules.push({
                    ruleId: ruleId,
                    originalRule: originalRule
                })
            }
            //deleted rule is already present in the changes list, and is either new or a clone, so it no longer is a change
            if (isDeletion) {
                const deletedChangeRecord = state.changedRoutingRules.find((rule) => rule.ruleId === ruleId)
                if (deletedChangeRecord && !deletedChangeRecord.originalRule) {
                    state.changedRoutingRules = state.changedRoutingRules.filter((rule) => rule.ruleId !== ruleId)
                }
            }
        },
        markTestCaseChanged: (state, action) => {
            const testCaseId = action.payload.testCaseId
            const isDeletion = action.payload.isDeletion
            //changed testcase is not yet found in changes list, so it needs to be added
            if (!(state.changedTestCases.some((changedTest) => changedTest.testCaseId === testCaseId))) {
                const originalTest = state.testCases.find((test) => test.id === testCaseId)
                state.changedTestCases.push({
                    testCaseId: testCaseId,
                    originalTestCase: originalTest
                })
            }
            //deleted testcase is already present in the changes list, and is either new or a clone, so it no longer is a change
            if (isDeletion) {
                const deletedChangeRecord = state.changedTestCases.find((test) => test.testCaseId === testCaseId)
                if (deletedChangeRecord && !deletedChangeRecord.originalTestCase) {
                    state.changedTestCases = state.changedTestCases.filter((test) => test.testCaseId !== testCaseId)
                }
            }
        },
        clearRoutingRuleChanges: (state) => {
            state.changedRoutingRules = []
        },
        clearTestCaseChanges: (state) => {
            state.changedTestCases = []
        },
        upsertRoutingRule: (state, action) => {
            const newRule = action.payload;
            const ruleIsNew = state.routingRuleSet.decisionRules.find((stateRule) => stateRule.id === newRule.id) === undefined
            const needToReorderSequence = state.routingRuleSet.decisionRules.find((stateRule) =>
                stateRule.sequenceNumber === newRule.sequenceNumber && stateRule.id !== newRule.id) !== undefined
            const insertAtEnd = newRule.sequenceNumber < 0
            if (insertAtEnd) {
                //Calculate sequenceNumber for the rule to be upserted
                const maxSequenceNumber = Math.max(...state.routingRuleSet.decisionRules.map((r) => r.sequenceNumber))
                newRule.sequenceNumber = maxSequenceNumber + 1
            } else if (needToReorderSequence) {
                //Push all rules with a higher sequenceNumber down a spot to create room
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.sequenceNumber >= newRule.sequenceNumber && currentRule.id !== newRule.id) {
                        currentRule.sequenceNumber++
                    }
                    return currentRule;
                });
            }
            if (ruleIsNew) {
                //New rule will be inserted according to its sequenceNumber
                state.routingRuleSet.decisionRules.push(newRule)
            } else {
                //Replace current rule with new rule
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.id === newRule.id) {
                        return newRule;
                    }
                    return currentRule;
                });
            }
        },
        upsertTestCase: (state, action) => {
            const newTestCase = action.payload;
            const testCaseIsNew = state.testCases.find((stateTest) => stateTest.id === newTestCase.id) === undefined
            if (testCaseIsNew) {
                state.testCases.push(newTestCase)
            } else {
                //Replace current testcase with new testcase
                state.testCases = state.testCases.map((currentTest) => {
                    if (currentTest.id === newTestCase.id) {
                        return newTestCase;
                    }
                    return currentTest;
                });
            }
        },
        updateRuleSetDescription: (state, action) => {
            const description = action.payload
            state.routingRuleSet.description = description
        },
        fetchedTestSuiteResults: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const testResults = action.payload as TestResult[]
            state.testCases = state.testCases.map((test) => {
                const result = testResults.find((result) => test.id === result.testCase.id)
                return (result)
                    ? {
                        ...test,
                        testResult: {
                            failureReason: result.failureReason,
                            result: result.result,
                            selectedProductionSite: result.selectedProductionSite,
                            selectedRule: { ...result.selectedRule }
                        } as TestCaseResult
                    }
                    : test
            })
        }
    }
});