import React from 'react';

type Props = {
  copy: string;
  customStyle?: string;
  color?: string;
};

const CloneIconWithCopy = ({ copy, customStyle, color }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.166 8V5.2C16.166 4.0799 16.166 3.51984 15.948 3.09202C15.7563 2.71569 15.4503 2.40973 15.074 2.21799C14.6462 2 14.0861 2 12.966 2H5.36602C4.24591 2 3.68586 2 3.25803 2.21799C2.88171 2.40973 2.57575 2.71569 2.384 3.09202C2.16602 3.51984 2.16602 4.0799 2.16602 5.2V12.8C2.16602 13.9201 2.16602 14.4802 2.384 14.908C2.57575 15.2843 2.88171 15.5903 3.25803 15.782C3.68586 16 4.24591 16 5.36602 16H8.16602M11.366 22H18.966C20.0861 22 20.6462 22 21.074 21.782C21.4503 21.5903 21.7563 21.2843 21.948 20.908C22.166 20.4802 22.166 19.9201 22.166 18.8V11.2C22.166 10.0799 22.166 9.51984 21.948 9.09202C21.7563 8.71569 21.4503 8.40973 21.074 8.21799C20.6462 8 20.0861 8 18.966 8H11.366C10.2459 8 9.68586 8 9.25803 8.21799C8.88171 8.40973 8.57575 8.71569 8.384 9.09202C8.16602 9.51984 8.16602 10.0799 8.16602 11.2V18.8C8.16602 19.9201 8.16602 20.4802 8.384 20.908C8.57575 21.2843 8.88171 21.5903 9.25803 21.782C9.68586 22 10.2459 22 11.366 22Z"
          stroke={(color) ? color : "#7E8299"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>

    </div>
    <div>{copy}</div>
  </div>
);

export default CloneIconWithCopy;
