import * as React from "react";
const CuboidIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={46}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={24}
      height={25}
      x={7}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M7 0h24v25H7z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1C1B1F"
        d="M18 20.235v-7.136l-6-3.62v7.136l6 3.62Zm2 0 6-3.62V9.479l-6 3.62v7.136Zm-1-8.933 5.925-3.567L19 4.167l-5.925 3.568L19 11.302Zm-8 7.136a2.029 2.029 0 0 1-.738-.755A2.095 2.095 0 0 1 10 16.64V8.36c0-.382.088-.73.262-1.042.175-.313.421-.564.738-.755l7-4.193c.317-.191.65-.287 1-.287s.683.096 1 .287l7 4.193c.317.19.563.442.738.755.175.312.262.66.262 1.042v8.28c0 .383-.087.73-.262 1.043-.175.312-.421.564-.738.755l-7 4.192c-.317.191-.65.287-1 .287s-.683-.096-1-.287l-7-4.192Z"
      />
    </g>
    <path
      fill="#000"
      d="M3.498 38.51c0-2 1.61-3.61 3.61-3.61 1.01 0 1.74.35 2.42.93.08.08.09.2.01.28l-.27.27c-.08.1-.16.09-.26-.01-.52-.45-1.18-.75-1.91-.75-1.58 0-2.79 1.33-2.79 2.88 0 1.54 1.21 2.87 2.79 2.87.87 0 1.38-.3 1.91-.75.1-.08.18-.07.26.01l.23.23c.08.06.06.2-.01.27-.66.6-1.49.97-2.38.97-2 0-3.61-1.59-3.61-3.59Zm7.163 1.58V37.6c0-.11.09-.22.22-.22h.3c.12 0 .22.11.22.22v2.5c0 .92.3 1.34.97 1.34.69 0 1.1-.43 1.31-.67V37.6c0-.11.09-.22.21-.22h.31c.11 0 .21.11.21.22v4.18c0 .12-.1.22-.21.22h-.16c-.13 0-.18-.08-.21-.2l-.09-.29c-.17.18-.6.6-1.43.6-1.33 0-1.65-.8-1.65-2.02Zm5.313 1.69v-6.56c0-.11.09-.22.21-.22h.3c.11 0 .22.11.22.22v2.35s.51-.29 1.28-.29c1.38 0 2.18 1.05 2.18 2.4 0 1.32-.89 2.43-2.07 2.43-.94 0-1.46-.6-1.46-.6l-.06.3c-.02.11-.09.19-.2.19h-.18c-.12 0-.22-.1-.22-.22Zm.73-1.1s.48.76 1.31.76c.84 0 1.4-.85 1.4-1.75 0-.92-.53-1.74-1.54-1.74-.64 0-1.17.29-1.17.29v2.44Zm4.43-1c0-1.3 1.04-2.4 2.32-2.4 1.28 0 2.32 1.1 2.32 2.4 0 1.32-1.04 2.43-2.32 2.43-1.28 0-2.32-1.11-2.32-2.43Zm.73 0c0 .96.68 1.76 1.59 1.76s1.58-.8 1.58-1.76c0-.94-.67-1.73-1.58-1.73s-1.59.79-1.59 1.73Zm5.515-3.49c-.3 0-.54-.25-.54-.54a.545.545 0 1 1 1.09 0c0 .29-.24.54-.55.54Zm-.35 5.59V37.6c0-.11.1-.22.22-.22h.3c.11 0 .22.11.22.22v4.18c0 .12-.11.22-.22.22h-.3c-.12 0-.22-.1-.22-.22Zm2.015-2.1c0-1.35.8-2.4 2.19-2.4.76 0 1.28.29 1.28.29v-2.35c0-.11.1-.22.22-.22h.28c.12 0 .23.11.23.22v6.56c0 .12-.11.22-.23.22h-.18c-.1 0-.16-.08-.2-.19l-.06-.3s-.59.6-1.46.6c-1.17 0-2.07-1.11-2.07-2.43Zm.75.01c0 .9.57 1.75 1.41 1.75.83 0 1.31-.74 1.31-.74v-2.49s-.54-.26-1.18-.26c-.99 0-1.54.82-1.54 1.74Z"
    />
  </svg>
);
export default CuboidIcon;
