import { useEffect, useState } from 'react';
import ListsWidgetProductGroups from '../list-widget-product-groups/ListsWidgetProductGroups';
import * as actions from '../redux/ProductsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usePlant } from '../../components/PlantHook';

const LeadTimesPage = () => {
  const plantCode = usePlant();
  const dispatch = useDispatch();
  const [hasLeadTimesUpdated, setHasLeadTimesUpdated] = useState(false);

  const { currentState, alert } = useSelector(
    (state: any) => ({
      currentState: state.products,
      alert: state.products?.error || null,
    }),
    shallowEqual
  );

  const { productGroups } = currentState;
  useEffect(() => {
    dispatch(actions.fetchProductGroupLeadtimes(plantCode));
  }, [dispatch, plantCode, hasLeadTimesUpdated]);

  const downloadLeadTimesHandler = async () => {
    await dispatch(actions.downloadLeadTimes(plantCode));
  };

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <ListsWidgetProductGroups
          leadTimesPerGroup={productGroups != null ? productGroups : []}
          downloadLeadTimesHandler={downloadLeadTimesHandler}
          plantCode={plantCode}
          setHasLeadTimesUpdated={setHasLeadTimesUpdated}
          alert={alert}
        ></ListsWidgetProductGroups>
      </div>
    </div>
  );
};
export default LeadTimesPage;
