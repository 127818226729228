import axios from 'axios';
import { getConfig } from '../../modules/Config/Config';
import { ProductionSiteList } from './ComponentsSlice';

async function getPlantData(): Promise<ProductionSiteList> {
  const backendConfig = await getConfig('backend');
  return axios
    .get<ProductionSiteList>(`${backendConfig.url}/plants`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data);
}

export { getPlantData };
