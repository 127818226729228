import React, { useState } from 'react';
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Field, Form, Formik } from 'formik';

interface ImportFromFileProps {
    formTitle?: string,
    enableDryRun?: boolean,
    onImport: (file: File, dryRun: boolean) => Promise<{ success: boolean, messages: string[] }>,
    onReset?: () => void,
}

const ImportFromFile: React.FC<ImportFromFileProps> = ({ formTitle, enableDryRun, onImport, onReset, children }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDryRun, setDryRun] = useState(true);
  const [messages, setMessages] = useState([] as string[]);

  const showImportForm = () =>{
    setDialogOpen(true);
  };

  return (
    <div>
      <Button variant="outline-primary" onClick={showImportForm} className="d-flex align-items-center btn-sm">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Import.svg")} className="svg-icon-md" />
        <span className="ml-2">Import</span>
      </Button>
      <Formik
        initialValues={{
          fileToUpload: undefined as File | undefined
        }}
        onSubmit={async (values, { setStatus, setFieldValue }) => {
        try {
            if (values.fileToUpload !== undefined) {
            var result = await onImport(values.fileToUpload, isDryRun);
            setMessages(result.messages);
            if (result.success) {
                setStatus("success");
                if (!isDryRun && onReset) {
                onReset();
                }
            } else {
                setStatus("error");
            }
            } else {

            }
        } catch (error) {
            setStatus("error");
        }
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, status, values }) => (
          <Modal size="lg" show={isDialogOpen} aria-labelledby="example-modal-sizes-title-lg" key="Test">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                {formTitle || "Import"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group">
                  <label htmlFor="file" className="sr-only">
                    Choose a file
                  </label>
                  <Field name="fileToUpload">
                    {({ field }: any) => (
                    <input
                      type="file"
                      className="btn btn-primary btn-elevate"
                      onChange={(event) => {
                        setFieldValue(field.name, event.currentTarget.files && event.currentTarget.files[0]);
                      }}
                    />
                    )}
                  </Field>
                  {values.fileToUpload && (
                  <section>
                      <h4>File details:</h4>
                      <ul>
                      <li>Name: {values.fileToUpload.name}</li>
                      <li>Type: {values.fileToUpload.type}</li>
                      <li>Size: {values.fileToUpload.size} bytes</li>
                      </ul>
                  </section>
                  )}
                </div>
                {children}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {enableDryRun && 
                <button
                  type="submit"
                  disabled={!values.fileToUpload}
                  onClick={() => { setDryRun(true); handleSubmit(); }}
                  className="btn btn-primary btn-elevate"
                >
                  Validate
                </button>
              }
              <button
                type="submit"
                disabled={!values.fileToUpload}
                onClick={() => { setDryRun(false); handleSubmit(); }}
                className="btn btn-primary btn-elevate"
              >
                Import
              </button>
              <button
                onClick={() => { setDialogOpen(false); }}
                className="btn btn-primary btn-elevate"
              >
                Close
              </button>
            </Modal.Footer>
            {status === "success" && (
            <div className="text-success text-center mt-3 p-10">
              <div>{isDryRun ? "Valid" : "Imported successfully!"}</div>
              {messages.map((m, index) => (
                <div key={index}>{m}</div>
              ))}
              {isDryRun && (
                <div>
                  Data were not imported into the system yet.
                </div>
              )}
            </div>
            )}
            {status === "error" && (
            <div className="text-danger text-center mt-3 p-10">
              An error occurred.
              {messages.map((m, index) => (
                <div key={index}>{m}</div>
              ))}
            </div>
            )}
          </Modal>
          )}
        </Formik>
    </div>
  );
};

export default ImportFromFile;