import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { getConfig } from "../../modules/Config/Config";
import { IHolidaysList } from "../models/HolidaysModel";

async function getHolidays(
    plantCode: string,
    year?: string) : Promise<IHolidaysList> {
    const backendConfig = await getConfig("backend");
  
    return axios
      .get<IHolidaysList>(`${backendConfig.url}/calendar/${plantCode}/holidays`, 
        {
        headers: 
        {
          "Content-Type": "application/json",
        },
        params: {
            year: year
        },
        transformResponse: [function transformResponse(data) {
          if (typeof data === 'string') {
            try {
              data = JSON.parse(data);
            } catch (e) { }
          }

          data.holidays.forEach((holiday : any) =>{
            holiday.date = moment(holiday.date) 
          })


          return data;
        }],
      })
      .then((response) => response.data);
  }


async function putHolidays(
    plantCode: string,
    date?: string,
    description?: string) : Promise<AxiosResponse> {
    const backendConfig = await getConfig("backend");
  
    return axios
      .put<AxiosResponse>(`${backendConfig.url}/calendar/${plantCode}/holidays`, 
      { date: date, description: description }, 
        {
        headers: 
        {
          "Content-Type": "application/json",
        }
      })
      .then((response) => response.data);
  }

async function deleteHoliday(
  plantCode: string,
  date?: string) : Promise<AxiosResponse> {
  const backendConfig = await getConfig("backend");

  return axios
    .delete<AxiosResponse>(`${backendConfig.url}/calendar/${plantCode}/holidays`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        date: date
      }
    })
    .then((response) => response.data);
}

export { deleteHoliday, putHolidays, getHolidays };
