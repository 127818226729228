import { useDispatch } from "react-redux";
import { SelectionReason, useRoutingRuleContext } from "../../RoutingRuleContext"
import * as actions from '../../redux/RoutingRulesActions'

type Props = {
}

const TestCasesActionCard = (props: Props) => {
    const dispatch = useDispatch();
    const routingRulesContext = useRoutingRuleContext();

    const handleAddTestCaseClick = () => {
        const newTestCase = {
            id: crypto.randomUUID(),
            targetProductionSite: "",
            testProduct: {
                channelName: "",
                destinationCountry: "",
                articleCode: "",
                productOptions: {}
            }
        }
        routingRulesContext.setSelectedTestCase({ testCase: newTestCase, selectionReason: SelectionReason.New })
        routingRulesContext.uiEvents.showTestCaseEditModal()
    }

    const handleRunTestCasesClick = () => {
        dispatch(actions.runTestCases(routingRulesContext.routingRuleSet, routingRulesContext.testCases))
    }

    return (
        <div className="d-flex justify-content-between bg-secondary rounded p-3">
            <div className="d-flex width-set-85">
                <button className="border-0 bg-white rounded width-set-15" onClick={handleRunTestCasesClick}>
                    Run TestSuite
                </button>
            </div>
            <button className="border-0 bg-white rounded width-set-15" onClick={handleAddTestCaseClick}>
                <div className="d-flex justify-content-center align-content-center">
                    <div className="h1 text-primary mt-2">+</div>
                    <div className="ml-3 mt-4">Add new Test Case</div>
                </div>
            </button>
        </div>
    )
}

export default TestCasesActionCard