import { createSlice } from "@reduxjs/toolkit";
import { IAlertModel } from "../models/ProductGroup";

type ProductGroupState = {   
  alert?: IAlertModel,
  productGroup: any,
  apiCallInProgress: boolean
}

const initialProductGroupState : ProductGroupState = {  
  alert: undefined,
  productGroup: undefined,
  apiCallInProgress: false
};

export const productGroupSlice = createSlice({
  name: "productGroup",
  initialState: initialProductGroupState,
  reducers: {
    startCall: (state) => {      
      state.alert = undefined;      
      state.productGroup = undefined;
      state.apiCallInProgress = true;
    },
    exported: (state, action) => {
      const { successAlert } = action.payload;      
      state.alert = successAlert;
      state.apiCallInProgress = false;
    },
    catchError: (state, action) => {      
      const { error } = action.payload;      
      state.alert = error;
      state.productGroup = undefined;
      state.apiCallInProgress = false;
    },
    alertDismissed: (state) => {      
      state.alert = undefined;
    },
  },
});
