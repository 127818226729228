import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

// TODO: Should be rewrited to pure function
class AnimateLoading extends Component {
  animateTimeout;
  stopAnimateTimeout;
  state = {
    width: 0,
  };

  componentDidUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.animate();
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const scrollToTopBtn = document.getElementById("kt_scrolltop");
    if (scrollToTopBtn) {
      scrollToTopBtn.click();
    }
  }

  animate() {
    this.animateTimeout = setTimeout(() => {
      const { width } = this.state;
      if (width <= 100) {
        this.setState({ width: width + 10 });
        this.animate();
      } else {
        this.stopAnimate();
      }
    }, 30);
  }
  stopAnimate() {
    clearTimeout(this.animateInterval);
    this.stopAnimateTimeout = setTimeout(() => {
      this.setState({ width: 0 });
    }, 300);
  }
  componentWillUnmount() {
    if (this.stopAnimateTimeout) {
      clearTimeout(this.stopAnimateTimeout);
    }
    if (this.animateTimeout) {
      clearTimeout(this.animateTimeout);
    }
  }
  render() {
    const { width } = this.state;
    return (
      <div
        className="header-progress-bar"
        style={{ height: "3px", width: "100%" }}
      >
        {width > 0 && (
          <ProgressBar variant="info" now={width} style={{ height: "3px" }} />
        )}
      </div>
    );
  }
}

export default withRouter(AnimateLoading);
