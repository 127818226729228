import {
  LeadTimeUploadActions,
  LeadTimesUploadState,
} from '../../models/ImportLeadTimesModel';

export const initialState: LeadTimesUploadState = {
  file: null,
  error: false,
  errorMessage: '',
  success: false,
  successMessage: '',
  isDryRun: true,
  isLoading: false,
  dryRunResponseData: null,
};

export function reducer(
  state: LeadTimesUploadState,
  action: LeadTimeUploadActions
) {
  switch (action.type) {
    case 'reset_import':
      return {
        ...initialState,
      };
    case 'set_file':
      return {
        ...initialState,
        file: action.file,
      };
    case 'is_uploading':
      return {
        ...state,
        error: false,
        errorMessage: '',
        success: false,
        successMessage: '',
        isLoading: true,
        dryRunResponseData: null,
      };
    case 'dry_run_success':
      const hasLeadTimesChanged = action?.dryRunResponseData?.numberOfNewRecords !== 0 || 
        action?.dryRunResponseData?.numberOfUpdatedRecords !== 0 ||
        action?.dryRunResponseData?.scheduleDatesChanged === true;

      return {
        ...state,
        error: false,
        errorMessage: '',
        success: true,
        successMessage: hasLeadTimesChanged ?
          'Lead times successfully checked. Please review the changes, and click "Accept" to proceed with update.' :
          'No change detected, please upload another file with updates.',
        isLoading: false,
        dryRunResponseData: action.dryRunResponseData,
      };
    case 'upload_success':
      return {
        ...state,
        error: false,
        errorMessage: '',
        success: true,
        successMessage: 'Lead times successfully uploaded.',
        isLoading: false,
        dryRunResponseData: null,
        file: null,
      };
    case 'dry_run_failed':
    case 'upload_failed':
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        success: false,
        successMessage: '',
        isLoading: false,
        dryRunResponseData: null,
      };
  }
  return state;
}
