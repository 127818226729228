import { LogLevel } from "@azure/msal-browser";
import { getConfig } from "../modules/Config/Config";

// Browser check variables
const ua = window.navigator.userAgent;
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const getMsalConfiguration = async () => {
  const authConfig = await getConfig("authentication");
  const msalConfig = {
    auth: {
      clientId: authConfig.client_id,
      authority: `${authConfig.authority}/${authConfig.tenant_id}`,
      redirectUri: authConfig.redirect_url,
      postLogoutRedirectUri: authConfig.post_logout_redirect_url,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: isEdge || isFirefox,
    },
    system: {
      loggerOptions: {
        loggerCallback,
      },
    },
  };

  return msalConfig;
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

const loggerCallback = (level: LogLevel, message: string, containsPii: Boolean) => {
  if (containsPii) {
    return;
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
    default:
      return;
  }
};
