import BootstrapTable from "react-bootstrap-table-next";
import BoxIcon from "../../svg/BoxIcon";
import TubeIcon from "../../svg/TubeIcon";
import { Card, CardHeader } from "../../../_metronic/_partials/controls";
import { useState, useEffect, ChangeEvent } from "react";
import { calculatePackages } from "../api/CalculatePackaging";
import isEmpty from "../../utils/isEmpty";
import { ErrorMessage, Field, FieldArray, Form, Formik, useFormikContext } from "formik";
import { usePlant } from "../../components/PlantHook";
import * as Yup from "yup";
import DeleteIcon from "../../svg/DeleteIcon";
import { Spinner } from "react-bootstrap";
import createDictionaryFromString from "../../utils/createDictionaryFromString";
import { getCatalogLookupsBulk, filterOutOptionKeysByArticleCode, filterOutLookupsByParentId, getAllProductCodesForAFactory } from "../../products/api/ProductCatalogApi";
import React from "react";
import { IProductCatalogLookupBulkDto, IProductCatalogLookupDto } from "../../products/models/ProductCatalog";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import { IndexIntoAlpha } from "../../Tools";
import { AxiosError } from "axios";

function PapsChangeListener({ ...props }) {
  const { values } = useFormikContext<{ plantCode: string | null, products: [{ tag: string | null, quantity: number | null, articleCode: string | null, options: Array<{ key: string, value: string }> }] }>();
  const [paps, setPaps] = useState<Array<string | null | undefined>>([]);
  const onChangeNotify: any = props.onChangeNotify;

  const refreshState = () => {
    for (let i = 0; i < values.products.length; i++) {
      if (paps.length < i + 1 || paps[i] !== values.products[i].articleCode) {
        if (onChangeNotify !== undefined && onChangeNotify != null) {
          onChangeNotify(i, values.products[i].articleCode);
        }
      }
    }
    let newPaps: Array<string | null | undefined> = values.products.map(p => p.articleCode?.trim()).filter(p => p !== undefined && p != null);
    setPaps(newPaps);
  };
  React.useEffect(() => {
    refreshState();
  }, [values]);
  return (<></>);
};

const validationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      tag: Yup.string().required("Tag is required"),
      quantity: Yup.number()
        .typeError("Quantity must be a number")
        .required("Quantity is required")
        .min(1, "Quantity must be greater than 0"),
      articleCode: Yup.string().required("Article Code is required"),
    })
  ),
});

const PackAnOrder = (props: any) => {
  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const plant = usePlant();

  const handleCalculate = async (values: { plantCode: string | null, products: [{ tag: string | null, quantity: number | null, articleCode: string | null, options: [] }] }) => {
    setIsLoading(true);
    setError(null);
    values.plantCode = plant;

    try {
      const reqData = {
        ...values,
        products: values.products.map((product: any, i: number) => ({
          ...product,
          options: createDictionaryFromString(selectedProductOptions[i].map(kv => kv.key + ':' + kv.value).join(",")),
        })),
      };

      const response = await calculatePackages(reqData);
      setData(response);
    } catch (error) {
      const errorMessage =
        (error as AxiosError<any>).response?.data?.Message as string || "An unknown error occurred.";

      console.error("Error in calculatePackages:", error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewDefaultProductsEntry = (entryIndex: number): { tag: string | null, quantity: number | null, articleCode: string | null, options: [] } => {
    return {
      tag: "Product " + IndexIntoAlpha(entryIndex),
      quantity: 0,
      articleCode: "",
      options: [],
    };
  }

  const handleClearOutput = () => {
    setData(null);
  };

  const handleProductOptionKeyDropdownChange = (selectedOption: { key: string; description: string }) => {
    //dummy handler to appease the compiler
  };

  const handleProductOptionValueDropdownChange = (selectedOption: { key: string; description: string }, dataContext: { productIndex: number, optionKey: string }) => {
    let keyIdx = selectedProductOptions[dataContext.productIndex].findIndex((okv) => okv.key === dataContext.optionKey);
    if (keyIdx !== -1) {
      selectedProductOptions[dataContext.productIndex][keyIdx].value = selectedOption.key;
    }
  };


  const columns = [
    {
      dataField: "packageName",
      text: "Package Name",
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "weightGrams",
      text: "Package Weight(g)",
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "lengthMm",
      text: "Length(mm)",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "breadthMm",
      text: "Width(mm)",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "heightMm",
      text: "Height(mm)",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "shape",
      text: "Shape",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: unknown, row: { shape: string }) => {
        if (row.shape.toLowerCase() === "box") {
          return <BoxIcon />;
        }
        if (row.shape.toLowerCase() === "tube") {
          return <TubeIcon />;
        }
        return row.shape;
      },
    },
    {
      dataField: "productAndCountAsString",
      text: "contents",
      headerClasses: "text-center",
      classes: "text-center",
    },
  ];

  const [selectedProductOptions, setSelectedProductOptions] = useState<Array<Array<{ key: string; value: string }>>>([[]]);
  const [papPairs, setPapPairs] = useState<Array<{ key: string; description: string }>>([]);
  const [bulkLookupsHive, setBulkLookupsHive] = useState<Array<IProductCatalogLookupBulkDto>>([]);


  useEffect(() => {
    handleClearOutput();
    getCatalogLookupsBulk().then((hive: IProductCatalogLookupBulkDto[]) => {
      setBulkLookupsHive(hive);
      getAllProductCodesForAFactory(plant, hive).then(setPapPairs).catch(error => console.error('Failed to load PAP pairs:', error));
    }).catch(error => console.error('Failed to load lookups:', error));
  }, [plant]);

  const onPapSelected = (index: number, pap: string) => {
    if (pap !== undefined && pap != null && pap !== "") {
      let newSelectedProductOptions = selectedProductOptions;
      let newOptions: { key: string, value: string }[] = [];
      let newOptionKeys: IProductCatalogLookupDto[] = filterOutOptionKeysByArticleCode(bulkLookupsHive, pap);
      let newOptionValues: IProductCatalogLookupDto[][] = newOptionKeys.map((ok: IProductCatalogLookupDto) => filterOutLookupsByParentId(bulkLookupsHive, ok.id));

      for (let idx = 0; idx < newOptionKeys.length; idx++) {
        const ok = newOptionKeys[idx];
        const newValueObject = newOptionValues[idx] && newOptionValues[idx][0] ? newOptionValues[idx][0] : null;
        const newValue = newValueObject && newValueObject.key !== null ? newValueObject.key : "";

        if (ok && ok.key) {
          newOptions.push({ key: ok.key, value: newValue });
        } else {
          newOptions.push({ key: "", value: newValue });
        }
      }

      if (newSelectedProductOptions.length < index + 1) {
        newSelectedProductOptions.push(newOptions);
      } else {
        newSelectedProductOptions[index] = newOptions;
      }
      setSelectedProductOptions(newSelectedProductOptions);
    }
  };

  interface ErrorMessages {
    [productIntex: number]: {
      [optionKey: string]: string;
    };
  }

  const [pagesErrorMessages, setPagesErrorMessages] = useState<ErrorMessages>({});

  const validatePagesInput = (value: string) => {
    const isValid = /^[1-9]\d*$/.test(value);
    return isValid;
  };

  const handlePagesInputChange = (productIndex: number, numericOption: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    var isValid = validatePagesInput(value);
    if (isValid != null && isValid) {
      setPagesErrorMessages(prevMessages => ({
        ...prevMessages,
        [productIndex]: {
          ...prevMessages[productIndex],
          [numericOption]: ""
        }
      }));

      setSelectedProductOptions(prevOptions => {
        const newOptions = [...prevOptions];

        if (!newOptions[productIndex]) {
          newOptions[productIndex] = [];
        }

        const optionIndex = newOptions[productIndex].findIndex(okv => okv.key === numericOption);

        if (optionIndex !== -1) {
          newOptions[productIndex][optionIndex].value = value;
        } else {
          newOptions[productIndex].push({ key: numericOption, value });
        }

        return newOptions;
      });
    }
    else {
      setPagesErrorMessages(prevMessages => ({
        ...prevMessages,
        [productIndex]: {
          ...prevMessages[productIndex],
          [numericOption]: "Must be a single, positive, numeric value"
        }
      }));
    }
  };

  return (
    <>
      <Card {...props}>
        <CardHeader title="INPUT - Products, options & quantities" {...props}></CardHeader>
        <div className="row">
          <div className="col-lg-12">
            <Formik
              initialValues={{
                plantCode: plant,
                products: [
                  createNewDefaultProductsEntry(0),
                ],
              }}
              onSubmit={handleCalculate}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <Form>
                  <div className="form-group p-10">
                    <label className="h4">Products</label>
                    <table className="table table-bordered">
                      <thead className="text-center">
                        <tr>
                          <th className="col-2">Tag</th>
                          <th style={{ width: "350px" }}>Article Code</th>
                          <th className="col-1">Quantity</th>
                          <th>Options</th>
                          <th className="col-1">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="products">
                          {({ push, remove }) => (
                            <>
                              {values.products.map((product, index) => (
                                <tr key={`products_${index}`}>
                                  <td>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name={`products.${index}.tag`}
                                    />
                                    <div className="text-danger">
                                      <ErrorMessage
                                        name={`products.${index}.tag`}
                                      />
                                    </div>
                                  </td>
                                  <td key={`products_${index}_pap`}>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      name={`products.${index}.articleCode`}
                                    >
                                      <option key={`products_${index}_pap_op_default`} disabled value="">(select article code)</option>
                                      {papPairs.map((dto: { key: string, description: string }) => (
                                        <option key={`products_${index}_pap_op_${dto.key}`} value={dto.key}>{dto.description}</option>
                                      )
                                      )}
                                    </Field>
                                    <div className="text-danger">
                                      <ErrorMessage
                                        name={`products.${index}.articleCode`}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <Field
                                      type="number"
                                      className="form-control form-control"
                                      name={`products.${index}.quantity`}
                                    />
                                    <div className="text-danger">
                                      <ErrorMessage
                                        name={`products.${index}.quantity`}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>key</th>
                                            <th>&nbsp;</th>
                                            <th>value</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {filterOutOptionKeysByArticleCode(bulkLookupsHive, values.products[index].articleCode).map((optionKey, idx) => (
                                            <tr key={`products.${index}.options.${idx}`}>
                                              <td>
                                                <SingleSelectDropdown
                                                  options={[{ key: optionKey.key, description: optionKey.description }]}
                                                  initialValue={{ key: optionKey.key, description: optionKey.description }}
                                                  onSelect={handleProductOptionKeyDropdownChange}
                                                  isLocked={true} />
                                              </td>
                                              <td>&nbsp;=&nbsp;</td>
                                              <td>
                                                {(optionKey.key === "Pages") ? (
                                                  <>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      onChange={(event) => handlePagesInputChange(index, optionKey.key, event)}
                                                    />
                                                    {pagesErrorMessages[index]?.[optionKey.key] && (
                                                      <div className="text-danger">{pagesErrorMessages[index]?.[optionKey.key]}</div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <SingleSelectDropdown
                                                    options={filterOutLookupsByParentId(bulkLookupsHive, optionKey.id)}
                                                    initialValue={filterOutLookupsByParentId(bulkLookupsHive, optionKey.id)[0]}
                                                    onSelect={handleProductOptionValueDropdownChange}
                                                    isLocked={false}
                                                    dataContext={{ productIndex: index, optionKey: optionKey.key }}
                                                  />
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      type="button"
                                      className="btn btn-link"
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={5}>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-elevate ml-3 mt-5 mb-5"
                                    onClick={() =>
                                      push(createNewDefaultProductsEntry(values.products.length))
                                    }
                                  >
                                    Add Product
                                  </button>
                                </td>
                              </tr>
                            </>
                          )}
                        </FieldArray>
                      </tbody>
                    </table>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-elevate m-10"
                  >
                    Calculate
                  </button>
                  <span>Output might be outdated within 10 minutes after modifying Packaging Configuration</span>
                  <PapsChangeListener onChangeNotify={onPapSelected} />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card>
      {isLoading && (
        <div className="text-center mt-5">
          <Spinner animation="border" role="status" variant="primary"></Spinner>
          <div>Calculating...</div>
        </div>
      )}
      <button
        onClick={handleClearOutput}
        className="btn btn-danger btn-elevate ml-10 mb-5"
        type="button"
      >
        Clear output
      </button>
      <div className="text-center mt-5 mb-10 text-danger">{error}</div>

      <Card {...props}>
        <CardHeader title="OUTPUT - Packages" {...props}></CardHeader>
        {isEmpty(data) ? (
          <div className="text-center mt-10 p-10">
            <p>
              No products have been specified yet. In order to test packaging, please start by adding some products.
            </p>
          </div>
        ) : (
          <BootstrapTable
            wrapperClasses="table-responsive column-width-control"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={true}
            striped={false}
            remote
            keyField="productAndCountAsString"
            data={data || []}
            columns={columns}
            defaultSorted={[{ dataField: "shape", order: "asc" }]}
            key="calculateResultsTable"
          ></BootstrapTable>
        )}
      </Card>
    </>
  );
};

export default PackAnOrder;
