import axios from "axios";
import { getConfig } from "../../modules/Config/Config";

async function downloadSkuMappings(): Promise<any> {
  const backendConfig = await getConfig("backend");
  return axios
    .get<string>(`${backendConfig.url}/skumappings/iWays/export?random=${new Date().getTime()}`, {
      headers: {
        "Content-Type": "application/json",
        "responseType": "blob"
      }
    })
    .then((response) => response.data);    
}

async function uploadSkuMappings(file:any, dryRun:boolean): Promise<any> {
    const backendConfig = await getConfig("backend");
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .put<string>(`${backendConfig.url}/skumappings/iWays/import?dryRun=${dryRun}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => response.data);
  }

export { downloadSkuMappings, uploadSkuMappings }