import React, { createContext, ReactNode, useState, useContext } from "react";

export type Schedule = {
    id?: string;
    name: string;
    startDate: Date | null;
    endDate: Date | null;
    startDateLocal: Date | null;
    endDateLocal: Date | null;
    isActive: boolean;
}

export type Product = {
    id?: string,
    desc: string,
    leadTime: number,
    extraLeadTime: number,
    productOptionLeadTimes?: ProductOptions[],
    scheduledLeadTimes?: ScheduleProductData[],
    isActive: boolean;
}

export type ProductExtended = {
    id?: string,
    desc: string,
    leadTime: number,
    extraLeadTime: number,
    isPremiumLayFlat?: boolean,
    productOptionLeadTimes?: ProductOptions[],
    scheduledLeadTimes?: ScheduleProductData[],
    totalProductLeadTime: number,
    totalLeadTimeDays: string,
    isActive: boolean,
}

export type ProductOptions = {
    leadTime: number,
    extraLeadTime: number,
    optionKey: string,
    optionValue: string,
    totalOptionLeadTimeDisplay?: string,
    isActive: boolean,
}

export type ScheduleProductData = {
    id?: string;
    name: string;
    startDate: Date | null;
    endDate: Date | null;
    leadTime: number;
    extraLeadTime: number;
    productOptionLeadTimes?: ProductOptions[];
    isActive: boolean;
}

type ProductContextProps = {
    initProduct:Product,
    productGroupName:string,
    ids:string[],
    setIds: (ids:string[]) => void,
    openEditArticleDialog:() => void,
    openUpdateArticlesLeadtimeBatchDialog:() => void,
    openUpdateArticlesExtraLeadtimeBatchDialog:() => void,
    maxLeadtimeValue:number
}

const initProduct:Product = {
    id: undefined,
    desc: "",
    leadTime: 0,
    extraLeadTime: 0,
    isActive: false,
};

const initialState:ProductContextProps = {
    initProduct,
    productGroupName: "",
    ids: [],
    setIds : () => {},
    openEditArticleDialog : () => {},
    openUpdateArticlesLeadtimeBatchDialog: () => {},
    openUpdateArticlesExtraLeadtimeBatchDialog: () => {},
    maxLeadtimeValue: 0
};


const ProductsContext = createContext<ProductContextProps>(initialState);

export function useProductsContext() {
    return useContext(ProductsContext);
}

type Props = {
    children: ReactNode;
    productGroupName: string,
    productsUIEvents:any
};

const ProductsProvider = (props: Props) => {
    const [ids, setIds] = useState<string[]>([]);
    const value = {
        initProduct,
        productGroupName: props.productGroupName,
        ids,
        setIds,
        openEditArticleDialog: props.productsUIEvents.openEditArticleDialog,
        openUpdateArticlesLeadtimeBatchDialog: props.productsUIEvents.openUpdateArticlesLeadtimeBatchDialog,
        openUpdateArticlesExtraLeadtimeBatchDialog: props.productsUIEvents.openUpdateArticlesExtraLeadtimeBatchDialog,
        maxLeadtimeValue : 15
    };
    return <ProductsContext.Provider value={value}>{props.children}</ProductsContext.Provider>;
}

export default ProductsProvider;