import { FC, ReactElement } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { CarrierOverride } from "../../models/CarrierOverrideModels";
import {
  dateColumnFormatter,
  dateTimeColumnFormatter,
  actionsColumnFormatter,
  statusColumnFormatter,
} from "./ColumnsFormatter";

export const CarrierOverridesTable: FC<{
  carrierOverrides: CarrierOverride[];
  handleEditClick: (carrierOverride: CarrierOverride) => void;
}> = ({ carrierOverrides, handleEditClick }): ReactElement => {
  const columns = [
    {
      dataField: "carrierName",
      text: "Carrier",
    },
    {
      dataField: "overrideCarrierName",
      text: "Override Carrier",
    },
    {
      dataField: "validFrom",
      text: "Valid From",
      formatter: dateTimeColumnFormatter,
    },
    {
      dataField: "validTo",
      text: "Valid To",
      formatter: dateTimeColumnFormatter,
    },
    {
      dataField: "promisedDateTo",
      text: "Promised Date To",
      formatter: dateColumnFormatter,
    },
    {
      dataField: "marketingPromised",
      text: "Apply Only For Promised",
    },
    {
      dataField: "country",
      text: "Country",
    },
    {
      dataField: "zipCodeRangesString",
      text: "Zip Code Ranges",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Edit",
      formatter: actionsColumnFormatter,
      formatExtraData: { handleEditClick },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "50px",
      },
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      remote
      keyField="id"
      data={carrierOverrides === null ? [] : carrierOverrides}
      columns={columns}
    ></BootstrapTable>
  );
};
