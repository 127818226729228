import axios from "axios";
import { IProduct, IProductsWithSchedules, IDeleteScheduleResult, IExportProductScheduleResult } from "../../models/LeadTimes";
import { getConfig } from "../../modules/Config/Config";
import { Product, Schedule } from "../ProductsContext";

export interface IQueryResults {
  entities: Product[];
  schedules?: Schedule[];
  totalCount: number;
  errorMessage: string;
}
export interface IPutProduct {
  plantCode: string;
  articleCode: string;
  isPremiumLayFlat?: boolean;
  productionLeadTimeDays: number;
  productionExtraLeadTimeDays: number;
}

async function getProducts(productGroupName: string, plantCode: string): Promise<IQueryResults> {
  const backendConfig = await getConfig("backend");
  return axios
    .get<IProduct[]>(
      `${backendConfig.url}/leadtime/${plantCode}/${productGroupName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return {
        entities: response.data.map((val) => {
          return {
            id: val.productCode,
            desc: val.description,
            leadTime: val.leadTime,
            extraLeadTime: val.extraLeadTime,
            productOptionLeadTimes: val.productOptionLeadTimes,
          } as Product;
        }),
        totalCount: response.data.length,
        errorMessage: "",
      };
    });
}

async function getProductsWithSchedules(productGroupName: string, plantCode: string): Promise<IQueryResults> {
  const backendConfig = await getConfig("backend");
  return axios
    .get<IProductsWithSchedules>(
      `${backendConfig.url}/leadtime/${plantCode}/${productGroupName}/withschedules`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return {
        entities: response.data.leadTimes.map((val) => {
          return {
            id: val.productCode,
            desc: val.description,
            leadTime: val.leadTime,
            extraLeadTime: val.extraLeadTime,
            productOptionLeadTimes: val.productOptionLeadTimes,
            scheduledLeadTimes: val.scheduledLeadTimes,
            isActive: val.isActive,
          } as Product;
        }),
        schedules: response.data.schedules,
        totalCount: response.data.leadTimes.length,
        errorMessage: "",
      };
    });
}

async function deleteScheduleWithId(plantCode: string, productGroup: string, scheduleId: string): Promise<IDeleteScheduleResult> {
  const backendConfig = await getConfig("backend");
  const url = `${backendConfig.url}/leadtime/${plantCode}/${productGroup}/${scheduleId}`;
  return axios
    .delete(url, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function exportScheduleWithId(plantCode: string, productGroup: string, scheduleId: string): Promise<any> {
  const backendConfig = await getConfig("backend");
  const url = `${backendConfig.url}/leadtime/${plantCode}/${productGroup}/${scheduleId}/export`;
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function getProduct(articleCode: string, plantCode: string, isPremiumLayFlat?: boolean): Promise<IProduct> {
  const backendConfig = await getConfig("backend");
  const url = `${backendConfig.url}/leadtime/${plantCode}/article/${articleCode}`;

  return axios
    .get<IProduct>(url, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        isPremiumLayFlat: isPremiumLayFlat,
      },
    })
    .then((response) => response.data);
}

async function putProduct(product: IPutProduct): Promise<any> {
  const backendConfig = await getConfig("backend");
  const url = `${backendConfig.url}/leadtime/${product.plantCode}/${product.articleCode}`;
  return axios
    .put(url, product, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        isPremiumLayFlat: product.isPremiumLayFlat || null,
      },
    })
    .then((response) => response.data);
}

async function putProducts(products: IPutProduct[]): Promise<any> {
  let productPromises = products.map((product) => {
    let productPromise = putProduct(product);
    // return the promise
    return productPromise.then((response) => response.data);
  });
  return Promise.all(productPromises).then((response) => response);
}

export { getProducts, getProductsWithSchedules, getProduct, putProduct, putProducts, deleteScheduleWithId, exportScheduleWithId };
