import { routingRulesSlice } from "./RoutingRulesSlice";
import { IAlertModel } from "../models/RoutingRulesModels";
import { getRoutingRules, getTestCases, putTestCases, putRoutingRules, postRunRoutingRulesTests } from "../api/RoutingRulesApi";

const { actions } = routingRulesSlice;
const errorTitle = "Error while calling API";
const successTitle = "Success!";

export const fetchRoutingRules = () => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  await getRoutingRules()
    .then((routingRulesResponse) => {
      dispatch(actions.routingRulesFetched({ routingRulesResponse }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: "Unable to fetch routing rules",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const fetchTestCases = () => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  await getTestCases()
    .then((testCasesResponse) => {
      dispatch(actions.testCasesFetched({ testCasesResponse }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: "Unable to fetch test cases",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const saveRules = (rules: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  await putRoutingRules(rules)
    .then(() => {
      const successAlert = {
        title: successTitle,
        message: "Routing rules saved",
        variant: "success",
      } as IAlertModel;
      dispatch(actions.rulesSaved({ successAlert }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: "Unable to save rules",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const saveTestCases = (testCases: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  await putTestCases(testCases)
    .then(() => {
      const successAlert = {
        title: successTitle,
        message: "Test cases saved",
        variant: "success",
      } as IAlertModel;
      dispatch(actions.testCasesSaved({ successAlert }))
    })
    .catch((err) => {
      const errMsg = err.response.data;
      const error = {
        title: errorTitle,
        message: "Unable to save test cases",
        additionalInfo: errMsg,
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const runTests = (routingRules: string, testCases: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  await postRunRoutingRulesTests(routingRules, testCases)
    .then((response) => {
      dispatch(actions.testRunFinished(response));
    })
    .catch((err) => {
      const { hasParsingErrors, parsingErrors } = err.response.data;
      const error = {
        title: errorTitle,
        message: "Unable to run tests",
        additionalInfo: hasParsingErrors ? parsingErrors : "",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const dismissAlert = () => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.alertDismissed());
};

export const changeRoutingRules = (routingRules: string) => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.routingRulesChanged({ routingRules }));
};

export const changeTestCases = (testCases: string) => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.testCasesChanged({ testCases }));
};

export const clearTestResults = () => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.testResultsCleared());
};