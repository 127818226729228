import { Modal } from "react-bootstrap";

const DeliveryGroupDialogHeader = () => {
  return (
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
        Add Delivery Group
      </Modal.Title>
    </Modal.Header>
  );
};

const DeliveryGroupItemDialogHeader = () => {
  return (
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
        Edit Delivery Group Item
      </Modal.Title>
    </Modal.Header>
  );
};

export { DeliveryGroupDialogHeader, DeliveryGroupItemDialogHeader };
