
type Props = {
  customStyle?: string;
  color?: string;
};

const ExportIcon = ({ customStyle, color }: Props) => (
  <div className={customStyle}>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="download-02">
        <path
          id="Icon"
          d="M21.5 21H3.5M18.5 11L12.5 17M12.5 17L6.5 11M12.5 17V3"
          stroke="#007377"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </div>
);

export default ExportIcon;
