import { Product, ProductOptions, ProductExtended } from '../ProductsContext';

export const addProductOptionTotalLeadTime = (
  productOptions: ProductOptions[] | undefined
) =>
  productOptions?.map(option =>{
    const leadTime = option?.leadTime ?? 0;
    const extraLeadTime = option?.extraLeadTime ?? 0;
    const totalOptionLeadTime = leadTime + extraLeadTime;
    const daysCopy = getDayCopy(totalOptionLeadTime);
    const totalOptionLeadTimeDisplay = totalOptionLeadTime !== 0 ? `+${totalOptionLeadTime} ${daysCopy}` : ''
    return {
      ...option,
      totalOptionLeadTimeDisplay 
    }
  }
);

export const getDayCopy = ( val: number): string => val=== 1 ? 'day' : 'days';

export const formatLeadTimesCopy = (
  totalProductLeadTime: number,
): string => {
   const dayCopy = getDayCopy(totalProductLeadTime)
   return `${totalProductLeadTime} ${dayCopy}`;
};

export const extendEntites = (
  entities: Product[] | []
): ProductExtended[] | [] => {
  if (!entities?.length) return [];
  return entities.map((item) => {
    const totalProductLeadTime = item?.leadTime + item?.extraLeadTime;
    return {
      ...item,
      productOptionLeadTimes: addProductOptionTotalLeadTime(
        item.productOptionLeadTimes
      ),
      totalProductLeadTime,
      totalLeadTimeDays: formatLeadTimesCopy(
        totalProductLeadTime,
      )
    };
  });
};
