import { FC, ReactElement, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/SkuMappingsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Alert, Spinner } from "react-bootstrap";

export const SkuMappingsPage: FC<{ props: any }> = ({ props }): ReactElement => {

  const [file, setFile] = useState<File | null>(null);
  const [dryRun, setDryRun] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state: any) => ({ currentState: state.skuMappings }),
    shallowEqual
  );

  const { alert, uploadResult, apiCallInProgress } = currentState;

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onDryRunChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked);
    setDryRun(e.target.checked);
  }

  const downloadSkusMappings = async () => {
    await dispatch(actions.downloadSkuMappings());
  }

  const uploadSkusMappings = async () => {
    if (file) {
      await dispatch(actions.uploadSkuMappings(file, dryRun));
    }
  }

  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
          <p>{alert.additionalInfo}</p>
        </Alert>
      )}
      {apiCallInProgress && (
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      <Card {...props}>
        <CardHeader title="Export Sku Mappings" {...props}>
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody {...props}>
          <div className="row">
            <div className="col-lg-6">
              <button
                type="submit"
                onClick={downloadSkusMappings}
                className="btn btn-primary btn-elevate">
                Download Sku Mappings
              </button>
            </div>
          </div>
        </CardBody>
      </Card >
      <Card {...props}>
        <CardHeader title="Upload Sku Mappings" {...props}>
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody {...props}>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="file" className="sr-only">
                Choose a file
              </label>
              <input id="file" type="file" onChange={handleFileChange} className="btn btn-primary btn-elevate" />
            </div>
            {file && (
              <section>
                <h4>File details:</h4>
                <ul>
                  <li>Name: {file.name}</li>
                  <li>Type: {file.type}</li>
                  <li>Size: {file.size} bytes</li>
                </ul>
              </section>
            )}
          </div>
          {file &&
            <>
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-lg checkbox-single">
                <input type="checkbox" checked={dryRun} onChange={onDryRunChanged} />
                <span />
              </label>
              <div className="feedback">
                Dry Run
              </div>
              <button type="submit"
                className="btn btn-primary btn-elevate"
                onClick={uploadSkusMappings}>Upload</button>
            </>}
        </CardBody>
      </Card >
      {uploadResult &&
        <Card {...props}>
          <CardHeader title="Result" {...props}>
            <CardHeaderToolbar></CardHeaderToolbar>
          </CardHeader>
          <CardBody {...props}>
            <div className="feedback" style={{ "whiteSpace": "pre-wrap" }}>
              {JSON.stringify(uploadResult, null, 4)}
            </div>
          </CardBody>
        </Card>
      }
    </>
  );
};
