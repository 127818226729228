export interface IRoutingRuleSet {
    name: string,
    description: string,
    timestamp: string,
    version: number,
    decisionRules: IRoutingRule[]
}

export interface IRoutingRule {
    id: string,
    name: string,
    description?: string,
    sequenceNumber: number
    activityRules?: IActivityRule[],
    conditions: ICondition,
    decision: IProductionSiteSelectionData[]
}

export interface IActivityRule {
    activityRuleType: string,
    activeFrom?: string, //activeBetweenDatesRule
    activeUntil?: string, //activeBetweenDatesRule
    active?: boolean, //unconditionalActivationRule
    activeWeekDays?: string[] //activeOnDayOfWeekRule
}

export const activityRuleTypes = {
    unconditionalActivationRule: 'unconditionalActivationRule',
    activeBetweenDatesRule: 'activeBetweenDatesRule',
    activeOnDayOfWeekRule: 'activeOnDayOfWeekRule'
}

export interface ICondition {
    conditionType: string,
    logicalOperator?: string, //conditionGroup
    conditions?: ICondition[], //conditionGroup 
    variableName?: string, //condition
    conditionOperator?: string, //condition
    evaluationValues?: string[] //condition
}

export const conditionTypes = {
    conditionGroup: 'conditionGroup',
    condition: 'condition'
}

export const logicalOperators = {
    and: 'and',
    or: 'or'
}

export const conditionOperators = {
    equal: 'equal',
    equalOrGreater: 'equalOrGreater',
    equalOrLesser: 'equalOrLesser',
    in: 'in',
    isSubsetOf: 'isSubsetOf',
    isAny: 'isAny'
}

export interface IProductionSiteSelectionData {
    productionSite: string,
    weight: number
}

export const deepCloneConditionGroup = (conditionGroup: ICondition): ICondition => {
    return {
        ...conditionGroup,
        conditions: conditionGroup?.conditions?.map((c) => {
            return (c.conditionType === conditionTypes.conditionGroup) ?
                deepCloneConditionGroup(c) :
                { ...c }
        })
    } as ICondition
}

export const deepCloneRoutingRule = (rule: IRoutingRule): IRoutingRule => {
    return {
        ...rule,
        activityRules: rule.activityRules?.map((ar) => { return { ...ar } }),
        conditions: deepCloneConditionGroup(rule.conditions),
        decision: rule.decision?.map((psd) => { return { ...psd } })
    }
}