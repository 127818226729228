const AlphabetUpperCased: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const AlphabetLowerCased: string = "abcdefghijklmnopqrstuvwxyz";
const Numbers: string = "0123456789";
const AllAphanumerics: string = AlphabetUpperCased + AlphabetLowerCased + Numbers;

/// Just generate a unique sequence of alphanumerics
function generateUniqueId (length:number): string {
    let result:string = "";
    const charactersLength: number = AllAphanumerics.length;
    let counter: number = 0;
    while (counter < length) {
      result += AllAphanumerics.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

/// Below method represents a mini-implementation of 26-mal numeric system
/// It produces equivalent of Excel columns naming: within first 26, columns are named 
/// A through Z, but beyond this number - AA, AB ... AZ, AAA, AAB, ... ZZZ, etc.
function IndexIntoAlpha(index: number, lowerCase: boolean = false): string {
  // 0. if the number is within the count of letters of the alphabet, we just return the single
  //    corresponding letter.
  if (AlphabetUpperCased.length > index) {
    return lowerCase ? AlphabetLowerCased.charAt(index) : AlphabetUpperCased.charAt(index);
  }

  // otherwise, we'll have to deal with 'decimal' upper registries equivalents.
  let remainder: number = 0;
  let charIndexes: Array<number> = [];
  let divByAlphabetLength: number = index;

  // this loop does all the heavy lifting
  // we keep dividing by the length of the alphabet,
  // until we're out of even 26s.
  while(true) {
    //
    remainder = divByAlphabetLength % (AlphabetUpperCased.length -1);
    divByAlphabetLength = Math.floor(divByAlphabetLength / (AlphabetUpperCased.length -1));
    // splicing the target array helps us avoid recursion here
    // 1. here we put the modulus
    charIndexes.splice(0,0,remainder-1);
    if (divByAlphabetLength < (AlphabetUpperCased.length -1)) {
      // 2. and here - the 'upper' registries part
      charIndexes.splice(0,0,divByAlphabetLength - 1);
      break;
    }
  }

  // 3. and now, what remains, is resolving the indexes into the corresponding
  // alphabet letters and glueing them together.
  let result: string = "";
  for(let i=0;i<charIndexes.length; i++)
  {
    result += lowerCase ? AlphabetLowerCased.charAt(charIndexes[i]) : AlphabetUpperCased.charAt(charIndexes[i]);
  }
  return result;
}


export { generateUniqueId , IndexIntoAlpha};