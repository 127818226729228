import { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { PlantSelectorDropdown } from "../../../../app/components/PlantSelectorDropdown";



export function Topbar() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <>
      <AuthenticatedTemplate>
        <div className="topbar">
        <PlantSelectorDropdown />
          {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}
          {layoutProps.viewUserDisplay && <QuickUserToggler />}
        </div>
      </AuthenticatedTemplate>
    </>
  );
}
