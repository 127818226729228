function isEmpty(value: unknown): boolean {
  if (!value) {
    return true;
  }
  if (Array.isArray(value) || typeof value === "string") {
    return !value.length;
  }
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  }
  return false;
}

export default isEmpty;
