import { Modal } from "react-bootstrap";
import { useRoutingRuleContext } from "../../RoutingRuleContext";
import RoutingRuleConditionsDetail from "./RoutingRuleConditionsDetail";
import RoutingRuleFactoryDetail from "./RoutingRuleFactoryDetail";

type Props = {
}

const RoutingRuleDetailModal = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext();

    const handleOkClick = () => {
        routingRulesContext.uiEvents.hideRuleDetailsModal()
    }

    const handleEditClick = () => {
        routingRulesContext.uiEvents.hideRuleDetailsModal()
        routingRulesContext.uiEvents.showRuleEditModal()
    }

    return (
        <Modal size="lg" dialogClassName="routing-rule" show={true} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Routing Rule Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <RoutingRuleConditionsDetail width="width-set-70" condition={routingRulesContext.selectedRoutingRule?.routingRule.conditions} />
                    <RoutingRuleFactoryDetail width="width-set-30" decision={routingRulesContext.selectedRoutingRule?.routingRule.decision} />
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        onClick={handleEditClick}
                        className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                        Edit
                    </button>
                    <button
                        onClick={handleOkClick}
                        className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                        Close
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RoutingRuleDetailModal;