import React from "react";

interface CheckboxProps {
  label?: string;
  isChecked: boolean;
  onToggle: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label="", isChecked, onToggle }) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
      />
      {label}
    </label>
  );
};

export {Checkbox}
