import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { IDeliveryGroup, IDeliveryGroupPreview, IDeliveryGroups } from "../models/DeliveryGroup";

async function getDeliveryGroups(
  plantCode: string
): Promise<IDeliveryGroups> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IDeliveryGroups>(
      `${backendConfig.url}/deliverygroups/${plantCode}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getDeliveryGroups:", error);
    throw error;
  }
}

async function getDeliveryGroup(
  plantCode: string,
  deliveryGroupName: string
): Promise<IDeliveryGroup> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IDeliveryGroup>(
      `${backendConfig.url}/deliverygroups/${plantCode}/${deliveryGroupName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getDeliveryGroup:", error);
    throw error;
  }
}

async function getDeliveryGroupPreview(
  plantCode: string,
  deliveryGroupName: string
): Promise<IDeliveryGroupPreview> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IDeliveryGroupPreview>(
      `${backendConfig.url}/deliverygroups/${plantCode}/${deliveryGroupName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getDeliveryGroup:", error);
    throw error;
  }
}

async function setDeliveryGroup(
  plantCode: string,
  deliveryGroupName: string,
  deliveryGroupData: any
): Promise<IDeliveryGroups> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    console.log(deliveryGroupName)

    if (!deliveryGroupName || typeof deliveryGroupName !== "string") {
      throw new Error(
        `Invalid delivery group name: ${deliveryGroupName}. Please provide a valid name for delivery group.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.post<IDeliveryGroups>(
      `${backendConfig.url}/deliverygroups/${plantCode}/?deliveryGroupName=${deliveryGroupName}`,
      deliveryGroupData,
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in setDeliveryGroup:", error);
    throw error;
  }
}

async function deleteDeliveryGroup(
  plantCode: string,
  deliveryGroupName: string
): Promise<IDeliveryGroup> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.delete<IDeliveryGroup>(
      `${backendConfig.url}/deliverygroups/${plantCode}/${deliveryGroupName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in deleteDeliveryGroup:", error);
    throw error;
  }
}


export {
  getDeliveryGroups,
  getDeliveryGroup,
  getDeliveryGroupPreview,
  setDeliveryGroup,
  deleteDeliveryGroup
};
