import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Alert, Button, Spinner } from "react-bootstrap";
import PackagingGroupEditDialog from "../components/PackagingGroupEditDialog";
import DeleteDialog from "../components/DeleteDialog";
import { usePlant } from "../../components/PlantHook";
import { useFetchPackagingGroup } from "../hooks/useFetchPackagingData";
import { useHistory, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  deletePackagingGroup,
  deletePackagingGroupItem,
} from "../api/PackagingApi";
import EditIcon from "../../svg/EditIcon";
import DeleteIcon from "../../svg/DeleteIcon";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import "./pages.scss";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import isEmpty from "../../utils/isEmpty";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PackagingGroupActions";
import { IExportPackagingGroupItemsCommand, IAlertModel, IPackagingItem, PackagingItem } from "../models/PackagingGroups";

const EditPackagingGroupPage = (props: any) => {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isDeleteGroupPopupOpen, setDeleteGroupPopupOpen] = useState(false);

  const [selectedPackagingItem, setSelectedPackagingItem] = useState<IPackagingItem | null | undefined>(null);
  const [selectedPackagingGroupItems, setSelectedPackagingGroupItems] = useState<string[]>([]);

  const { packagingGroup } = useParams<{ packagingGroup: string }>();
  const history = useHistory();

  const dispatch = useDispatch();

  const { alert, apiCallInProgress } = useSelector(
    (state: any) => ({
      alert: state.packagingGroup?.alert || null,
      apiCallInProgress: state.packagingGroup?.apiCallInProgress || false
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.dismissAlert());
  }, []);

  const plant = usePlant();

  const { packagingGroupItems, refetchPackagingGroup, isLoading } =
    useFetchPackagingGroup(plant, packagingGroup);

  const handleFormSubmitSuccess = async () => {
    try {
      // Call the refetch function to update the packaging group data
      refetchPackagingGroup();
    } catch (error) {
      console.error(
        "Error in handleFormSubmitSuccess on PackagingPage.tsx :",
        error
      );
    }
  };

  const handleEditClick = (singlePackagingItem: IPackagingItem) => {
    let itemIndex = packagingGroupItems?.findIndex(i => i.name === singlePackagingItem.name);
    if (itemIndex !== undefined && itemIndex !== null && itemIndex !== -1 && packagingGroupItems != null) {
      setSelectedPackagingItem(packagingGroupItems[itemIndex]);
      setEditDialogOpen(true);
    }
  };

  const handleAddNewPackagingItemClick = () => {
    setSelectedPackagingItem(null);
    setEditDialogOpen(true);
  };

  const handleCancelClick = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteCancelClick = () => {
    setDeletePopupOpen(false);
    setDeleteGroupPopupOpen(false);
  };

  const handleDeleteClick = () => {
    if (selectedPackagingItem) {
      // If a single item is selected, open the single item delete modal
      setDeletePopupOpen(true);
    } else {
      // If no item is selected, open the group delete modal
      setDeleteGroupPopupOpen(true);
    }
  };

  const handleDeleteGroupClick = async () => {
    await deletePackagingGroup(plant, packagingGroup);
    setDeletePopupOpen(false);
    history.push(`/packaging`);
    await refetchPackagingGroup();
  };

  const handleSingleItemDeleteClick = async (row: any) => {
    const { name } = row;
    setSelectedPackagingItem(name);
    setDeletePopupOpen(true);
  };

  const handleSingleItemDeleteConfirm = async (name: any) => {
    try {
      await deletePackagingGroupItem(plant, packagingGroup, name);
      await refetchPackagingGroup();
      setDeletePopupOpen(false);
    } catch (error) {
      console.error("Error deleting packaging group item:", error);
    }
  };

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  const handleExport = async () => {
    if (selectedPackagingGroupItems.length === 0) {
      const alertModel = {
        title: "Selection Required",
        message: "Please select packaging group items to export.",
        variant: "danger",
      } as IAlertModel;
      await dispatch(actions.showAlert(alertModel));
      return;
    }
    const exportCommand: IExportPackagingGroupItemsCommand = {
      PackagingItems: selectedPackagingGroupItems
    };
    await dispatch(actions.exportPackagingGroupItems(plant, packagingGroup, exportCommand));
  }

  const handlePackagingGroupItemSelected = (packagingGroupItem: string) => {
    setSelectedPackagingGroupItems(currentSelectedPackagingGroupItems => {
      if (currentSelectedPackagingGroupItems.includes(packagingGroupItem)) {
        return currentSelectedPackagingGroupItems.filter(name => name !== packagingGroupItem);
      } else {
        return [...currentSelectedPackagingGroupItems, packagingGroupItem];
      }
    });
  };

  const selectAllPackagingGroupItemsToggle = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedPackagingGroupItems(packagingGroupItemsData?.map(item => item.name) || []);
    } else {
      setSelectedPackagingGroupItems([]);
    }
  };

  const createDefaultPackagingItem = (): IPackagingItem => {
    return new PackagingItem();
  };
  const packagingGroupItemsData = packagingGroupItems?.map(
    (packagingGroupItem) =>
    ({
      name: packagingGroupItem?.name,
      shape: packagingGroupItem?.shape,
      fitsProductShapes: packagingGroupItem?.fitsProductShapes,
      innerDimension: {
        lengthMm: packagingGroupItem?.innerDimension?.lengthMm,
        breadthMm: packagingGroupItem?.innerDimension?.breadthMm,
        heightMm: packagingGroupItem?.innerDimension?.heightMm,
        weightGr: packagingGroupItem?.innerDimension?.weightGr
      },
      outerDimension: {
        lengthMm: packagingGroupItem?.outerDimension?.lengthMm,
        breadthMm: packagingGroupItem?.outerDimension?.breadthMm,
        heightMm: packagingGroupItem?.outerDimension?.heightMm,
        weightGr: packagingGroupItem?.outerDimension?.weightGr
      },
      selected: selectedPackagingGroupItems.includes(packagingGroupItem.name),
      minHeight: packagingGroupItem.minFlexInnerHeight
    } || [])
  );

  const columns = [
    {
      dataField: "name",
      text: "Item",
      classes: "text-center",
      headerClasses: "text-center",
      title: (cell: any) => `${cell !== undefined ? `This is the minimal size of the flex packaging ${cell})` : "Fixed size"}`
    },
    {
      dataField: "shape",
      text: "Type",
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "fitsProductShapes",
      text: "Fits",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: string[]) => `${cell.join(', ')}`,
    },
    {
      dataField: "outerDimension",
      text: "Outer Dimensions\nL x W x H (mm)",
      headerClasses: "break-header text-center",
      classes: "text-center",
      formatter: (cell: {
        lengthMm: number;
        breadthMm: number;
        heightMm: number;
      }) => `${cell.lengthMm} x ${cell.breadthMm} x ${cell.heightMm}`,
    },
    {
      dataField: "innerDimension",
      text: "Inner Dimensions\nL x W x H (mm)",
      headerClasses: "break-header text-center",
      classes: "text-center",
      formatter: (cell: {
        lengthMm: number;
        breadthMm: number;
        heightMm: number;
      }) => `${cell.lengthMm} x ${cell.breadthMm} x ${cell.heightMm}`,
    },
    {
      dataField: "outerDimension.weightGr",
      text: "Weight (g)",
      classes: "text-center",
      headerClasses: "text-center",
    },
    {
      dataField: "minHeight",
      text: "Flexible",
      classes: "text-center",
      headerClasses: "text-center",
      title: (cell: any) => `${cell !== undefined ? `This is the minimal size of the flex packaging ${cell})` : "Fixed size"}`,
      formatter: (cell: {
      }) => `${cell !== undefined ? `Flex (Min Height ${cell})` : "Fixed size "}`
    },
    {
      dataField: "edit",
      classes: "text-center",
      headerClasses: "text-center",
      text: "",
      formatter: (cell: any, row: IPackagingItem) => (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => handleEditClick(row)}
        >
          <EditIcon />
        </button>
      ),
    },
    {
      dataField: "delete",
      classes: "text-center",
      headerClasses: "text-center",
      text: "",
      formatter: (cell: any, row: { group: string }) => (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => handleSingleItemDeleteClick(row)}
        >
          <DeleteIcon />
        </button>
      ),
    },
    {
      dataField: "select",
      text: "Select",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: any, row: any) => (
        <input
          type="checkbox"
          checked={row.selected}
          onChange={() => handlePackagingGroupItemSelected(row.name)}
        />
      ),
      headerFormatter: () => (
        <>
          <label htmlFor="chkBoxSelectAll">select all</label><br /><input
            type="checkbox"
            checked={selectedPackagingGroupItems.length === packagingGroupItemsData?.length}
            onChange={(e) => selectAllPackagingGroupItemsToggle(e.target.checked)}
            id="chkBoxSelectAll"
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Card {...props}>
        <CardHeader title="Available packaging Overview" {...props}></CardHeader>
        <CardBody {...props}>
          {alert && (
            <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
              <Alert.Heading>{alert.title}</Alert.Heading>
              <p>{alert.message}</p>
              <p>{alert.additionalInfo}</p>
            </Alert>
          )}
          {apiCallInProgress && (
            <Spinner animation="border" role="status" variant="primary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="font-weight-bold font-size-h3">{packagingGroup}</div>
            <Button variant="outline-primary" onClick={handleExport} className="d-flex align-items-center btn-sm">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} className="svg-icon-md" />
              <span className="ml-2">Export</span>
            </Button>
          </div>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p className="mt-2">Loading...</p>
            </div>
          ) : isEmpty(packagingGroupItemsData) ? (
            <div className="text-center mt-10 p-10">
              <p>
                No items have been added to this packaging group yet. You can use Add button to add an item.
              </p>
            </div>
          ) : (
            <BootstrapTable
              wrapperClasses="table-responsive column-width-control"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              striped={false}
              keyField="name"
              data={packagingGroupItemsData || []}
              columns={columns}
              defaultSorted={[{ dataField: "name", order: "asc" }]}
            ></BootstrapTable>
          )}
          <button
            title="Add new item"
            className="btn btn-light btn-hover-primary btn-sm mx-4"
            onClick={handleAddNewPackagingItemClick}
            style={{
              borderRadius: "12px",
              border: "2px solid #38BFC4",
              backgroundColor: "white",
              color: "#38BFC4",
              display: "flex",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <span className="svg-icon svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                title="New product group"
                className="svg-icon-md"
              />
            </span>
            <span style={{ marginLeft: "8px" }}>Add New Item</span>
          </button>
          <div className="delete-section mt-10">
            <h3 className="mt-10">DELETE PACKAGING GROUP</h3>
            <div>
              This will delete current packaging group all and its items from the factory configuration.
            </div>
            <Button
              className="mt-5"
              onClick={handleDeleteClick}
              variant="danger"
            >
              <span>Delete entire group</span> <DeleteIcon />
            </Button>
          </div>
          {isEditDialogOpen && (
            <PackagingGroupEditDialog
              onHide={handleCancelClick}
              packagingGroup={packagingGroup}
              selectedPackagingItem={
                selectedPackagingItem !== null ? selectedPackagingItem : createDefaultPackagingItem()
              }
              onSuccess={handleFormSubmitSuccess}
              plant={plant}
            />
          )}
          {/* Group Delete Modal */}
          {isDeleteGroupPopupOpen && (
            <DeleteDialog
              isOpen={isDeleteGroupPopupOpen}
              onHide={handleDeleteCancelClick}
              onDelete={handleDeleteGroupClick}
              title="WARNING"
              message="Deleting this packaging group and all of its items cannot be undone"
            />
          )}

          {/* Single Item Delete Modal */}
          {selectedPackagingItem && isDeletePopupOpen && (
            <DeleteDialog
              isOpen={isDeletePopupOpen}
              onHide={handleDeleteCancelClick}
              onDelete={() =>
                handleSingleItemDeleteConfirm(selectedPackagingItem)
              }
              title="Delete Packaging Item"
              message={`Are you sure you want to delete this item '${selectedPackagingItem}'?`}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default EditPackagingGroupPage;
