import React from "react";

export function Checkbox({
  isSelected,
  onChange,
  children,
  showFeedbackLabel = false,
  feedbackLabel,
}) {
  return (
    <>
      <input type="checkbox" style={{display: "none"}} />
      <label className="checkbox checkbox-lg checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        {children}
        <span />
      </label>
      <div className="feedback">
        {showFeedbackLabel && feedbackLabel && <>{feedbackLabel}</>}
      </div>
    </>
  );
}
