import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../redux/ProductsActions";
import { RootState } from "../../../redux/store";
import { usePlant } from "../../components/PlantHook";
import { useAppSelector } from "../../hooks";
import { Product, useProductsContext } from "../ProductsContext";
import NumericInput from 'react-numeric-input';


const selectedProducts = (entities: Product[], ids: string[]) => {
    const _products: Product[] = [];
    ids.forEach((id) => {
        const product = entities.find((el) => el.id === id);
        if (product) {
            _products.push(product);
        }
    });
    return _products;
};
type Props = {
    show: boolean,
    maxProductsToDisplay: number,
    onHide: () => void
}
const ProductsUpdateExtraLeadtimeDialog = (props: Props) => {
    const { show, onHide } = props;
    // Products UI Context
    const plant = usePlant();
    const productsContext = useProductsContext();
    const productsUIProps = useMemo(() => {
        return {
            ids: productsContext.ids,
            setIds: productsContext.setIds,
            productGroupName: productsContext.productGroupName,
        };
    }, [productsContext]);

    // Products Redux state
    const { products, isLoading } = useAppSelector(
        (state: RootState) => {
            return {
                products: selectedProducts(state.products.entities, productsUIProps.ids),
                isLoading: state.products.actionsLoading
            }
        },
        shallowEqual
    );
    // if !id we should close modal
    useEffect(() => {
        if (!productsUIProps.ids || productsUIProps.ids.length === 0) {
            onHide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsUIProps.ids]);

    const [extraLeadtime, setExtraLeadtime] = useState(0);
    const dispatch = useDispatch();
    const updateExtraLeadtime = () => {
        if (extraLeadtime <= productsContext.maxLeadtimeValue) {
            // server request for update products status by selected ids
            dispatch(actions.updateProductsExtraLeadtime(products, extraLeadtime, productsUIProps.productGroupName, plant))
            // dispatch(actions.fetchProducts(productsUIPropss.productGroupName, plant))
        }
        // clear selections list
        productsUIProps.setIds([]);
        setExtraLeadtime(0);
        // closing delete modal
        // onHide();     
    }

    const groupedProducts = products.reduce((groups, { id, extraLeadTime }) => {
        if (!groups[extraLeadtime]) groups[extraLeadtime] = [];
        groups[extraLeadtime].push(id);
        return groups;
    }, {} as any);

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Update <span className="text-primary">Extra Leadtime</span> for all selected articles
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                {/*begin::Loading*/}
                {isLoading && (
                    <div className="overlay-layer">
                        <div className="spinner spinner-lg spinner-primary" />
                    </div>
                )}
                {/*end::Loading*/}
                {products.length <= props.maxProductsToDisplay &&
                    <table className="table table table-head-custom table-vertical-center overflow-hidden">
                        <thead>
                            <tr>
                                <th>CODE</th>
                                <th>EXTRA LEADTIME</th>
                                <th>DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={`id${product.id}`}>
                                    <td>{product.id}</td>
                                    <td>{product.extraLeadTime}</td>
                                    <td>
                                        <span className="ml-3">
                                            {product.desc}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                {products.length > props.maxProductsToDisplay &&
                    Object.keys(groupedProducts).map((key, index) => (
                        <div className="font-weight-bold font-size-h6 mt-2">
                            <span className="text-primary text-right" style={{ display: 'inline-block', width: '1.6em' }}>{groupedProducts[key].length}</span><span> Article{groupedProducts[key].length === 1 ? '' : 's'} with Extra Lead Time </span><span className="text-primary">{key}</span><br />
                        </div>
                    ))
                }
            </Modal.Body>
            <Modal.Footer className="form">
                <div className="form-group">
                    <NumericInput
                        className="form-control"
                        value={extraLeadtime}
                        max={productsContext.maxLeadtimeValue}
                        onChange={value => setExtraLeadtime(value ?? 0)}
                    />
                </div>
                <div className="form-group">
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate mr-3"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={updateExtraLeadtime}
                        className="btn btn-primary btn-elevate"
                    >
                        Update All
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export { ProductsUpdateExtraLeadtimeDialog };
