import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AxiosStatic } from "axios";
import { loginRequest } from "../app/authentication/msalConfig";

async function getToken(msalInstance: any) {
  try {
    const token = await msalInstance.acquireTokenSilent(loginRequest);
    return token.idToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(loginRequest)
    }
    return null;
  }
}

export default function setupAxios(axios: AxiosStatic,  msalInstance: any) {
  axios.interceptors.request.use(
    async (config) => {
      const authToken = await getToken(msalInstance);

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}