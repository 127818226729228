import { FC, ReactElement, useEffect } from "react";
import { CarrierOverrideEditDialog } from "./carrier-override-edit-dialog/CarrierOverrideEditDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/CarrierOverrideActions";
import {
  Card,
  CardBody,
  CardHeader,  
} from "../../../_metronic/_partials/controls";
import { CarrierOverridesTable } from "./carrier-override-table/CarrierOverridesTable";
import { Alert } from "react-bootstrap";
import _ from "lodash";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { CarrierOverride, Carrier } from "../models/CarrierOverrideModels";
import { usePlant, useCarrierConfigurationSupport } from "../../components/PlantHook";
import { CardActions } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const CarrierOverridesPage: FC<{ props: any }> = ({ props }): ReactElement => {
  const plantCode = usePlant();
  const plantSupportsCarrierConfiguration = useCarrierConfigurationSupport()
  const dispatch = useDispatch();
  
  const { currentState } = useSelector(
    (state: any) => ({ currentState: state.carrierOverrides }),
    shallowEqual
  );

  const { carrierOverrides, showEdit, carrierOverrideForEdit, alert, carriers } = currentState;

  useEffect(() => {
    dispatch(actions.getCarrierOverridesAction(plantCode));
    dispatch(actions.getCarriersAction(plantCode));
  }, [dispatch, plantCode]);

  const handleEditClick = (selectedCarrierOverride: CarrierOverride) => {
    dispatch(actions.selectCarrierOverride(selectedCarrierOverride));
  };

  const save = (carrierOverride: CarrierOverride) => {    
    if (carrierOverride.id){
      dispatch(actions.updateCarrierOverrideAction(carrierOverride));
    }
    else {
      dispatch(actions.createCarrierOverrideAction(carrierOverride));
    }
    KTUtil.scrollTop(100,500);
  };

  const handleDelete = (ruleId: string) => {    
    dispatch(actions.deleteCarrierOverridesAction(plantCode, ruleId));
    KTUtil.scrollTop(100,500);
  };

  const handleHideDismiss = () => {
    dispatch(actions.unselectCarrierOverride());
  };

  const handleNewClick = () => {
    const carrierOverride = {
      id: undefined,
      plantCode: plantCode,
      country: "",
      promisedDateFrom: undefined,
      promisedDateTo: undefined,
      validFrom: "",
      validTo: "",
      carrierName: "",
      overrideCarrierName: "",
      disabledOn: undefined,
      description: '',
      enabled: true,
      zipCodeRangesString: "",
      zipCodeRanges: [],
      marketingPromised: false
    } as CarrierOverride;    
    dispatch(actions.selectCarrierOverride(carrierOverride));
  };

  const sortedCarrierOverrides = (carrierOverrides: CarrierOverride[]) : CarrierOverride[] => {
    return _.orderBy(carrierOverrides, carrierOverride => carrierOverride.country.toLowerCase(), 'asc');
  }

  const sortedCarriers = (carriers: Carrier[]) : Carrier[] => {
    return _.orderBy(carriers, carrier => carrier.carrierName.toLowerCase(), 'asc');
  }

  if (!plantSupportsCarrierConfiguration){
    return ( <Card {...props}>
      <CardHeader title={"Carrier Overrides Not Supported For " + plantCode} {...props}>
      </CardHeader>
    </Card>)
  }
  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleHideDismiss} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
        </Alert>
      )}
      <Card {...props}>
        <CardHeader title="Carrier Overrides" {...props}>          
          <CardActions>
              <button
                title="New carrier override"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => handleNewClick()}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                    title="New carrier override"
                  />
                </span>
              </button>
          </CardActions>
        </CardHeader>
        <CardBody {...props}>
          <CarrierOverridesTable
            carrierOverrides={sortedCarrierOverrides(carrierOverrides)}
            handleEditClick={handleEditClick}
          ></CarrierOverridesTable>
        </CardBody>
      </Card>
      {carrierOverrideForEdit && <CarrierOverrideEditDialog
        carrierOverrideForEdit={carrierOverrideForEdit}
        saveCarrierOverride={(values: any) => {
          save(values);
        }}
        showModal={showEdit}
        deleteCarrierOverride={() => {
          handleDelete(carrierOverrideForEdit.id);
        }}        
        hideModal={() => {
          handleHideDismiss();
        }}
        carriers={sortedCarriers(carriers)}        
      ></CarrierOverrideEditDialog>}
    </>
  );
};
