export interface IPackagingGroups {
  packagingGroups: IPackagingGroup[];
}

export interface IPackagingGroup {
  factory: string;
  group: string;
  packagingItems: IPackagingItem[];
  categoriesToPack: string[];
}

export interface IPackageDimension {
  lengthMm: number;
  breadthMm: number;
  heightMm: number;
  weightGr: number;
}

export class PackageDimension implements IPackageDimension {
  lengthMm: number;
  breadthMm: number;
  heightMm: number;
  weightGr: number;
  constructor() {
    this.lengthMm = this.breadthMm = this.heightMm = this.weightGr = 0;
  }
}

export interface IPackagingItem {
  name: string;
  shape: string;
  fitsProductShapes: string[];
  innerDimension: IPackageDimension;
  outerDimension: IPackageDimension;
  minFlexInnerHeight: number|undefined|null;
  categoriesToPack: string[];
  surfaceArea: number;
  volume: number;
}

export class PackagingItem implements IPackagingItem {
  name: string;
  shape: string;
  fitsProductShapes: string[];
  innerDimension: IPackageDimension;
  outerDimension: IPackageDimension;
  minFlexInnerHeight: number|undefined|null;
  categoriesToPack: string[];
  surfaceArea: number;
  volume: number;
  constructor() {
    this.name = "";
    this.shape = "";
    this.fitsProductShapes = [];
    this.innerDimension = new PackageDimension();
    this.outerDimension = new PackageDimension();
    this.minFlexInnerHeight = 0;
    this.categoriesToPack = [];
    this.surfaceArea = this.volume = 0;
  }
}

export interface IAlertModel {
  title: string;
  message: string;
  variant: string;
}

export interface IExportPackagingGroupItemsCommand {
  PackagingItems: string[];
}