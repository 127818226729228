import { conditionOperators, logicalOperators } from './RoutingRuleSetModel'

export const conditionOperatorOptions = [
    conditionOperators.equal,
    conditionOperators.equalOrGreater,
    conditionOperators.equalOrLesser,
    conditionOperators.in,
    conditionOperators.isSubsetOf,
    conditionOperators.isAny,
]

export const logicalOperatorOptions = [
    logicalOperators.and,
    logicalOperators.or,
]

export type Channel = {
    name: string,
    brand: string
}

export type Brand = {
    name: string,
    channels: string[]
}

export type ArticleType = {
    name: string,
    articles: string[]
}

export const productOptionDropdownOptions = [
    {
        variableName: "Border",
        evaluationValues: [
            "BorderOption",
        ]
    },
    {
        variableName: "BoxFrame",
        evaluationValues: [
            "BK",
            "NT",
            "WH",
        ]
    },
    {
        variableName: "Color",
        evaluationValues: [
            "BK",
            "BL",
            "GN",
            "PK",
            "RD",
            "WT",
        ]
    },
    {
        variableName: "CoverText",
    },
    {
        variableName: "CoverType"
    },
    {
        variableName: "FloatingFrame",
        evaluationValues: [
            "BK",
            "NT",
            "WH",
        ]
    },
    {
        variableName: "Glossy",
        evaluationValues: [
            "false",
            "true",
        ]
    },
    {
        variableName: "HangingKit",
        evaluationValues: [
            "KitSystem",
            "OrangeHook",
            "SteelPlate"]
    },
    {
        variableName: "Layflat",
        evaluationValues: [
            "Heavy",
            "HeavyPremium",
        ]
    },
    {
        variableName: "Material",
        evaluationValues: [
            "CAN",
            "VEL"
        ]
    },
    {
        variableName: "Pages",
    },
    {
        variableName: "Paper",
        evaluationValues: [
            "ExtraMatte",
            "ExtraWhite",
            "Glossy",
            "Matte",]
    },
    {
        variableName: "Passepartout",
        evaluationValues: [
            "WH5",
        ]
    },
    {
        variableName: "PhotoSide",
        evaluationValues: [
            "DBL",
            "SGL"
        ]
    },
    {
        variableName: "PosterFrame",
        evaluationValues: [
            "BK",
            "NT",
            "WH",
        ]
    },
    {
        variableName: "PrebuiltPosterFrame",
        evaluationValues: [
            "BK",
            "NT",
            "WH",
        ]
    },
    {
        variableName: "PremiumLayFlat",
        evaluationValues: [
            "false",
            "true",
        ]
    },
    {
        variableName: "PrintSize",
        evaluationValues: [
            "PrintSize10x10",
            "PrintSize10x12",
            "PrintSize10x13",
            "PrintSize10x15",
            "PrintSize13x13",
            "PrintSize13x17",
            "PrintSize13x18",
            "PrintSize13x19",
            "PrintSize15x20",
            "PrintSize20x25",
        ]
    },
]

export const conditionDropdownOptions = [
    {
        variableName: "ArticleCode"
    },
    {
        variableName: "ArticleType",
        evaluationValues: [
            "AcrylicPhotoBlock",
            "Aluminium",
            "AluminiumButler",
            "Calendar",
            "Canvas",
            "Canvas4cm",
            "Cushion",
            "CutoutPhotoBook",
            "Forex",
            "GreetingCard",
            "HardCoverPhotoBook",
            "HardPhotoCoverPhotoBook",
            "Jigsaw",
            "LoveMug",
            "MagicMug",
            "Magnet",
            "MomentsPhotoBook",
            "Mug",
            "PaddedPhotoBook",
            "Perspex",
            "Poster",
            "Prints",
            "SoftCoverPhotoBook",
            "WoodenPhotoBlock"
        ]
    },
    {
        variableName: "ChannelName",
        evaluationValues: [
            "albelli.nl",
            "albelli.de",
            "albelli.fr",
            "albelli.be",
            "albelli.se",
            "bonusprint.co.uk",
            "fotoknudsen.no",
            "hofmann.es",
            "hofmann.pt",
            "monalbumphoto.fr",
            "posterxxl.de",
            "posterxxl.at",
            "b2b.posterxxl.de",
            "api.posterxxl.de",
            "photobox.co.uk",
            "photobox.fr",
            "photobox.ie",
            "photobox.it",
            "photobox.dk",
        ]
    },
    {
        variableName: "DeliveryMethod",
        evaluationValues: [
            "Home",
            "PUDO"
        ]
    },
    {
        variableName: "DeliveryType",
        evaluationValues: [
            "Standard",
            "Express",
            "Fast"
        ]
    },
    {
        variableName: "DestinationCountry"
    },
    {
        variableName: "DestinationPostcode"
    },
    {
        variableName: "ReceiverName"
    },
    ...productOptionDropdownOptions
]
