import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { IRoutingRuleSet } from "../models/RoutingRuleSetModel";
import { TestCase, TestResult } from "../models/TestSuiteModel";

type GetRoutingRulesResponse = {
  routingRules: IRoutingRuleSet
}

async function getRoutingRules(): Promise<IRoutingRuleSet> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<GetRoutingRulesResponse>(`${backendConfig.url}/productionsiteroutingrules?ruleSetVersion=0`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.routingRules);
}

type SaveRoutingRulesCommand = {
  routingRules: IRoutingRuleSet
}

type SaveRoutingRulesResponse = {
  routingRules: IRoutingRuleSet
}

async function postRoutingRules(ruleSet: IRoutingRuleSet): Promise<IRoutingRuleSet> {
  const backendConfig = await getConfig("backend");
  const command = {
    routingRules: ruleSet
  } as SaveRoutingRulesCommand

  return axios
    .post<SaveRoutingRulesResponse>(`${backendConfig.url}/productionsiteroutingrules`,
      command,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.routingRules);
}

type GetTestCasesResponse = {
  testCases: TestCase[]
}

async function getTestCases(): Promise<TestCase[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<GetTestCasesResponse>(`${backendConfig.url}/productionsiteselectiontests`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.testCases);
}

type PutTestCasesResponse = {
  testCases: TestCase[]
}

async function putTestCases(testCases: TestCase[]): Promise<TestCase[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .put<PutTestCasesResponse>(`${backendConfig.url}/productionsiteselectiontests`,
      testCases,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.testCases);
}

type TestSuite = {
  productionSiteRoutingRules: IRoutingRuleSet
  testCases: TestCase[]
}

type RunTestSuiteCommand = {
  productionSiteSelectionTestSuite: TestSuite
}

type RunTestSuiteResponse = {
  testResults: TestResult[]
}

async function runTestSuite(ruleSet: IRoutingRuleSet, testcases: TestCase[]): Promise<TestResult[]> {
  const backendConfig = await getConfig("backend");
  const command = {
    productionSiteSelectionTestSuite: {
      productionSiteRoutingRules: ruleSet,
      testCases: testcases
    }
  } as RunTestSuiteCommand

  return axios
    .post<RunTestSuiteResponse>(`${backendConfig.url}/productionsiteselectiontests`,
      command,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.testResults);
}

export { getRoutingRules, postRoutingRules, getTestCases, putTestCases, runTestSuite };
