import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import {
    IProductCatalogLookupDto,
    IProductCatalogLookupBulkDto,
    ProductCatalogLookupType
} from "../models/ProductCatalog";
import { IArticle, IProductCategories, IProductCategory } from "../models/ProductGroups";
import { getProductCategories } from "./ProductsApi";
import { lowerCase, upperCase } from "lodash";

async function getCatalogProductTypes():
    Promise<IProductCatalogLookupDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupDto[]>(
            `${backendConfig.url}/productcatalog/producttypes`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error in getCatalogProductTypes:", error);
        throw error;
    }
}

async function getCatalogProductCodes(productType: string):
    Promise<IProductCatalogLookupDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupDto[]>(
            `${backendConfig.url}/productcatalog/${productType}/productcodes`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error in getCatalogProductCodes:", error);
        throw error;
    }
}

async function getCatalogOptionKeys(articleCode: string):
    Promise<IProductCatalogLookupDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupDto[]>(
            `${backendConfig.url}/productcatalog/${articleCode}/optionkeys`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error in getCatalogOptionKeys:", error);
        throw error;
    }
}

async function getCatalogOptionKeysByProductType(productType: string | undefined):
    Promise<IProductCatalogLookupDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupDto[]>(
            `${backendConfig.url}/productcatalog/${productType}/optionkeys?byType=productType`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error in getCatalogOptionKeys:", error);
        throw error;
    }
}

async function getCatalogOptionValues(productType: string | undefined,
    optionKey: string):
    Promise<IProductCatalogLookupDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupDto[]>(
            `${backendConfig.url}/productcatalog/${productType}/${optionKey}/optionvalues`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error in getCatalogOptionValues:", error);
        throw error;
    }
}

async function getCatalogOptionConstraints(productType: string | undefined,
    optionKey: string):
    Promise<IProductCatalogLookupDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupDto[]>(
            `${backendConfig.url}/productcatalog/${productType}/${optionKey}/optionconstraints`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error in getCatalogOptionValues:", error);
        throw error;
    }
}

async function getCatalogLookupsBulk():
    Promise<IProductCatalogLookupBulkDto[]> {
    try {

        const backendConfig = await getConfig("backend");

        const response = await axios.get<IProductCatalogLookupBulkDto[]>(
            `${backendConfig.url}/productcatalog/lookupsbulk`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;

    } catch (error) {
        console.error("Error in getCatalogLookupsBulk:", error);
        throw error;
    }
}

async function getAllProductCodes(): Promise<IProductCatalogLookupDto[]> {
    const bulkResponse = await getCatalogLookupsBulk();
    const response: IProductCatalogLookupDto[] = [];
    bulkResponse.forEach((dto: IProductCatalogLookupBulkDto) => {
        if (dto.lookupType === ProductCatalogLookupType.productCode || dto.lookupType.toString() === "productCode") {
            response.push(...dto.children);
        }
    });
    return response.sort((one: IProductCatalogLookupDto, two: IProductCatalogLookupDto) => one.key.localeCompare(two.key));
}

async function getAllProductCodesForAFactory(factory: string, syncWithinBulkLookups: IProductCatalogLookupBulkDto[]): Promise<IProductCatalogLookupDto[]> {
    const productGroupsRaw: IProductCategories = await getProductCategories(factory);
    const productCodes: Array<string> = [];
    const response: IProductCatalogLookupDto[] = [];
    productGroupsRaw.productCategories.forEach((prodCat: IProductCategory) => {
        prodCat.articles.forEach((article: IArticle) => {
            productCodes.push(article.articleCode);
        });
    });
    syncWithinBulkLookups.forEach((dto: IProductCatalogLookupBulkDto) => {
        if (dto.lookupType === ProductCatalogLookupType.productCode || dto.lookupType.toString() === "productCode") {
            dto.children.forEach((dtoChild: IProductCatalogLookupDto) => {
                if (productCodes.findIndex(code => code === dtoChild.key || upperCase(code) === upperCase(dtoChild.key)) !== -1) {
                    response.push(dtoChild);
                }
            });
        }
    });
    return response.sort((one: IProductCatalogLookupDto, two: IProductCatalogLookupDto) => one.key.localeCompare(two.key));
}

function filterOutOptionKeysByArticleCode(lookupsHive: IProductCatalogLookupBulkDto[], articleCode: string|null) : Array<IProductCatalogLookupDto> {
    let response: IProductCatalogLookupDto[] = [];
    let papId: number|null = -1;
    lookupsHive.forEach((dto: IProductCatalogLookupBulkDto) => {
        if (dto.lookupType === ProductCatalogLookupType.productCode || dto.lookupType.toString() === "productCode") {
            let currIdx: number = dto.children.findIndex((l: IProductCatalogLookupDto) => l.key == articleCode);
            if (currIdx >= 0)
            {
                papId = dto.children[currIdx].id;
            }
        }
    });
    
    lookupsHive.forEach((dto: IProductCatalogLookupBulkDto) => {
        if ((dto.lookupType === ProductCatalogLookupType.optionKey || dto.lookupType.toString() === "optionKey") && dto.parentId === papId) {
            response.push(...dto.children);
        }
    });
    return response.sort((one: IProductCatalogLookupDto, two: IProductCatalogLookupDto) => one.key.localeCompare(two.key));
}

function filterOutLookupsByParentId(lookupsHive: IProductCatalogLookupBulkDto[], parentId: number|null) : Array<IProductCatalogLookupDto> {
    let response: IProductCatalogLookupDto[] = [];
    let bFound: boolean = false;
    lookupsHive.forEach((dto: IProductCatalogLookupBulkDto) => {
        if (!bFound && dto.parentId === parentId) {
            response.push(...dto.children);
            bFound = true;
        }
    });
    return response.sort((one: IProductCatalogLookupDto, two: IProductCatalogLookupDto) => one.key.localeCompare(two.key));
}

export {
    getCatalogProductTypes,
    getCatalogProductCodes,
    getCatalogOptionKeys,
    getCatalogOptionKeysByProductType,
    getCatalogOptionValues,
    getCatalogOptionConstraints,
    getCatalogLookupsBulk,
    getAllProductCodes,
    getAllProductCodesForAFactory,
    filterOutOptionKeysByArticleCode,
    filterOutLookupsByParentId
};

