import { Dropdown, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input } from "../../../../_metronic/_partials/controls";
import { getProductCategories, setProductCategory } from "../../api/ProductsApi";
import { useState, useEffect } from "react";
import MultiSelectDropdownWithCheckboxes from "../../../components/MultiSelectDropdown";
import * as Yup from "yup";
import { getBundleTypesLookup, getProductShapesLookup } from "../../../packaging/api/PackagingApi";
import { IProductCategories, IProductCategory } from "../../models/ProductGroups";
import { getCatalogProductTypes } from "../../api/ProductCatalogApi";

let uniquenessCheck: ((newProductGroupName: string) => boolean) | undefined = undefined;

const validationSchema = Yup.object().shape({
  productKind: Yup.string()
    .nullable()
    .required("Product Group Name is required")
    .test("is-unique", "Such Product Group already exists. Please choose another name.", (value) => uniquenessCheck === undefined || !value || uniquenessCheck(value)),
  productType: Yup.string()
    .nullable()
    .required("Product Type is required"),
  consolidationType: Yup.string()
    .nullable()
    .test("is-required", "Consolidation Type is required", (value) => value !== ""),
  defaultShape: Yup.string()
    .nullable()
    .test("is-required", "Shape is required", (value) => value !== ""),
});

const initSelectedProductKindsToMix = (bundleWithValue: string|null|undefined) : Array<{key:string, description: string}> => {
  if (bundleWithValue == null || bundleWithValue === undefined || bundleWithValue?.trim().length === 0) {
    return [];
  } else {
    return bundleWithValue.split(',').map((p: string) => {return { key:p?.trim(), description: p?.trim()};} )
  }
};
const ProductCategoryAddForm = ({ onHide, onSuccess, plant, editProductGroup, productGroupNameUniquenessCheck }: any) => {
  const [selectedConsolidationType, setSelectedConsolidationType] = useState(editProductGroup?.consolidationType);
  const [selectedShape, setSelectedShape] = useState(editProductGroup?.shape);
  const [selectedProductKinds, setSelectedProductKinds] = useState<Array<{ key: string; description: string }>>(initSelectedProductKindsToMix(editProductGroup?.bundleWith));
  const [initiallySelectedProductKinds] = useState<Array<{ key: string; description: string }>>((editProductGroup?.bundleWith || "").split(',').map((p: string) => {return { key:p.trim(), description: p.trim()};} ));
  const [productKinds, setProductKinds] = useState<Array<{ key: string; description: string }>>([]);
  const [consolidationTypes, setConsolidationTypes] = useState<Array<{ key: string; description: string }>>([]);
  const [productShapes, setProductShapes] = useState<Array<{ key: string; description: string }>>([]);
  const [selectedConsolidationTypeLabelText, setSelectedConsolidationTypeLabelText] = useState<string>("Select a consolidation type");
  const [productTypes, setProductTypes] = useState<{ key: string, description: string }[]>([]);

  uniquenessCheck = productGroupNameUniquenessCheck;

  const onConsolidationTypeOptionSelect = (eventKey: string | null): void => {
    if (eventKey !== null) {
      setSelectedConsolidationType(eventKey);
      setSelectedConsolidationTypeLabelText(consolidationTypes.find(bt => bt.key === eventKey)?.description || "");
    }
  };

  const onShapeOptionSelect = (eventKey: string | null): void => {
    if (eventKey !== null) {
      setSelectedShape(eventKey);
    }
  };

  const handleProductKindDropdownChange = (selectedProductKinds: Array<{ key: string; description: string }>) => {
    setSelectedProductKinds(selectedProductKinds);
  };

  useEffect(() => {
    getCatalogProductTypes().then(productTypes => setProductTypes(productTypes)).catch(error => console.error('Failed to load product types:', error));
    loadProductKinds().then(setProductKinds).catch(error => console.error('Failed to load product codes:', error));
    getBundleTypesLookup().then(consolidationTypes => {
      setConsolidationTypes(consolidationTypes);

      if (selectedConsolidationType !== undefined) {
        setSelectedConsolidationTypeLabelText(consolidationTypes.find(bt => bt.key === selectedConsolidationType)?.description || "");
      }
    }).catch(error => console.error('Failed to load consolidation types:', error));
    getProductShapesLookup().then(setProductShapes).catch(error => console.error('Failed to load product shapes:', error));
    setSelectedProductKinds(initiallySelectedProductKinds);
    handleProductKindDropdownChange(selectedProductKinds);
  }, [editProductGroup]);

  const loadProductKinds = (): Promise<{ key: string, description: string }[]> => {
    return getProductCategories(plant).then((results: IProductCategories) => {
      return results.productCategories.map((item: IProductCategory) => {
        return {
          key: item.name, 
          description: item.name
        };
      });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          productKind: editProductGroup?.productGroup,
          productKindsToMix: editProductGroup?.bundleWith,
          productType: editProductGroup?.productType,
          defaultShape: editProductGroup?.shape,
          consolidationType: editProductGroup?.consolidationType,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setStatus }) => {
          try {
            await setProductCategory(plant, values.productKind, [
              {
                productKind: values.productKind,
                factory: plant,
                articles: !editProductGroup ? [] : null,
                productType: values.productType,
                defaultShape: selectedShape,
                bundleType: selectedConsolidationType,
                productKindsToMix: selectedProductKinds?.map((p: {key:string,description:string}) => p.key)
              },
            ]);
            setStatus("success");
            setTimeout(() => {
              onHide(); // Close the modal after a successful submission
              onSuccess();
            }, 1500);
            getProductCategories(plant);
          } catch (error) {
            setStatus("error");
          }
        }}
      >
        {({ handleSubmit, handleChange, status, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group">
                  <div>
                    <Field
                      name="productKind"
                      component={Input}
                      placeholder="ex. Mugs, Small Posters, Cards "
                      label="Product Group Name"
                      disabled={(editProductGroup !== undefined)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row mt-5 p-4">
                  <div>
                    <label>Select type of products in this group:</label>
                    <div className="dropdown" key="typeOfProductsSelect">
                      <Field name="productType" as="select" 
                              placeholder="Canvas, Mug"
                              label="Product Type"
                              >
                        <option>(select product type)</option>
                      {productTypes.map((dto: {key: string, description: string}) => (
                        <option value={dto.key}>{dto.description}</option>
                      )
                      )}
                      </Field>
                    </div>
                    <div className="text-danger"><ErrorMessage name="productType" /></div>
                  </div>
                </div>
                <div className="form-group row mt-5 p-4">
                  <div>
                    <label>Select a default shape of products in this group</label>
                    <Dropdown key="defaultShapeOfProductsSelect">
                      <Dropdown.Toggle variant="success">
                        {selectedShape || "Select a shape"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {productShapes.map((dto: {key: string, description: string}) => (
                        <Dropdown.Item
                        onSelect={onShapeOptionSelect}
                        eventKey={dto.key}
                        key={dto.key}
                      >
                        {dto.description}
                      </Dropdown.Item>
                        )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {!selectedShape && (
                      <div className="text-danger">Shape is required</div>
                    )}
                  </div>
                </div>
                <div className="form-group row mt-5 p-4">
                  <div>
                    <label>Select a consolidation type (packing together):</label>
                    <Dropdown key="consolidationType">
                      <Dropdown.Toggle variant="success" key="consolidationTypeToggle">
                        {selectedConsolidationTypeLabelText}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {consolidationTypes.map((dto: {key: string, description: string}) => (
                        <Dropdown.Item
                        onSelect={onConsolidationTypeOptionSelect}
                        eventKey={dto.key}
                      >
                        {dto.description}
                      </Dropdown.Item>
                        )
                      )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {!selectedConsolidationType && (
                      <div className="text-danger">Consolidation Type is required</div>
                    )}
                  </div>
                </div>
                {selectedConsolidationType === "MixKindSameShapeMixSize" && (
                <div className="form-group row mt-5 p-4">
                  <div>
                    <label>Consolidate with:</label>
                    <div>
                      <div>
                        <MultiSelectDropdownWithCheckboxes 
                        options={productKinds.filter(pk => pk.key !== values.productKind)} 
                        onSelect={handleProductKindDropdownChange} 
                        selectedOptions={selectedProductKinds}
                        isLocked={false}
                        label="Choose which product groups can be consolidated (packed together) with this one"
                        controlId="prdKinds2Mix"/>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            {status === "success" && (
              <div className="text-success text-center mt-3 p-10">
                Saved successfully!
              </div>
            )}
            {status === "error" && (
              <div className="text-danger text-center mt-3 p-10">
                An error occurred.
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default ProductCategoryAddForm;
