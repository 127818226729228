import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../_metronic/_partials/controls";
import * as Yup from "yup";

const defaultDeliveryGroupItem = {
  articleCodes: [""],
  maxCount: 0,
  productOptions: [""]
};

const DeliveryGroupEditForm = ({
  onHide,
  onSuccess,
  deliveryGroup,
  selectedDeliveryGroupItem = defaultDeliveryGroupItem,
  plant,
}: any) => {
  const onHideClick = () => {
    onHide();
  };

  const [errorMessage, setErrorMessage] = useState("");

  // Validation was started, but not completed
  const validationSchema = Yup.object().shape({
    articleCodes: Yup.string().required("Required")
  });

  const initialValues = {
    deliveryGroupName: deliveryGroup || "",
    codes: selectedDeliveryGroupItem ? selectedDeliveryGroupItem.articleCodes || [""] : [""],
    options: selectedDeliveryGroupItem ? selectedDeliveryGroupItem.productOptions || [""] : [""],
    maxCount: selectedDeliveryGroupItem ? selectedDeliveryGroupItem.maxCount || 0 : 0,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setStatus }) => {
        try {
          const editedDeliveryGroupItem = {
            deliveryGroupName: values.deliveryGroupName,
            deliveryGroupItem: {
              articleCodes: values.codes,
              productOptions: values.options,
              maxCount: values.maxCount
            },
          };
          setStatus("success");
          // Here should go function call to set delivery group items
          setTimeout(() => {
            onHide(); // Close the modal after a successful submission
            onSuccess();
          }, 1500);
        } catch (error: any) {
          setStatus("error");
          setErrorMessage(error.message);
          console.error(
            "Error occured while editing the delivery group item",
            error
          );
        }
      }}
    >
      {({ handleSubmit, status }) => (
        <Form>
          <Modal.Body className="overlay overlay-block cursor-default">
            <div className="mb-5">Edit {deliveryGroup}</div>
            <div className="form-group">
              <Field
                name="codes"
                component={Input}
                placeholder="pap____"
                label="Article Codes"
              />
            </div>
            <div className="form-group">
              <Field
                name="options"
                component={Input}
                placeholder="Product Options"
                label="Product Options"
              />
            </div>            
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHideClick}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
            >
              Save
            </button>
          </Modal.Footer>
          {status === "success" && (
            <div className="text-success text-center mt-3 p-10">
              Saved successfully!
            </div>
          )}
          {status === "error" && (
            <div className="text-danger text-center mt-3 p-10">
              An error occurred: {errorMessage}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DeliveryGroupEditForm;
