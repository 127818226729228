import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../redux/ProductsActions";
import { RootState } from "../../../redux/store";
import { usePlant } from "../../components/PlantHook";
import { useAppSelector } from "../../hooks";
import { Product, useProductsContext } from "../ProductsContext";
import { ProductEditDialogHeader } from "./ProductEditDialogHeader";
import { ProductEditForm } from "./ProductEditForm";

type Props = {
  id: string;
  show: boolean;
  isPremiumLayFlat?: boolean;
  onHide: () => void;
};
export const ProductEditDialog = (props: Props) => {
  const { id, show, isPremiumLayFlat, onHide } = props;
  // Products UI Context
  const plant = usePlant();
  const productsContext = useProductsContext();
  const productsUIProps = useMemo(() => {
    return {
      initProduct: productsContext.initProduct,
    };
  }, [productsContext]);

  // Product Redux state
  const dispatch = useDispatch();
  const { productForEdit, actionsLoading } = useAppSelector(
    (state: RootState) => state.products,
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Product by id
    if (id && productsContext.productGroupName && plant) {
      dispatch(
        actions.fetchProduct(
          id,
          productsContext.productGroupName,
          plant,
          isPremiumLayFlat
        )
      );
    }
  }, [id, productsContext.productGroupName, dispatch, plant, isPremiumLayFlat]);

  // server request for saving product
  const saveProduct = (product: Product) => {
    if (id) {
      // server request for updating product
      dispatch(
        actions.updateProduct(product, productsContext.productGroupName, plant)
      );
      onHide();
    }
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ProductEditDialogHeader id={id} />
      <ProductEditForm
        saveArticle={saveProduct} // TODO: article -> product
        actionsLoading={actionsLoading}
        article={productForEdit || productsUIProps.initProduct} // TODO: article -> product
        onHide={onHide}
      />
    </Modal>
  );
};
