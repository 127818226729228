import { FC, ReactElement } from "react";

export const TestResults: FC<{
  testResult: boolean;
  testSummary: string;
  runTests: () => void;
  clearTestResults: () => void;
}> = ({ testResult, testSummary, runTests, clearTestResults }): ReactElement => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <h4>Test Results</h4>
          </div>
          <div className="col-lg-8">
            <button onClick={clearTestResults} className="btn btn-secondary btn-sm">
              Clear test results
            </button>
          </div>
          <div className="col-lg-2">
            <button type="submit" onClick={runTests} className="btn btn-primary btn-elevate">
              Run tests
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <textarea 
              id="summary" 
              value={testSummary} 
              rows={15}
              cols={144}
              style={{fontFamily: "Courier", font: "Courier", wordWrap: "normal", whiteSpace: "pre"}}
              key={"currentSummary_" + (10000 + Math.random() * (1000000 - 10000))}
              readOnly></textarea>
          </div>
        </div>
      </div>
    </>
  );
};
