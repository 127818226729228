import { getCarrierOverrides, getCarrierOverrideById, createCarrierOverride, updateCarrierOverride, getCarrierConfigurations, deleteCarrierOverride } from "../api/CarrierOverrideApi";
import { mapCarrierOverrideToICarrierOverrideApi, mapICarrierOverrideApiToCarrierOverride, mapICarrierApiToCarrier, carrierOverrideStatus } from "../mappers/CarrierOverrideMapper";
import { IAlertModel } from "../models/CarrierOverrideApiModels";
import { CarrierOverride } from "../models/CarrierOverrideModels";
import { carrierOverrideSlice } from "./CarrierOverrideSlice";
import moment from "moment";

const { actions } = carrierOverrideSlice;
const errorTitle = "Error while calling API";

export const getCarrierOverridesAction = (plantCode: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const carrierOverrides = await getCarrierOverrides(plantCode);
    const carrierOverridesMapped = carrierOverrides.map((carrierOverride) => mapICarrierOverrideApiToCarrierOverride(carrierOverride));
    dispatch(actions.carrierOverrides({ carrierOverridesMapped }));
  } catch (err) {
    const error = {
      title: errorTitle,
      message: "Can't find any carrier overrides",
      variant: "danger",
    } as IAlertModel;
    console.error(err);
    dispatch(actions.catchError({ error }));
  }
};

export const createCarrierOverrideAction = (carrierOverride: CarrierOverride) => async (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall());
  const carrierOverrideRequest =  mapCarrierOverrideToICarrierOverrideApi(carrierOverride);
  await createCarrierOverride(carrierOverrideRequest)
    .then((response) => {
      const alert = {        
        message: "Carrier override has been created",
        variant: "success",
      } as IAlertModel;      
      carrierOverride.id = response.data.ruleId;
      carrierOverride.status = carrierOverrideStatus(moment(carrierOverride.validFrom).format(), moment(carrierOverride.validTo).format(), undefined)
      dispatch(actions.carrierOverrideCreated({ carrierOverride, alert }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: err.response?.data?.detail ? err.response.data.detail : "Can't create carrier override",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const updateCarrierOverrideAction = (carrierOverride: CarrierOverride) => async (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall());
  const carrierOverrideRequest =  mapCarrierOverrideToICarrierOverrideApi(carrierOverride);
  await updateCarrierOverride(carrierOverrideRequest)
    .then(async () => {
      const updatedCarrierOverrideRule = await getCarrierOverrideById(carrierOverride.plantCode, carrierOverride.id);

      const alert = {        
        message: "Carrier override has been updated",
        variant: "success",
      } as IAlertModel;      
      dispatch(actions.carrierOverrideUpdated({updatedCarrierOverrideRule : mapICarrierOverrideApiToCarrierOverride(updatedCarrierOverrideRule), alert }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: err.response?.data?.detail ? err.response.data.detail : "Can't update carrier override",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const deleteCarrierOverridesAction = (plantCode: string, ruleId: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());  
  await deleteCarrierOverride(plantCode, ruleId)
    .then(() => {
      const alert = {        
        message: "Carrier override has been deleted",
        variant: "success",
      } as IAlertModel;            
      dispatch(actions.carrierOverrideDeleted({ ruleId, alert }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: err.response?.data?.detail ? err.response.data.detail : "Can't delete specified carrier override",
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const selectCarrierOverride = (carrierOverride: CarrierOverride) => (
  dispatch: (args: { payload: any; type: string }) => void
) => {
  dispatch(actions.carrierOverrideSelected({ carrierOverride }));
};

export const unselectCarrierOverride = () => (
  dispatch: (args: { payload: any; type: string }) => void
) => {
  dispatch(actions.carrierOverrideUnselected());
};

export const getCarriersAction = (plantCode: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const carriers = await getCarrierConfigurations(plantCode);
    const carriersMapped = carriers.map((carrier) => mapICarrierApiToCarrier(carrier));
    dispatch(actions.carriers({ carriersMapped }));
  } catch (err) {
    const error = {
      title: errorTitle,
      message: "Can't find any carriers",
      variant: "danger",
    } as IAlertModel;
    console.error(err);
    dispatch(actions.catchError({ error }));
  }
};