import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { getMsalConfiguration } from "./msalConfig";

const generateMsalInstance = async () => {
  const msalConfig = await getMsalConfiguration();
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account_1 = event.payload.account;
      msalInstance.setActiveAccount(account_1);
    }
  });

  return msalInstance;
};

export {
  generateMsalInstance
};
