import React, { SVGProps } from "react";

function TubeIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="45"
      viewBox="0 0 24 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.56305 41.81V35.67H2.82305C2.71305 35.67 2.63305 35.58 2.63305 35.48V35.19C2.63305 35.09 2.71305 35 2.82305 35H7.06305C7.17305 35 7.25305 35.09 7.25305 35.19V35.48C7.25305 35.58 7.17305 35.67 7.06305 35.67H5.32305V41.81C5.32305 41.91 5.23305 42 5.13305 42H4.75305C4.65305 42 4.56305 41.91 4.56305 41.81ZM7.38039 40.09V37.6C7.38039 37.49 7.47039 37.38 7.60039 37.38H7.90039C8.02039 37.38 8.12039 37.49 8.12039 37.6V40.1C8.12039 41.02 8.42039 41.44 9.09039 41.44C9.78039 41.44 10.1904 41.01 10.4004 40.77V37.6C10.4004 37.49 10.4904 37.38 10.6104 37.38H10.9204C11.0304 37.38 11.1304 37.49 11.1304 37.6V41.78C11.1304 41.9 11.0304 42 10.9204 42H10.7604C10.6304 42 10.5804 41.92 10.5504 41.8L10.4604 41.51C10.2904 41.69 9.86039 42.11 9.03039 42.11C7.70039 42.11 7.38039 41.31 7.38039 40.09ZM12.6941 41.78V35.22C12.6941 35.11 12.7841 35 12.9041 35H13.2041C13.3141 35 13.4241 35.11 13.4241 35.22V37.57C13.4241 37.57 13.9341 37.28 14.7041 37.28C16.0841 37.28 16.8841 38.33 16.8841 39.68C16.8841 41 15.9941 42.11 14.8141 42.11C13.8741 42.11 13.3541 41.51 13.3541 41.51L13.2941 41.81C13.2741 41.92 13.2041 42 13.0941 42H12.9141C12.7941 42 12.6941 41.9 12.6941 41.78ZM13.4241 40.68C13.4241 40.68 13.9041 41.44 14.7341 41.44C15.5741 41.44 16.1341 40.59 16.1341 39.69C16.1341 38.77 15.6041 37.95 14.5941 37.95C13.9541 37.95 13.4241 38.24 13.4241 38.24V40.68ZM17.863 39.69C17.863 38.39 18.703 37.28 20.103 37.28C21.313 37.28 22.193 38.17 22.193 39.38C22.193 39.46 22.183 39.58 22.173 39.66C22.163 39.78 22.063 39.86 21.963 39.86H18.603C18.623 40.63 19.273 41.44 20.113 41.44C20.723 41.44 21.103 41.23 21.363 41.06C21.523 40.95 21.593 40.91 21.713 41.09L21.833 41.26C21.923 41.4 21.963 41.45 21.793 41.58C21.513 41.8 20.903 42.11 20.103 42.11C18.703 42.11 17.863 41.01 17.863 39.69ZM18.613 39.27H21.453C21.433 38.59 20.893 37.95 20.103 37.95C19.323 37.95 18.703 38.55 18.613 39.27Z"
        fill="black"
      />
      <mask
        id="mask0_458_414"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_458_414)">
        <path
          d="M12 22C10.3222 22 8.90278 21.6125 7.74167 20.8375C6.58056 20.0625 6 20 6 18V6C6 4.9 6.58611 3.95833 7.75833 3.175C8.93056 2.39167 10.3444 2 12 2C13.6556 2 15.0694 2.39167 16.2417 3.175C17.4139 3.95833 18 4.9 18 6V18C18 20 17.4194 20.0625 16.2583 20.8375C15.0972 21.6125 13.6778 22 12 22ZM12 8.025C12.9889 8.025 13.9833 7.8125 14.9833 7.3875C16 7 16.2417 6.025 16.2417 6.025C16.2417 6.025 15.9861 5.08333 14.9917 4.65C13.9972 4.21667 13 4 12 4C10.9889 4 9.99722 4.2125 9.025 4.6375C8.05278 5.0625 7.75833 6.025 7.75833 6.025C7.75833 6.025 8.05278 6.98333 9.025 7.4C9.99722 7.81667 10.9889 8.025 12 8.025ZM12 20C12.5111 20 13.0306 19.9417 13.5583 19.825C14.0861 19.7083 14.5722 19.5542 15.0167 19.3625C15.4611 19.1708 15.7 18.9542 16 18.7125V18V9C15.7111 9.23333 16.0583 8.96667 15.7083 9.15C15.3583 9.33333 14.9861 9.4875 14.5917 9.6125C14.1972 9.7375 13.7833 9.83333 13.35 9.9C12.9167 9.96667 12.4667 10 12 10C11.5333 10 11.0778 9.96667 10.6333 9.9C10.1889 9.83333 9.76944 9.7375 9.375 9.6125C8.98056 9.4875 8.61111 9.33333 8.26667 9.15C7.92222 8.96667 8.27778 9.23333 8 9V18C8 18.5 8 18.5 8 18.7125C8.29444 18.9458 8.52222 19.1708 8.96667 19.3625C9.41111 19.5542 9.9 19.7083 10.4333 19.825C10.9667 19.9417 11.4889 20 12 20Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default TubeIcon;
