import React, { useState } from 'react';
import { Card, CardBody } from '../../../../_metronic/_partials/controls';
import { Alert } from 'react-bootstrap';
import { IAlertModel } from '../../../models/LeadTimes';

type Props = {
  alert?: IAlertModel | null;
  cardProps?: any;
  downloadHandler: () => Promise<void>;
  downloadLabel?: string;
  showImportModalHandler: () => void;
  uploadLabel?: string;
  showFilter?: boolean;
  handleFilter?: (query: string) => void;
};

const LeadTimesExportImportCard: React.FC<Props> = ({
  alert,
  cardProps,
  downloadHandler,
  downloadLabel,
  showImportModalHandler,
  uploadLabel,
  showFilter,
  handleFilter
}) => {

  const [filterQuery, setFilterQuery] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(event.target.value);    
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && handleFilter) {
      handleFilter(filterQuery);
    }
  };

  const handleButtonClick = () => {
    if (handleFilter) {
      handleFilter(filterQuery);
    }
  };

  return (
    <Card {...cardProps} className='card bg-light mb-3 mx-10'>
      {/* TODO: After testing add 'd-none' class to card component above to hide the buttons */}
      <CardBody {...cardProps} className='px-10'>
        {alert && alert?.message && (
          <Alert variant={alert.variant}>
            <Alert.Heading>{alert.title}</Alert.Heading>
            <p>{alert.message}</p>
          </Alert>
        )}
        <div className='row'>
          <div className='col-lg-8 col-md-6'>
            <button
              type='submit'
              onClick={downloadHandler}
              className='btn btn-primary-theme border-3 fw-bold mr-5 mb-sm-5 mb-md-0'
            >
              {downloadLabel ?? 'Download'}
            </button>
            <button
              type='submit'
              onClick={showImportModalHandler}
              className='btn btn-outline-theme border-3 fw-bold mr-5 mb-sm-5 mb-md-0'
            >
              {uploadLabel ?? 'Upload'}
            </button>
          </div>
          {showFilter && (
            <div className='col-lg-4 col-md-6'>
              <div className="input-group search-input-group">
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search pap or description'
                  value={filterQuery}
                  onChange={handleFilterChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="input-group-append">
                  <button
                    className="input-group-text"
                    type="button"
                    onClick={handleButtonClick}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <i className="fa fa-search"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export { LeadTimesExportImportCard };
