import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../_metronic/_partials/controls";
import { useState } from "react";
import { setProductModifier } from "../../api/ModifiersApi";
import { getCatalogOptionKeysByProductType, getCatalogOptionValues, getCatalogOptionConstraints } from "../../api/ProductCatalogApi";
import { Checkbox } from "../../../components/Checkbox";
import * as Yup from "yup";
import { IProductCatalogLookupDto } from "../../models/ProductCatalog";


const validationSchema = Yup.object({
  name: Yup.string().required("Please select a product"),
  sequence: Yup.number().required("Sequence is required")
  });

let errorMessage: string;

const weightModifierOptionMap = {
  PagesWeightForPrints: "PaperWeightGramsPerSquareMeter",
  PagesWeight: "PaperWeightGramsPerSquareMeter",
  WeightForRectangularArea: "WeightInGramsPerSquareMeter",
  WeightForRectangularCircumference: "WeightInGramsPerMeter",
  Add: "Value",
  AddPerPage: "Value",
  Override: "Value",
  None: "Value",
};

const heightModifierOptionMap = {
  BookHeight: "DefaultSpineWidth",
  Add: "Value",
  AddPerPage: "Value",
  Override: "Value",
  None: "Value",
};

type WeightModifierType = keyof typeof weightModifierOptionMap;
type HeightModifierType = keyof typeof heightModifierOptionMap;

const ModifierAddForm = ({
  onHide,
  onSuccess,
  plant,
  productGroup,
  productType,
  editModifierData,
}: any) => {
  let name = editModifierData?.name || "";
  let suffix = "";
  const rangeOptions = ["Pages"];
  if (editModifierData?.modifier?.modifierType === "ProductOptionBased") {
    const splitName = editModifierData?.name.split("-");
    const option = splitName[0];
    const value = splitName[1];
    if (rangeOptions.includes(option) && (value === undefined || value.indexOf("~") === -1)) {
      name = option;
      suffix = value || "";
    } else {
      name = `${option}-${value}`;
      suffix = splitName[2];
    }
  }

  const initialValues = {
    name: name || "",
    suffix: suffix,
    sequence: editModifierData?.modifier?.sequence || 1,
    modifierType: editModifierData?.modifier?.modifierType,
    heightModifierValue: editModifierData?.height,
    lengthModifierValue: editModifierData?.length,
    breadthModifierValue: editModifierData?.width,
    weightModifierValue: editModifierData?.weight,
    weightModifierType:
      editModifierData?.modifier?.weightModifier?.modifierType,
    breadthModifierType:
      editModifierData?.modifier?.breadthModifier?.modifierType,
    heightModifierType:
      editModifierData?.modifier?.heightModifier?.modifierType,
    lengthModifierType:
      editModifierData?.modifier?.lengthModifier?.modifierType,
    shapeModifierType: editModifierData?.modifier?.shapeModifier,
  };

  const [loaded, setLoaded] = useState(false);

  const [selectedProductOption, setSelectedProductOption] = useState<{ key: string, description: string } | null>(null);
  const [selectedProductOptionValue, setSelectedProductOptionValue] = useState<{ key: string, description: string } | null>(null);

  const [selectedModifierType, setSelectedModifierType] = useState(
    initialValues.modifierType || "Default"
  );
  const [heightModifierValue, setHeightModifierValue] = useState(
    initialValues.heightModifierValue || 0
  );
  const [selectedHeightModifierType, setSelectedHeightModifierType] =
    useState<HeightModifierType>(
      (initialValues.heightModifierType || "None") as HeightModifierType
    );
  const [breadthModifierValue, setBreadthModifierValue] = useState(
    initialValues.breadthModifierValue || 0
  );
  const [selectedBreadthModifierType, setSelectedBreadthModifierType] =
    useState(initialValues.breadthModifierType || "None");

  const [lengthModifierValue, setLengthModifierValue] = useState(
    initialValues.lengthModifierValue || 0
  );
  const [selectedLengthModifierType, setSelectedLengthModifierType] = useState(
    initialValues.lengthModifierType || "None"
  );
  const [weightModifierValue, setWeightModifierValue] = useState(
    initialValues.weightModifierValue || 0
  );
  const [selectedWeightModifierType, setSelectedWeightModifierType] =
    useState<WeightModifierType>(
      (initialValues.weightModifierType || "None") as WeightModifierType
    );

  const [selectedShapeModifier, setSelectedShapeModifier] = useState(
    initialValues.shapeModifierType || null
  );

  const [productOptions, setProductOptions] = useState<{ key: string, description: string }[]>([]);

  const [productOptionValues, setProductOptionValues] = useState<{ key: string, description: string }[]>([]);

  const [productOptionConstraints, setProductOptionConstraints] = useState<{ constraintType: string, constraintValue?: string }[]>([]);

  const [productOptionSpecifyRange, setProductOptionSpecifyRange] = useState(true);
  const [productOptionFrom, setProductOptionFrom] = useState("");
  const [productOptionTo, setProductOptionTo] = useState("");

  const loadProductOptions = (): Promise<any[]> => {
    const promise = getCatalogOptionKeysByProductType(productType);
    promise.then(options => {
      setProductOptions(options);
      updateModifierArrays(options);
    });
    return promise;
  };

  const loadProductOptionValues = (option: string): Promise<any[]> => {
    const promise = getCatalogOptionValues(productType, option);
    promise.then(values => {
      // Remove duplicates and empty value
      values = values.filter((item, index) => item.key != '' && values.findIndex(item2 => item.key == item2.key) === index);
      values.forEach(v => v.description = v.description || v.key);
      const anyValue: IProductCatalogLookupDto = { id: null,  key: "*", description: "*" };
      values.push(anyValue);
      setProductOptionValues(values);
    });
    return promise;
  };

  const loadProductOptionConstraints = (option: string): Promise<any[]> => {
    const promise = getCatalogOptionConstraints(productType, option);
    promise.then(constraints => {
      setProductOptionConstraints(constraints.map(c => ({ constraintType: c.key, constraintValue: c.description })));
      setProductOptionFrom("");
      setProductOptionTo("");
    });
    return promise;
  };

  const modifierTypeChanged = (modifierType: string) => {
    setSelectedModifierType(modifierType);
    loadProductOptions();
  };

  const onOptionChanged = (event: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const option = productOptions.find(po => po.key === event.target.value);
    if (option) {
      setSelectedProductOption(option);
      setFieldValue("name", `${option.key}-...`);
      loadProductOptionValues(option.key);
      loadProductOptionConstraints(option.key);
    }
  };

  const onOptionValueChanged = (event: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const value = productOptionValues.find(ov => ov.key === event.target.value);
    if (value) {
      setSelectedProductOptionValue(value);
      setFieldValue("name", `${selectedProductOption?.key}-${value?.key}`);
    }
  };

  const onOptionFromChanged = (event: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const from = event.target.value;
    setProductOptionFrom(from);
    setFieldValue("name", `${selectedProductOption?.key}-${from}~${productOptionTo}`);
  };

  const onOptionToChanged = (event: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const to = event.target.value;
    setProductOptionTo(to);
    setFieldValue("name", `${selectedProductOption?.key}-${productOptionFrom}~${to}`);
  };

  const productOptionSpecifyRangeChanged = (event: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    setProductOptionSpecifyRange(event.target.checked);
    if (event.target.checked) {
      setFieldValue("name", `${selectedProductOption?.key}-${productOptionFrom}~${productOptionTo}`);
    } else {
      setFieldValue("name", `${selectedProductOption?.key}`);
    }
  };

  if (!loaded) {
    loadProductOptions().then(options => {
      if (editModifierData?.modifier?.modifierType === "ProductOptionBased") {
        const splitName = editModifierData?.name.split("-");
        const option = splitName[0];
        const value = splitName[1];
        const selectedOption = options.find(op => op.key.toLowerCase() === option.toLowerCase());
        if (selectedOption) {
          setSelectedProductOption(selectedOption);
          loadProductOptionConstraints(selectedOption.key)
            .then(constraints => {
              if (constraints.some(c => c.key === "Number")) {
                if (value.indexOf("~") > -1) {
                  const range = value.split("~");
                  setProductOptionSpecifyRange(true);
                  setProductOptionFrom(range[0]);
                  setProductOptionTo(range[1]);
                } else {
                  setProductOptionSpecifyRange(false);
                  setProductOptionFrom("");
                  setProductOptionTo("");
                }
              }
            });
          loadProductOptionValues(selectedOption.key)
            .then(values => { 
              setSelectedProductOptionValue(values.find(v => v.key.toLowerCase() === value.toLowerCase()));
            });
        }
      }
    });
    setLoaded(true);
  }

  let heightModifiers: { key: string, description: string, info: string }[] = [];

  let weightModifiers: { key: string, description: string, info: string }[] = [];

  let breadthModifiers: { key: string, description: string, info: string }[] = [];

  let lengthModifiers: { key: string, description: string, info: string }[] = [];

  const updateModifierArrays = (options: { key: string, description: string }[]) => {
    let basicModifierNames: { key: string, description: string, info: string }[] = [
      { key: "Override", description: "Override", info: "" },
      { key: "Add", description: "Add", info: "" },
    ];
  
    if (options.some(x => rangeOptions.includes(x.key))) {
      basicModifierNames = basicModifierNames.concat([
        { key: "AddPerPage", description: "Add per Page", info: "Adds a value calculated by multiplying the number of pages by the specified value." },
      ]);
    }
  
    heightModifiers = basicModifierNames.concat([
      { key: "BookHeight", description: "Book Height", info: "" },
    ]);
  
    weightModifiers = basicModifierNames.concat([
      { key: "PagesWeightForPrints", description: "Pages Weight For Prints per square meter", info: "Adds to weight a value calculated specially for Prints by multiplying the number of prints by the area in square meters by the specified value in grams. It takes one TopSheet into account." },
      { key: "PagesWeight", description: "Pages Weight per square meter", info: "Adds to weight a value calculated by multiplying the number of sheets by the area of the sheet in square meters by the specified value in grams." },
      { key: "WeightForRectangularArea", description: "WeightForRectangularArea", info: "Adds to weight a value calculated by multiplying the area of the page/product in square meters by the specified value in grams." },
      { key: "WeightForRectangularCircumference", description: "WeightForRectangularCircumference", info: "Adds to weight a value calculated by multiplying the circumference of the page/product in meters by the specified value in grams." },
    ]);
  
    breadthModifiers = basicModifierNames.concat([]);
  
    lengthModifiers = basicModifierNames.concat([]);
  };

  updateModifierArrays(productOptions);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}  
        onSubmit={async (values, { setStatus }) => {
          try {
            if (productOptionConstraints.some(c => c.constraintType === "Number")) {
              if (productOptionSpecifyRange) {
                if (productOptionFrom === "" || productOptionTo === "") {
                  alert("Please, specify range `From` and `To`.");
                  setStatus("error");
                  return;
                }
                if (productOptionFrom !== "" && productOptionTo !== "") {
                  const from = parseInt(productOptionFrom);
                  const to = parseInt(productOptionTo);
                  if (from > to) {
                    alert("Range `From` must be less than or equal `To`.");
                    setStatus("error");
                    return;
                  }
                }
              }
            }
            const name = values.name + (selectedModifierType === "ProductOptionBased" && values.suffix && values.suffix !== "" ? "-" + values.suffix : "");
            await setProductModifier(plant, productGroup, {
              productModifiers: [
                {
                  name: name,
                  modifierType: selectedModifierType,
                  sequence: values.sequence,
                  shapeModifier: selectedShapeModifier,
                  heightModifier: {
                    modifierType: selectedHeightModifierType,
                    values: {
                      [heightModifierOptionMap[selectedHeightModifierType]]:
                        heightModifierValue,
                    },
                  },
                  weightModifier: {
                    modifierType: selectedWeightModifierType,
                    values: {
                      [weightModifierOptionMap[selectedWeightModifierType]]:
                        weightModifierValue,
                    },
                  },
                  breadthModifier: {
                    modifierType: selectedBreadthModifierType,
                    values: {
                      Value: breadthModifierValue,
                    },
                  },
                  lengthModifier: {
                    modifierType: selectedLengthModifierType,
                    values: {
                      Value: lengthModifierValue,
                    },
                  },
                },
              ],
            });
            setStatus("success");
            setTimeout(() => {
              onHide(); // Close the modal after a successful submission
              onSuccess();
            }, 1500);
          } catch (error: any) {
            const errors = error?.response?.data?.errors;
            if (errors){
              errorMessage = JSON.stringify(errors);
            }
            setStatus("error");
          }
        }}
      >
        {({ handleSubmit, handleChange, status, values, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Modal.Title></Modal.Title>
              <Form className="form form-label-right">
                <div className="form-group">
                  <label className="h6 font-weight-bold">
                    Select Modifier Type
                  </label>
                  <div className="form-check">
                    <label className="form-check-label">
                      <Field
                        type="radio"
                        name="modifierType"
                        value="Default"
                        checked={selectedModifierType === "Default"}
                        onChange={() => modifierTypeChanged("Default")}
                        className="form-check-input"
                      />
                      Default
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label">
                      <Field
                        type="radio"
                        name="modifierType"
                        value="ProductOptionBased"
                        checked={selectedModifierType === "ProductOptionBased"}
                        onChange={() =>
                          modifierTypeChanged("ProductOptionBased")
                        }
                        className="form-check-input"
                      />
                      Product Option Based
                    </label>
                  </div>
                  <div className="row">
                      {selectedModifierType === "Default" &&
                      <div className="col-md-12 mt-2">
                        <label className="h6 font-weight-bold">
                          Modifier Key
                        </label>
                        <Field
                          name="name"
                          component={Input}
                          placeholder="e.g. glossy"
                          label="Modifier Key"
                        />
                      </div>
                      }
                      {selectedModifierType === "ProductOptionBased" &&
                      <>
                        {productType === undefined && (
                          <div className="col-md-12">
                            <div className="text-danger">
                                <>No options found. Please, specify a Product Type for Product Group '{productGroup}' from Products page.</>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12 mt-2 mb-5">
                          <label className="h6 font-weight-bold">
                            Option
                          </label>
                          <Field as="select" 
                                  placeholder="Canvas, Mug"
                                  label="Product Option"
                                  value={selectedProductOption?.key}
                                  onChange={(event: any) => onOptionChanged(event, setFieldValue)}
                                  className="form-control"
                                  >
                            <option>(select option)</option>
                          {productOptions.map((dto: {key: string, description: string}) => (
                            <option value={dto.key}>{dto.description}</option>
                          )
                          )}
                          </Field>
                          {productOptionConstraints?.some(c => c.constraintType === "Number")
                          ? (
                            <div className="mt-3">
                            <Field
                              name="specifyRange"
                              type="checkbox"
                              component={Checkbox}
                              onChange={(event: any) => productOptionSpecifyRangeChanged(event, setFieldValue)}
                              isSelected={productOptionSpecifyRange}
                              showFeedbackLabel={productOptionSpecifyRange}
                              feedbackLabel="Specify Range"
                            >
                              <label className="h6 font-weight-bold mr-2 mt-2">
                                Range
                              </label>
                            </Field>
                            {productOptionSpecifyRange && 
                            <>
                            <div className="col-md-6">
                              <Field
                                name="rangeFrom"
                                value={productOptionFrom}
                                component={Input}
                                placeholder="50"
                                label="from"
                                type="number"
                                required={true}
                                onChange={(event: any) => onOptionFromChanged(event, setFieldValue)}
                                withFeedbackLabel={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name="rangeTo"
                                value={productOptionTo}
                                component={Input}
                                placeholder="100"
                                label="to"
                                type="number"
                                required={true}
                                onChange={(event: any) => onOptionToChanged(event, setFieldValue)}
                                withFeedbackLabel={true}
                              />
                            </div>
                            </>}
                            </div>
                          ) : (
                            <>
                          <label className="h6 font-weight-bold">
                            Value
                          </label>
                          <Field as="select" 
                                  placeholder="Canvas, Mug"
                                  label="Product Option Value"
                                  value={selectedProductOptionValue?.key}
                                  onChange={(event: any) => onOptionValueChanged(event, setFieldValue)}
                                  className="form-control"
                                  >
                            <option>(select option value)</option>
                          {productOptionValues.map((dto: {key: string, description: string}) => (
                            <option value={dto.key}>{dto.description}</option>
                          )
                          )}
                          </Field>
                          </>)}
                        </div>
                        <div className="col-md-12 mt-2">
                          <label className="h6 font-weight-bold">
                            Modifier Key
                          </label>
                        </div>
                        <div className="col-md-6 mt-2">
                          <Field
                            name="name"
                            component={Input}
                            placeholder="paper-glossy"
                            label="Modifier Key"
                            title={values.name}
                            disabled={true}
                            withFeedbackLabel={false}
                          />
                        </div>
                        <span className="mt-4 h4">-</span>
                        <div className="col-md-4 mt-2">
                          <Field
                            name="suffix"
                            component={Input}
                            placeholder="suffix"
                            label="Suffix"
                            customFeedbackLabel="Optional"
                          />
                        </div>
                      </>
                      }
                    <div className="col-md-12 mt-2">
                      <label className="h6 font-weight-bold">
                        Sequence
                      </label>
                      <Field
                        name="sequence"
                        component={Input}
                        type="number"
                        placeholder="1"
                        label="Sequence"
                      />
                    </div>
                  </div>
                </div>
                {/* Height Modifier */}
                <label className="mt-10 h6 font-weight-bold">
                  Height Modifier
                </label>
                <select
                  name="heightModifierType"
                  value={selectedHeightModifierType}
                  onChange={(e) =>
                    setSelectedHeightModifierType(
                      e.target.value as HeightModifierType
                    )
                  }
                  className="form-control"
                >
                  <option value="None">None</option>
                  {heightModifiers.map(m => (<option value={m.key} title={m.info}>{m.description}</option>))}
                </select>
                <div className="mt-5">
                  <Field
                    name="heightModifierValue"
                    component={Input}
                    type="number"
                    placeholder="e.g. 2"
                    label="Height Modifier Value"
                    value={heightModifierValue}
                    onChange={(e: any) =>
                      setHeightModifierValue(e.target.value)
                    }
                    disabled={selectedHeightModifierType === "None"}
                  />
                </div>

                {/* Weight Modifier */}
                <label className="mt-10 h6 font-weight-bold">
                  Weight Modifier
                </label>
                <select
                  name="weightModifierType"
                  value={selectedWeightModifierType}
                  onChange={(e) =>
                    setSelectedWeightModifierType(
                      e.target.value as WeightModifierType
                    )
                  }
                  className="form-control"
                >
                  <option value="None">None</option>
                  {weightModifiers.map(m => (<option value={m.key} title={m.info}>{m.description}</option>))}
                </select>
                <div className="mt-5">
                  <Field
                    name="weightModifierValue"
                    component={Input}
                    type="number"
                    placeholder="e.g. 3"
                    label="Weight Modifier Value"
                    value={weightModifierValue}
                    onChange={(e: any) =>
                      setWeightModifierValue(e.target.value)
                    }
                    disabled={selectedWeightModifierType === "None"}
                  />
                </div>

                {/* Breadth Modifier */}
                <label className="mt-10 h6 font-weight-bold">
                  Breadth Modifier
                </label>
                <select
                  name="breadthModifierType"
                  value={selectedBreadthModifierType}
                  onChange={(e) =>
                    setSelectedBreadthModifierType(e.target.value)
                  }
                  className="form-control"
                >
                  <option value="None">None</option>
                  {breadthModifiers.map(m => (<option value={m.key} title={m.info}>{m.description}</option>))}
                </select>
                <div className="mt-5">
                  <Field
                    name="breadthModifierValue"
                    component={Input}
                    type="number"
                    placeholder="e.g. 3"
                    label="Breadth Modifier Value"
                    value={breadthModifierValue}
                    onChange={(e: any) =>
                      setBreadthModifierValue(e.target.value)
                    }
                    disabled={selectedBreadthModifierType === "None"}
                  />
                </div>

                {/* Length Modifier */}
                <label className="mt-10 h6 font-weight-bold">
                  Length Modifier
                </label>
                <select
                  name="lengthModifierType"
                  value={selectedLengthModifierType}
                  onChange={(e) =>
                    setSelectedLengthModifierType(e.target.value)
                  }
                  className="form-control"
                >
                  <option value="None">None</option>
                  {lengthModifiers.map(m => (<option value={m.key} title={m.info}>{m.description}</option>))}
                </select>
                <div className="mt-5">
                  <Field
                    name="lengthModifierValue"
                    component={Input}
                    type="number"
                    placeholder="e.g. 3"
                    label="Length Modifier Value"
                    value={lengthModifierValue}
                    onChange={(e: any) =>
                      setLengthModifierValue(e.target.value)
                    }
                    disabled={selectedLengthModifierType === "None"}
                  />
                </div>
                {/* Shape Modifier */}
                <label className="mt-10 h6 font-weight-bold">
                  Select Shape Modifier
                </label>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      name="shapeModifier"
                      value=""
                      checked={selectedShapeModifier === null}
                      onChange={() => setSelectedShapeModifier(null)}
                      className="form-check-input"
                    />
                    Not Set
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      name="shapeModifier"
                      value="Cuboid"
                      checked={selectedShapeModifier === "Cuboid"}
                      onChange={() => setSelectedShapeModifier("Cuboid")}
                      className="form-check-input"
                    />
                    Cuboid
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      name="shapeModifier"
                      value="Roll"
                      checked={selectedShapeModifier === "Roll"}
                      onChange={() => setSelectedShapeModifier("Roll")}
                      className="form-check-input"
                    />
                    Roll
                  </label>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            {status === "success" && (
              <div className="text-success text-center mt-3 p-10">
                Saved successfully!
              </div>
            )}
            {status === "error" && (
              <div className="text-danger text-center mt-3 p-10">
                An error occurred. {errorMessage}
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default ModifierAddForm;
