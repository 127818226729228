import { createSlice } from "@reduxjs/toolkit";
import { IAlertModel } from "../models/SkuMappingsModels";

type SkuMappingsState = { 
  showUpload: boolean,
  alert?: IAlertModel,
  skuMappings: any,
  uploadResult: any,
  apiCallInProgress: boolean
}

const initialSkuMappingssState : SkuMappingsState = {
  showUpload: false,
  alert: undefined,
  skuMappings: undefined,
  uploadResult: undefined,
  apiCallInProgress: false
};

export const skuMappingsSlice = createSlice({
  name: "skuMappings",
  initialState: initialSkuMappingssState,
  reducers: {
    startCall: (state) => {
      state.alert = undefined;
      state.showUpload = false;
      state.skuMappings = undefined;
      state.uploadResult = undefined;
      state.apiCallInProgress = true;
    },
    catchError: (state, action) => {
      const { error } = action.payload;
      state.showUpload = false;
      state.alert = error;
      state.skuMappings = undefined;
      state.apiCallInProgress = false;
    },
    alertDismissed: (state) => {
      state.alert = undefined;
    },
    skuMappingsFetched: (state, action) => {
      const { skuMappings, successAlert } = action.payload;      
      state.alert = successAlert;
      state.skuMappings = skuMappings;
      state.showUpload = false;
      state.apiCallInProgress = false;
    },
    skuMappingsUploaded: (state, action) => {      
      const { uploadResult, successAlert } = action.payload;   
      state.alert = successAlert;
      state.skuMappings = undefined;
      state.showUpload = false;
      state.uploadResult = uploadResult;
      state.apiCallInProgress = false;
    }
  },
});
