import React, { useMemo, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../_metronic/_partials/controls';
import { IAlertModel } from '../models/LeadTimes';
import ProductsGrouping from './products-grouping/ProductsGrouping';
import ProductsTable from './products-table/ProductsTable';
import ProductsTableWithSchedules from './products-table/ProductsTableWithSchedules';
import { LeadTimesExportImportCard } from './components/leadtimes-export-import-card/LeadTimesExportImportCard';

import { useProductsContext } from './ProductsContext';
import { isScheduledLeadTimesEnabled } from '../utils/IsAvailableOnFeatureFlag';

type Props = {
  cardProps?: any;
  downloadLeadTimesHandler: () => Promise<void>;
  showImportModalHandler: () => void;
  leadTimesAlert?: IAlertModel | null; 
  hasLeadTimesUpdated? : boolean;
};

const ProductsCard = (props: Props) => {
  const productsContext = useProductsContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsContext.ids,
    };
  }, [productsContext]);
  const downloadLabel = `Download ${productsContext.productGroupName} Lead times`;
  const importLabel = 'Import Product Lead times';

  const [filterQuery, setFilterQuery] = useState('');

  const handleFilter = (query: string) => {
    setFilterQuery(query);
  };

  return (
    <Card {...props.cardProps}>
      <CardHeader
        className='border-0'
        title={`${productsContext.productGroupName} Lead Times`}
        {...props.cardProps}
      >
        <CardHeaderToolbar {...props.cardProps}></CardHeaderToolbar>
      </CardHeader>
      <LeadTimesExportImportCard
        alert={props.leadTimesAlert}
        downloadHandler={props.downloadLeadTimesHandler}
        downloadLabel={downloadLabel}
        showImportModalHandler={props.showImportModalHandler}
        uploadLabel={importLabel}
        showFilter={isScheduledLeadTimesEnabled}
        handleFilter={handleFilter}
      />
      <CardBody {...props.cardProps}>
        {productsUIProps.ids.length > 0 && <ProductsGrouping />}
        {isScheduledLeadTimesEnabled ?
          <ProductsTableWithSchedules hasLeadTimesUpdated={props.hasLeadTimesUpdated} filterQuery={filterQuery} /> :
          <ProductsTable hasLeadTimesUpdated={props.hasLeadTimesUpdated} />
        }
      </CardBody>
    </Card>
  );
};

export default ProductsCard;
