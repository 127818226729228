export type CarrierOverride = {
  id?: string,
  plantCode: string,
  country: string,
  promisedDateFrom?: string,
  promisedDateTo?: string,
  validFrom: string,
  validTo: string,
  carrierName: string,
  overrideCarrierName: string,
  description: string,
  status?: CarrierOverrideStatus,
  enabled: boolean,
  zipCodeRangesString: string,
  zipCodeRanges: ZipCodeRange[],
  marketingPromised: boolean
};

export enum CarrierOverrideStatus {
  Active = 1,
  Inactive = 2,
  Expired = 3,
  Disabled = 4
}

export interface Carrier {  
  carrierName: string;
}

export type ZipCodeRange = {
  zipCodeStart: string,
  zipCodeEnd: string
}