import { Field, getIn, useFormikContext } from "formik"
import { activityRuleTypes, IRoutingRule } from "../../models/RoutingRuleSetModel"
import { Input } from "../../../../_metronic/_partials/controls/forms/Input"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import HelpIcon from "../../../svg/HelpIcon"
import { formatLocalDateTime, localDateTimeToIso } from "../../utils/DateTimeHelpers"

const getDateRangeName = (rule: IRoutingRule) => {
    //Multiple ways of determining activity exist in the activityRules, get the one that contains the date range
    const index = rule.activityRules?.findIndex(x => x.activityRuleType === activityRuleTypes.activeBetweenDatesRule)
    return `activityRules[${index}]`
}

const RoutingRuleDateRangeEditBlock = () => {
    const { setFieldTouched, setFieldValue, values } = useFormikContext()
    const rule = values as IRoutingRule

    return (
        <div className="border border-secondary mt-6">
            <div className="d-flex">
                <p className="routing-rule-text-smallcaps ml-4 mt-4">Time/Date range - in local time zone</p>
                <OverlayTrigger
                    placement='right'
                    overlay={
                        <Tooltip id='local-time-explanation'>
                            <div className="text-left">
                                Dates and times are displayed in the timezone configured for the user's computer. The backend stores them in Universal Time to ensure consistent behavior, but upon display they are converted to the users computer configuration.
                            </div>
                        </Tooltip>
                    }
                >
                    <div>
                        <HelpIcon customStyle="pl-2 pt-2" />
                    </div>
                </OverlayTrigger>
            </div>
            <div className="row border-top border-secondary pt-6 mx-0">
                <label className="col-auto mt-3 lighter">From</label>
                <div className="col-4 form-group">
                    <Field
                        name={`${getDateRangeName(rule)}.activeFrom`}
                        component={Input}
                        type="datetime-local"
                        value={formatLocalDateTime(getIn(values, `${getDateRangeName(rule)}.activeFrom`)) ?? ''}
                        onChange={async (changeEvent: any) => {
                            const formattedDate = localDateTimeToIso(changeEvent.target.value)
                            await setFieldValue(`${getDateRangeName(rule)}.activeFrom`, formattedDate)
                            setFieldTouched(`${getDateRangeName(rule)}.activeFrom`, true)
                        }}
                    />
                </div>
                <label className="col-auto mt-3 lighter">until</label>
                <div className="col-4 form-group">
                    <Field
                        name={`${getDateRangeName(rule)}.activeUntil`}
                        component={Input}
                        type="datetime-local"
                        value={formatLocalDateTime(getIn(values, `${getDateRangeName(rule)}.activeUntil`)) ?? ''}
                        onChange={async (changeEvent: any) => {
                            const formattedDate = localDateTimeToIso(changeEvent.target.value)
                            await setFieldValue(`${getDateRangeName(rule)}.activeUntil`, formattedDate)
                            setFieldTouched(`${getDateRangeName(rule)}.activeUntil`, true)
                        }}
                    />
                </div>
            </div>
        </div>

    )
}

export default RoutingRuleDateRangeEditBlock