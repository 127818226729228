import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import { SelectionReason, useRoutingRuleContext } from "../../RoutingRuleContext";
import { TestCase, TestContext, TestProduct } from "../../models/TestSuiteModel";
import moment from "moment";
import DeleteIconWithCopy from "../../../svg/DeleteIconWithCopy";
import EditIconWithCopy from "../../../svg/EditIconWithCopy";
import CloneIconWithCopy from "../../../svg/CloneIconWithCopy";
import { sortCaret } from "../../../../_metronic/_helpers";
import * as actions from '../../redux/RoutingRulesActions'
import { useDispatch } from "react-redux";

type Props = {
}

const deriveArticleType = (articleCode: string) => {
    return "ArticleType unknown"
}

const TestCasesTable = (props: Props) => {
    const dispatch = useDispatch();
    const routingRulesContext = useRoutingRuleContext();
    const nrApiCallsInProgress = routingRulesContext.nrApiCallsInProgress
    const testcases = routingRulesContext.testCases
    const changedTestCases = routingRulesContext.changedTestCases

    const handleEditTestCaseClick = (test: TestCase) => {
        routingRulesContext.setSelectedTestCase({ testCase: test, selectionReason: SelectionReason.Default });
        routingRulesContext.uiEvents.showTestCaseEditModal()
    }

    const handleCloneTestCaseClick = (test: TestCase) => {
        const clonedTest = {
            ...test,
            id: crypto.randomUUID(),
            testProduct: {
                ...test.testProduct,
                productOptions: {
                    ...test.testProduct.productOptions
                }
            },
            testContext: {
                ...test.testContext
            }
        }
        routingRulesContext.setSelectedTestCase({ testCase: clonedTest, selectionReason: SelectionReason.Clone });
        routingRulesContext.uiEvents.showTestCaseEditModal()
    }

    const handleDeleteTestCaseClick = (test: TestCase) => {
        dispatch(actions.markTestCaseChanged(test.id, true))
        dispatch(actions.deleteTestCase(test.id))
    }

    const setRowBackground = (test: TestCase, rowIndex: number) => {
        let classes = '';

        if (test.testResult) {
            classes = (test.testResult.result) ? 'routing-rules-test-success' : 'routing-rules-test-failed'
        } else {
            if (changedTestCases.some((changedTest) => changedTest.testCaseId === test.id)) {
                classes = 'routing-rules-changed';
            }
        }

        return classes;
    };

    const columns = [
        {
            dataField: 'testProduct.channelName',
            text: 'Channel',
            sort: true,
            sortCaret: sortCaret,
            classes: 'col-1',
        },
        {
            dataField: 'testProduct',
            text: 'Address',
            formatter: (cell: TestProduct, row: TestCase) => (
                <div className="d-flex flex-column justify-content-center">
                    {cell.receiverName && cell.receiverName !== "" && <div>{cell.receiverName}</div>}
                    {cell.destinationPostCode && cell.destinationPostCode !== "" && <div>{cell.destinationPostCode}</div>}
                    <div>{cell.destinationCountry}</div>
                </div>
            ),
            sort: true,
            sortCaret: sortCaret,
            sortValue: (cell: TestProduct, row: TestCase) => {
                const result = {
                    destinationCountry: cell.destinationCountry ?? '',
                    destinationPostCode: cell.destinationPostCode ?? '',
                    receiverName: cell.receiverName ?? ''
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.destinationCountry !== second.destinationCountry) {
                    if (order === 'desc') {
                        return second.destinationCountry.localeCompare(first.destinationCountry)
                    } else {
                        return first.destinationCountry.localeCompare(second.destinationCountry)
                    }
                }

                if (first.destinationPostCode !== second.destinationPostCode) {
                    if (order === 'desc') {
                        return second.destinationPostCode.localeCompare(first.destinationPostCode)
                    } else {
                        return first.destinationPostCode.localeCompare(second.destinationPostCode)
                    }
                }

                if (order === 'desc') {
                    return second.receiverName.localeCompare(first.receiverName)
                } else {
                    return first.receiverName.localeCompare(second.receiverName)
                }
            },
            classes: 'col-1',
        },
        {
            dataField: 'testProduct.deliveryType',
            text: 'Delivery',
            formatter: (cell: string, row: TestCase) => (
                <div className="d-flex flex-column justify-content-center">
                    {row.testProduct.deliveryMethod && row.testProduct.deliveryMethod !== "" && <div>{row.testProduct.deliveryMethod}</div>}
                    {row.testProduct.deliveryType && row.testProduct.deliveryType !== "" && <div>{row.testProduct.deliveryType}</div>}
                </div>
            ),
            sort: true,
            sortValue: (cell: string, row: TestCase) => {
                const result = {
                    deliveryMethod: row.testProduct.deliveryMethod ?? '',
                    deliveryType: row.testProduct.deliveryType ?? ''
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.deliveryMethod !== second.deliveryMethod) {
                    if (order === 'desc') {
                        return second.deliveryMethod.localeCompare(first.deliveryMethod)
                    } else {
                        return first.deliveryMethod.localeCompare(second.deliveryMethod)
                    }
                }

                if (order === 'desc') {
                    return second.deliveryType.localeCompare(first.deliveryType)
                } else {
                    return first.deliveryType.localeCompare(second.deliveryType)
                }
            },
            sortCaret: sortCaret,
            classes: 'col-1',
        },
        {
            dataField: 'testProduct.articleCode',
            text: 'Product',
            formatter: (cell: string, row: TestCase) => (
                <div className="d-flex flex-column justify-content-center">
                    <div>{routingRulesContext.getArticleType(cell)?.name ?? ""}</div>
                    <div>{cell}</div>
                </div>
            ),
            sort: true,
            sortCaret: sortCaret,
            sortValue: (cell: string, row: TestCase) => {
                const result = {
                    articleCode: cell,
                    articleType: deriveArticleType(cell)
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.articleType !== second.articleType) {
                    if (order === 'desc') {
                        return second.articleType.localeCompare(first.articleType)
                    } else {
                        return first.articleType.localeCompare(second.articleType)
                    }
                }

                if (order === 'desc') {
                    return second.articleCode.localeCompare(first.articleCode)
                } else {
                    return first.articleCode.localeCompare(second.articleCode)
                }
            },
            classes: 'col-2',
        },
        {
            dataField: 'testProduct.productOptions',
            text: 'Options',
            formatter: (cell: Object, row: TestCase) => {
                const options = Object.entries(cell).sort()
                return (
                    <div className="d-flex">
                        <div className="d-flex flex-column justify-content-center">
                            {options.map((kvp, index) => (
                                <div key={index} >{kvp[0]}</div>
                            ))}
                        </div>
                        <div className="d-flex flex-column justify-content-center ml-3">
                            {options.map((kvp, index) => (
                                <div key={index} >{kvp[1]}</div>
                            ))}
                        </div>
                    </div>
                )
            },
            sort: false,
            classes: 'col-2',
        },
        {
            dataField: 'testContext',
            text: 'TestContext',
            formatter: (cell: TestContext, row: TestCase) => {
                let formattedDate = ''
                if (cell?.simulatedEvaluationDate) {
                    formattedDate = moment(cell.simulatedEvaluationDate).format("YYYY-MM-DD hh:mm")
                }
                return (
                    <div className="d-flex">
                        <div className="d-flex flex-column justify-content-center">
                            {cell?.simulatedEvaluationDate && <div>Date</div>}
                            {cell?.simulatedRandomValue && <div>Random</div>}
                        </div>
                        <div className="d-flex flex-column justify-content-center ml-3">
                            {cell?.simulatedEvaluationDate && <div>{formattedDate}</div>}
                            {cell?.simulatedRandomValue && <div>{(cell.simulatedRandomValue * 100)}%</div>}
                        </div>
                    </div>
                )
            },
            sort: false,
            classes: 'col-2',
        },
        {
            dataField: 'targetProductionSite',
            text: 'Result',
            formatter: (cell: string, row: TestCase) => (
                <div className="d-flex flex-column justify-content-center">
                    <div>{cell}</div>
                    {row.testResult && !row.testResult.result &&
                        <div>{`Result: ${(row.testResult.selectedProductionSite && row.testResult.selectedProductionSite !== '' && row.testResult.selectedProductionSite !== row.targetProductionSite)
                            ? row.testResult.selectedProductionSite
                            : row.testResult.failureReason
                            }`}
                        </div>}
                </div>
            ),
            sort: true,
            // Sort on test result (success/failure) before sorting on Factory string
            sortValue: (cell: string, row: TestCase) => {
                const result = {
                    testResult: row.testResult?.result ?? true,
                    factory: row.targetProductionSite
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.testResult !== second.testResult) {
                    if (order === 'desc') {
                        return second.testResult ? -1 : 1
                    } else {
                        return first.testResult ? -1 : 1
                    }
                }

                if (order === 'desc') {
                    return second.factory.localeCompare(first.factory)
                } else {
                    return first.factory.localeCompare(second.factory)
                }
            },
            sortCaret: sortCaret,
            classes: 'col-1',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell: any, row: TestCase) => (
                <div className='table-action-icons-container-leftaligned'>
                    <button
                        onClick={() => handleEditTestCaseClick(row)}
                        className='table-action-icons-container-button-small'
                    >
                        <EditIconWithCopy
                            copy='Edit'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => handleCloneTestCaseClick(row)}
                        className='table-action-icons-container-button-small'
                    >
                        <CloneIconWithCopy
                            copy='Clone'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => handleDeleteTestCaseClick(row)}
                        className='table-action-icons-container-button-small'
                    >
                        <DeleteIconWithCopy
                            copy='Delete'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                </div>
            ),
            sort: false,
            classes: 'col-1'
        },
    ]

    return (
        <>
            {(nrApiCallsInProgress > 0) &&
                <div className='d-flex justify-content-center'>
                    <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                    </div>
                </div>
            }
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
                rowClasses={setRowBackground}
                bootstrap4
                keyField='id'
                data={testcases}
                columns={columns}
                onTableChange={() => { }}
            >
            </BootstrapTable>
        </>
    );
}

export default TestCasesTable;