import { FC, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useMsal } from "@azure/msal-react";

const Login: FC = () => {
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();
  const handleLogin = () => {
    setLoading(true);
    instance.loginRedirect().then(() => {
      setLoading(false);
    });
  };

  return (
    <div className="login-form login-signin">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Use SSO</p>
        <button
          onClick={() => handleLogin()}
          className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
        >
          <span>Sign In</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
      </div>
      {/* end::Head */}
    </div>
  );
};

export default injectIntl(Login);
