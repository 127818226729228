import { Modal } from "react-bootstrap";
import ProductCategoryItemDialogHeader from "./ProductCategoryItemDialogHeader";
import ProductCategoryItemAddForm from "./ProductCategoryItemAddForm";

const ProductCategoryItemAddDialog = ({
  onHide,
  onSuccess,
  plant,
  productGroup,
  productType,
  singleProduct,
}: any) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <ProductCategoryItemDialogHeader
        productGroup={productGroup}
        singleProduct={singleProduct}
      />
      <ProductCategoryItemAddForm
        productGroup={productGroup}
        productType={productType}
        onHide={onHide}
        onSuccess={onSuccess}
        plant={plant}
        singleProduct={singleProduct}
      />
    </Modal>
  );
};

export default ProductCategoryItemAddDialog;
