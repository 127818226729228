import BootstrapTable from 'react-bootstrap-table-next';
import { transformLeadTimeData } from './utils/utils';
import { status, DryRunData } from './types';

const LeadTimesDryRunUpdates = ({
  dryRunResponseData,
}: {
  dryRunResponseData: DryRunData;
}) => {
  const updatedLeadTimes = transformLeadTimeData(
    dryRunResponseData?.leadTimeAudits ?? []
  );

  function statusFormatter(cell: any, row: any) {
    switch (row.changeStatus as status) {
      case 'Unchanged':
        return ``;
      case 'Updated':
        return (
          <span className='label label-lg label-inline label-light-warning'>
            Update
          </span>
        );
      case 'Inserted':
        return (
          <span className='label label-lg label-inline label-light-success'>
            Insert
          </span>
        );
      default:
        return row.changeStatus;
    }
  }

  const optionColumns = [
    {
      dataField: 'changeStatus',
      text: 'Status',
      sort: false,
      classes: 'width-set-10',
      formatter: statusFormatter,
    },
    {
      dataField: 'none',
      text: '',
      sort: false,
      classes: 'width-set-15 lighter',
    },
    {
      dataField: 'description',
      text: 'Option',
      sort: false,
      classes: 'pl-5 lighter',
    },
    {
      dataField: 'leadTime',
      text: 'Leadtime',
      sort: false,
      classes: 'width-set-15 lighter',
    },
    {
      dataField: 'extraLeadTime',
      text: 'Extra Leadtime',
      sort: false,
      classes: 'width-set-15 lighter',
    },
  ];

  const expandRow = {
    showExpandColumn: false,
    expandByColumnOnly: true,
    expanded: updatedLeadTimes
      ? updatedLeadTimes
          .filter(
            (row) =>
              row.productOptionLeadTimes &&
              row.productOptionLeadTimes.length > 0
          )
          .map((row) => row.id)
      : [],
    renderer: (row: any) => {
      const optionData = row.productOptionLeadTimes.map((option: any) => {
        return {
          id: `${row.articleCode}_${option.productOptionName}_${option.productOptionValue}`,
          changeStatus: option.changeStatus,
          description: `${option.productOptionName}: ${option.productOptionValue}`,
          leadTime:
            option.oldLeadTimeValues.baseLeadTime !==
            option.newLeadTimeValues.baseLeadTime
              ? `${option.oldLeadTimeValues.baseLeadTime} \u2192 ${option.newLeadTimeValues.baseLeadTime}`
              : option.newLeadTimeValues.baseLeadTime,
          extraLeadTime:
            option.oldLeadTimeValues.extraLeadTime !==
            option.newLeadTimeValues.extraLeadTime
              ? `${option.oldLeadTimeValues.extraLeadTime} \u2192 ${option.newLeadTimeValues.extraLeadTime}`
              : option.newLeadTimeValues.extraLeadTime,
        };
      });

      return (
        <BootstrapTable
          wrapperClasses='table-responsive no-padding'
          bordered={false}
          classes='table table-head-custom table-vertical-center overflow-hidden'
          bootstrap4
          remote
          keyField='desc'
          data={optionData}
          columns={optionColumns}
          onTableChange={() => {}}
          headerClasses='hidden-header'
          rowClasses='custom-row-class'
        />
      );
    },
  };

  const columns = [
    {
      dataField: 'changeStatus',
      text: 'Status',
      sort: false,
      classes: 'width-set-10',
      formatter: statusFormatter,
    },
    {
      dataField: 'articleCode',
      text: 'Article Code',
      sort: false,
      classes: 'width-set-15',
    },
    {
      dataField: 'description',
      text: 'Description/Option',
      sort: false,
    },
    {
      dataField: 'leadTimeUpdate',
      text: 'Leadtime',
      sort: false,
      classes: 'width-set-15',
    },
    {
      dataField: 'extraLeadTimeUpdate',
      text: 'Extra Leadtime',
      sort: false,
      classes: 'width-set-15',
    },
  ];

  return (
    <div className='row mt-10'>
      <div className='col-lg-12'>
        <BootstrapTable
          wrapperClasses='table-responsive'
          bordered={false}
          classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
          bootstrap4
          remote
          keyField='id'
          data={updatedLeadTimes}
          columns={columns}
          onTableChange={() => {}}
          expandRow={expandRow}
        />
      </div>
    </div>
  );
};

export default LeadTimesDryRunUpdates;
