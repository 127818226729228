import { Field, getIn, useFormikContext } from "formik"
import { Input, Select } from "../../../../_metronic/_partials/controls"
import { productOptionDropdownOptions } from "../../models/RoutingRuleDropdownData"

interface ProductOptionEditProps {
    productOptionFieldName: string
    onDeleteRow: () => any
}

const TestCaseProductOptionEditRow: React.FC<ProductOptionEditProps> = ({ productOptionFieldName, onDeleteRow }) => {
    const { setFieldValue, setFieldTouched, values } = useFormikContext()
    const productOption = getIn(values, productOptionFieldName)
    const productOptionDropdown = productOptionDropdownOptions.find((x) => x.variableName.toLowerCase() === productOption.option?.toLowerCase())
    const freeTextInput = (!productOptionDropdown || !productOptionDropdown.evaluationValues || productOptionDropdown.evaluationValues.length === 0)

    return (
        <>
            <div className="form-row">
                <div className="col-4 form-group mb-3 pl-4">
                    <Field
                        component={Select}
                        name={`${productOptionFieldName}.option`}
                        type="string"
                        value={productOption.option.toLowerCase()}
                        onChange={async (changeEvent: any) => {
                            await setFieldValue(`${productOptionFieldName}.option`, changeEvent.target.value)
                            setFieldTouched(`${productOptionFieldName}.option`, true)
                            //The optionValue is unique to the variable, so clear them on variable change.
                            await setFieldValue(`${productOptionFieldName}.optionValue`, "")
                            setFieldTouched(`${productOptionFieldName}.optionValue`, true)
                        }}
                    >
                        <option key={"select"} value="">select Option</option>
                        {productOptionDropdownOptions.map(opt => (
                            <option key={opt.variableName} value={opt.variableName.toLowerCase()}>{opt.variableName}</option>
                        ))}
                    </Field>
                </div>
                <div className="col-4 form-group mb-3">
                    {freeTextInput && <Field
                        component={Input}
                        name={`${productOptionFieldName}.optionValue`}
                        type="string"
                    />}
                    {!freeTextInput && <Field
                        component={Select}
                        name={`${productOptionFieldName}.optionValue`}
                        type="string"
                    >
                        <option key={"select"} value="">{`select ${productOptionDropdown?.variableName}`}</option>
                        {(productOptionDropdown?.evaluationValues ?? []).map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Field>}
                </div>
                <div className="col-1 form-group mb-3">
                    <button
                        type="button"
                        className="form-control border-0 bg-transparent col-3 text-bold text-left pl-0"
                        onClick={onDeleteRow}
                    >
                        X
                    </button>
                </div>
            </div>
        </>
    )
}

export default TestCaseProductOptionEditRow