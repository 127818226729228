import React, { useState, useEffect, createRef } from "react";
import { deleteHoliday, putHolidays, getHolidays } from "../api/HolidaysApi";
import { usePlant } from "../../components/PlantHook";
import FullCalendar, {
  EventAddArg,
  EventInput,
  EventRemoveArg
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap from "@fullcalendar/bootstrap";
import { Alert, Spinner } from "react-bootstrap";

const HolidaysPage = () => {
  const [events,setEvents] = useState([] as EventInput[]);
  const [year,setYear] = useState(new Date().getFullYear().toString());
  const [operationFailed,setOperationFailed] = useState(false);
  const [dataIsLoading,setDataIsLoading] = useState(false);
  const calendarRef = createRef<FullCalendar>();
  const plantCode = usePlant();

  const retrieveHolidays = async (plantCode: string) => {  
    setDataIsLoading(true);
    setOperationFailed(false);
    
    getHolidays(plantCode, year)
      .then((res) => {
        const retrievedEvents = res.holidays.map((h) => {
          let event = {
            title: h.description,
            editable: true,
            date: h.date.format("YYYY-MM-DD"),
            allDay: true,
          } as EventInput;
          return event;
        });
        setEvents(retrievedEvents);
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => setDataIsLoading(false));
  }
  
  // Effects
  // Reloading on year and events changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { retrieveHolidays(plantCode); }, [year, plantCode]);

  // Alert auto timeout
  useEffect(() => { handleTimeout(); }, [operationFailed]);

  const getApi = () => {
    const { current: calendarDom } = calendarRef;
    return calendarDom ? calendarDom.getApi() : null;
  };

  const handleAlertDismiss = () => {
    setOperationFailed(false);
  }

  // Calendar event handler
  const handleNext = () => {
    let calendarApi = getApi();
    calendarApi?.next();

    let currentYear = calendarApi?.getDate().getFullYear().toString();
    if (currentYear !== year) {
      setYear(currentYear as string);
    }
  }

  const handlePrevious = () => {
    let calendarApi = getApi();
    calendarApi?.prev();

    let currentYear = calendarApi?.getDate().getFullYear().toString();
    if (currentYear !== year) {
      setYear(currentYear as string);
    }
  }

  const handleTimeout = () => {
    window.setTimeout(() => {
      setOperationFailed(false);
    }, 2000);
  }

  const handleEventAdd = (arg: EventAddArg) => {
    putHolidays(plantCode, arg.event.startStr, arg.event.title).catch((ex) => {
      setOperationFailed(true);
      arg.event.remove();
    });
  }

  const handleEventDelete = (arg: EventRemoveArg) => {
    deleteHoliday(plantCode, arg.event.startStr)
      .catch(() => {
        setOperationFailed(true);
        arg.revert();
      });
  }

  const handleEventClick = (clickInfo: any) => {
    setOperationFailed(false);
    if (window.confirm(`Are you sure you want to delete the holiday '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  };

  const handleDateSelect = (selectInfo: any) => {
    setOperationFailed(false);

    let title = prompt("Please enter a name for the holiday");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: true,
      });
    }
  };

  //Render
  return (
      <>
        <div className="row">
          <div>
            {operationFailed && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={handleAlertDismiss}
              >
                Something went wrong, please try again
              </Alert>
            )}
            <div className="card card-custom card-stretch gutter-b">
              <div className="card-header">
                <h3 className="card-label card-title font-weight-bolder text-dark">
                  {dataIsLoading && (
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </h3>
              </div>
              <div className="card-body">
                <div className=" col-lg-6">
                  <FullCalendar
                    plugins={[
                      bootstrap,
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                    ]}
                    customButtons={{
                      previousButton: {
                        text: "«",
                        click: handlePrevious,
                      },
                      nextButton: {
                        text: "»",
                        click: handleNext,
                      },
                    }}
                    headerToolbar={{
                      left: "previousButton",
                      center: "title",
                      right: "nextButton",
                    }}
                    aspectRatio={1.5}
                    height="auto"
                    ref={calendarRef}
                    themeSystem="bootstrap"
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    events={events}
                    eventAdd={handleEventAdd}
                    eventRemove={handleEventDelete}
                    eventClick={handleEventClick}
                    select={handleDateSelect}
                    showNonCurrentDates={false}
                  ></FullCalendar>
                </div>
                <div className=" col-lg-6"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );  
}

export { HolidaysPage };
