import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../_metronic/_partials/controls";
import { getDeliveryGroups, setDeliveryGroup } from "../api/DeliveryGroupApi";

const DeliveryGroupAddForm = ({ onHide, onSuccess, plant }: any) => {
  const onHideClick = () => {
    onHide();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          deliveryGroupName: "",
          deliveryGroupItems: ""
        }}
        onSubmit={async (values, { setStatus }) => {
          try {
            await setDeliveryGroup(plant, values.deliveryGroupName, []);
            setStatus("success");
            setTimeout(() => {
              onHide(); // Close the modal after a successful submission
              onSuccess();
            }, 1500);
            getDeliveryGroups(plant);
          } catch (error) {
            setStatus("error");
          }
        }}
      >
        {({ handleSubmit, handleChange, status }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group">
                  <div>
                    <Field
                      name="deliveryGroupName"
                      component={Input}
                      placeholder="ex. Calendars"
                      label="Delivery Group Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row"></div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHideClick}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            {status === "success" && (
              <div className="text-success text-center mt-3 p-10">
                Saved successfully!
              </div>
            )}
            {status === "error" && (
              <div className="text-danger text-center mt-3 p-10">
                An error occurred.
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default DeliveryGroupAddForm;
