import React from 'react';

type Props = {
  copy: string;
  customStyle?: string;
  color?: string;
};

const ResetIconWithCopy = ({ copy, customStyle, color }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_543_32540" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.666016" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_543_32540)">
          <path
            d="M11.666 20.95C9.64935 20.7 7.97852 19.8208 6.65352 18.3125C5.32852 16.8042 4.66602 15.0333 4.66602 13C4.66602 11.9 4.88268 10.8458 5.31602 9.8375C5.74935 8.82917 6.36602 7.95 7.16602 7.2L8.59102 8.625C7.95768 9.19167 7.47852 9.85 7.15352 10.6C6.82852 11.35 6.66602 12.15 6.66602 13C6.66602 14.4667 7.13268 15.7625 8.06602 16.8875C8.99935 18.0125 10.1993 18.7 11.666 18.95V20.95ZM13.666 20.95V18.95C15.116 18.6833 16.3118 17.9917 17.2535 16.875C18.1952 15.7583 18.666 14.4667 18.666 13C18.666 11.3333 18.0827 9.91667 16.916 8.75C15.7493 7.58333 14.3327 7 12.666 7H12.591L13.691 8.1L12.291 9.5L8.79102 6L12.291 2.5L13.691 3.9L12.591 5H12.666C14.8993 5 16.791 5.775 18.341 7.325C19.891 8.875 20.666 10.7667 20.666 13C20.666 15.0167 20.0035 16.7792 18.6785 18.2875C17.3535 19.7958 15.6827 20.6833 13.666 20.95Z"
            fill={(color) ? color : "#236CFF"}
          />
        </g>
      </svg>
    </div>
    <div>{copy}</div>
  </div>
);

export default ResetIconWithCopy;
