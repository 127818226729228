import { getPlantData } from './ComponentsApi';
import { componentsSlice } from './ComponentsSlice';

const { actions } = componentsSlice;

export const selectPlant =
  (plantCode: string, plantSupportsCarrierConfiguration: boolean) =>
  (dispatch: (args: { payload: any; type: string }) => void) => {
    dispatch(
      actions.plantSelected({ plantCode, plantSupportsCarrierConfiguration })
    );
  };

export const fetchPlantData = () => async (dispatch: (arg0: any) => void) => {
  return getPlantData().then((response: any) => {    
    dispatch(actions.plantDataFetched({ response }));
  });
};
