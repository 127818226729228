import { FC, ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { CarrierOverride, Carrier } from "../../models/CarrierOverrideModels";
import { CarrierOverrideEditDialogHeader } from "./CarrierOverrideEditDialogHeader";
import { CarrierOverrideEditForm } from "./CarrierOverrideEditForm";

export const CarrierOverrideEditDialog: FC<{
  saveCarrierOverride: (values: CarrierOverride) => void;
  deleteCarrierOverride: (plantCode: string, ruleId: string) => void;
  carrierOverrideForEdit: CarrierOverride;
  hideModal: () => void;
  showModal: boolean;
  carriers: Carrier[];
}> = ({ carrierOverrideForEdit, hideModal, showModal, saveCarrierOverride, deleteCarrierOverride, carriers }): ReactElement => {

  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <CarrierOverrideEditDialogHeader
          carrierOverride={carrierOverrideForEdit}
        />
        <CarrierOverrideEditForm
          saveCarrierOverride={saveCarrierOverride}
          deleteCarrierOverride={deleteCarrierOverride}
          carrierOverride={carrierOverrideForEdit}
          onHide={hideModal}
          carriers={carriers}
        />
      </Modal>
    </>
  );
};
