import { AxiosError } from 'axios';
import { getRoutingRules, getTestCases, postRoutingRules, putTestCases, runTestSuite } from '../api/RoutingRulesApi';
import { IRoutingRule, IRoutingRuleSet } from '../models/RoutingRuleSetModel';
import { routingRulesSlice, callTypes } from "./RoutingRulesSlice";
import { RoutingRulesAlert } from '../models/RoutingRulesAlert';
import { getArticleTypes, getBrands, getChannels } from '../api/DropdownDataApi';
import { TestCase } from '../models/TestSuiteModel';

const { actions: sliceActions } = routingRulesSlice;

const createAlert = (error: AxiosError, message: string) => {
    return {
        message: message,
        apiResponseCode: error.response?.status,
        apiMessage: error.message,
        url: error.config?.url,
        data: error.response?.data
    } as RoutingRulesAlert
}

export const fetchRoutingRules = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getRoutingRules()
            .then(response => {
                const routingRuleSet = response
                dispatch(sliceActions.routingRulesFetched(routingRuleSet));
                dispatch(sliceActions.clearRoutingRuleChanges());
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on GET for Routing Rules")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const persistRoutingRuleSet = (ruleSet: IRoutingRuleSet, description: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.post }));
        const updatedRuleSet = {
            ...ruleSet,
            description: description
        }
        return postRoutingRules(updatedRuleSet)
            .then(response => {
                const routingRuleSet = response
                dispatch(sliceActions.routingRulesFetched(routingRuleSet));
                dispatch(sliceActions.clearRoutingRuleChanges());
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on POST for Routing Rules")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };

export const fetchTestCases = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getTestCases()
            .then(response => {
                const testCases = response
                dispatch(sliceActions.testCasesFetched(testCases));
                dispatch(sliceActions.clearTestCaseChanges())
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on GET for Test Cases")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const persistTestCases = (testCases: TestCase[]) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.put }));
        return putTestCases(testCases)
            .then(response => {
                const testCases = response
                dispatch(sliceActions.testCasesFetched(testCases));
                dispatch(sliceActions.clearTestCaseChanges());
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on PUT for Test Cases")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };


export const fetchArticleTypes = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getArticleTypes()
            .then(response => {
                const articleTypes = response
                dispatch(sliceActions.articleTypesFetched(articleTypes));
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on GET for Article Types")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const fetchBrands = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getBrands()
            .then(response => {
                const articleTypes = response
                dispatch(sliceActions.brandsFetched(articleTypes));
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on GET for Brands")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const fetchChannels = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getChannels()
            .then(response => {
                const channels = response
                dispatch(sliceActions.channelsFetched(channels));
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on GET for Channels")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const markRoutingRuleChanged = (ruleId: string, isDeletion: boolean) =>
    async (dispatch: (arg0: any) => void) => {
        const payload = {
            ruleId: ruleId,
            isDeletion: isDeletion
        }
        dispatch(sliceActions.markRoutingRuleChanged(payload));
    };

export const upsertRoutingRule = (rule: IRoutingRule) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.upsertRoutingRule(rule));
    };

export const deleteRoutingRule = (ruleId: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.deleteRoutingRule(ruleId));
    };

export const updateRuleSetDescription = (description: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.updateRuleSetDescription(description));
    };

export const markTestCaseChanged = (testCaseId: string, isDeletion: boolean) =>
    async (dispatch: (arg0: any) => void) => {
        const payload = {
            testCaseId: testCaseId,
            isDeletion: isDeletion
        }
        dispatch(sliceActions.markTestCaseChanged(payload));
    };

export const upsertTestCase = (testCase: TestCase) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.upsertTestCase(testCase));
    };

export const deleteTestCase = (testCaseId: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.deleteTestCase(testCaseId));
    };

export const runTestCases = (ruleSet: IRoutingRuleSet, testcases: TestCase[], description?: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.post }));
        const updatedRuleSet = {
            ...ruleSet,
            description: description
        } as IRoutingRuleSet
        return runTestSuite(updatedRuleSet, testcases)
            .then(response => {
                const testResults = response
                dispatch(sliceActions.fetchedTestSuiteResults(testResults));
            })
            .catch(err => {
                const alert = createAlert(err, "Failure on POST for Run Test Suite")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };

export const dismissAlert = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.dismissAlert());
    };