import { LeadTimeAuditData, LeadTimeAuditDataExtended } from './../types';

export const transformLeadTimeData = (
  updates: LeadTimeAuditData[]
): LeadTimeAuditDataExtended[] => {
  return updates
    .map((update) => {
      return {
        ...update,
        productOptionLeadTimes: update.productOptionLeadTimes.filter(
          (productOption) =>
            productOption.changeStatus === 'Updated' ||
            productOption.changeStatus === 'Inserted'
        ),
      };
    })
    .filter(
      (update) =>
        update.changeStatus === 'Updated' ||
        update.changeStatus === 'Inserted' ||
        update.productOptionLeadTimes.length !== 0
    )
    .map((update) => {
      return {
        ...update,
        id: update.articleCode,
        leadTimeUpdate:
          update.oldLeadTimeValues.baseLeadTime !==
          update.newLeadTimeValues.baseLeadTime
            ? `${update.oldLeadTimeValues.baseLeadTime} \u2192 ${update.newLeadTimeValues.baseLeadTime}`
            : update.oldLeadTimeValues.baseLeadTime,
        extraLeadTimeUpdate:
          update.oldLeadTimeValues.extraLeadTime !==
          update.newLeadTimeValues.extraLeadTime
            ? `${update.oldLeadTimeValues.extraLeadTime} \u2192 ${update.newLeadTimeValues.extraLeadTime}`
            : update.oldLeadTimeValues.extraLeadTime,
      };
    });
};
