import { IAlertModel } from "../models/SkuMappingsModels";
import { skuMappingsSlice } from "./SkuMappingsSlice";
import { downloadSkuMappings as downloadSkuMappingsApi  , uploadSkuMappings as uploadSkuMappingsApi } from "../api/SkuMappingsApi";
import moment from "moment";

const { actions } = skuMappingsSlice;
const successTitle = "Success!";
const errorTitle = "Error while calling API";

export const dismissAlert = () => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.alertDismissed());
};

export const downloadSkuMappings = () => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    var skuMappings = await downloadSkuMappingsApi();
    const successAlert = {
      title: successTitle,
      message: "File downloaded",
      variant: "success",
    } as IAlertModel;
    dispatch(actions.skuMappingsFetched({ skuMappings, successAlert }));
    console.log(skuMappings);
    const url = window.URL.createObjectURL(new Blob([skuMappings]));
        const link = document.createElement("a");
        link.href = url;
        const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
        link.download = "sku-mappings-" + dateTime + ".csv";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
  } catch (err) {
    const error = {
      title: errorTitle,
      message: "Can't download sku mappings",
      variant: "danger",
    } as IAlertModel;
    console.error(err);
    dispatch(actions.catchError({ error }));
  }
};

export const uploadSkuMappings = (file:any, dryRun:boolean) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    var uploadResult = await uploadSkuMappingsApi(file, dryRun);
    const successAlert = {
      title: successTitle,
      message: `Sku Import success (dryRun=${dryRun})`,
      variant: "success",
    } as IAlertModel;
    dispatch(actions.skuMappingsUploaded({ uploadResult, successAlert }));
  } catch (err) {
    const error = {
      title: errorTitle,
      message: "Error uploading sku mappings",
      variant: "danger",
    } as IAlertModel;
    console.error(err);
    dispatch(actions.catchError({ error }));
  }
};
