import { FC, ReactElement, useEffect, useState } from "react";
import { RoutingRulesEdit } from "./routing-rules-edit/RoutingRulesEdit";
import { TestCases } from "./test-cases-edit/TestCases";
import { TestResults } from "./test-results/TestResults";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/RoutingRulesActions";
import { Alert } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { RoutingRulesPage as NewRoutingRulesPage } from "../../newRoutingRules/pages/RoutingRulesPage"

export const RoutingRulesPage: FC<{ props: any }> = ({ props }): ReactElement => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state: any) => ({ currentState: state.routingRules }),
    shallowEqual
  );

  const { accounts }: any = useMsal();
  var roles = accounts[0].idTokenClaims.roles as Array<string>;
  var filteredRoles = roles.filter(x => x === "Developer");
  var developerMode = filteredRoles.length > 0;

  const [routingRulesVersion, setRoutingRulesVersion] = useState(developerMode ? 'v2' : 'v1');

  const toggleVersion = () => {
    setRoutingRulesVersion(routingRulesVersion === 'v1' ? 'v2' : 'v1');
  }

  const { routingRulesYaml, testResult, testSummary, alert } = currentState;

  const handleSaveRules = () => {
    dispatch(actions.saveRules(currentState.routingRulesYaml));
  };

  const handleSaveTestCases = () => {
    dispatch(actions.saveTestCases(currentState.testCases));
  };

  const handleRunTests = () => {
    if (routingRulesVersion === 'v1') {
      dispatch(actions.runTests(currentState.routingRulesYaml, currentState.testCases));
    }
  };

  const handleClearTestResults = () => {
    dispatch(actions.clearTestResults());
  };

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  useEffect(() => {
    dispatch(actions.clearTestResults());
    dispatch(actions.fetchRoutingRules());
    dispatch(actions.fetchTestCases());
  }, [dispatch]);

  return (
    <>
      {developerMode &&
        <button onClick={toggleVersion}
          className="btn btn-primary btn-elevate" style={{ float: "right" }}>{routingRulesVersion === 'v1' ? 'V2' : 'YAML'}</button>
      }
      {routingRulesVersion === 'v1' &&
        <>
          {alert && (
            <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
              <Alert.Heading>{alert.title}</Alert.Heading>
              <p>{alert.message}</p>
              <p>{alert.additionalInfo}</p>
            </Alert>
          )}
          <RoutingRulesEdit
            currentRules={routingRulesYaml}
            canSaveRules={currentState.canSaveRoutingRules}
            saveRulesHandler={handleSaveRules}
          ></RoutingRulesEdit>
          <br />
          <TestCases
            testCases={currentState.testCases}
            saveTestCases={handleSaveTestCases}
          ></TestCases>
          <br />
          <TestResults
            testResult={testResult}
            testSummary={testSummary}
            runTests={handleRunTests}
            clearTestResults={handleClearTestResults}
          ></TestResults>
        </>
      }
      {routingRulesVersion === 'v2' &&
        <NewRoutingRulesPage />
      }
    </>
  );
};