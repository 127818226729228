import { Modal } from "react-bootstrap";
import { IArticle } from "../../../models/ProductGroups";
import isEmpty from "../../../../utils/isEmpty";

interface ProductCategoryItemDialogHeaderProps {
  productGroup: string;
  singleProduct: IArticle;
}

const ProductCategoryItemDialogHeader = ({
  productGroup,
  singleProduct,
}: ProductCategoryItemDialogHeaderProps) => {
  const { articleCode } = singleProduct || {};
  return (
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
        {isEmpty(singleProduct)
          ? `Add new product to ${productGroup} group`
          : `Edit ${articleCode}`}
      </Modal.Title>
    </Modal.Header>
  );
};

export default ProductCategoryItemDialogHeader;
