const RollIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={42}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M12.426 38.743v-6.62c0-.1.08-.19.19-.19h2.59c1.2 0 2.18.94 2.18 2.13 0 .92-.61 1.69-1.48 2.04l1.37 2.54c.07.13 0 .29-.17.29h-.48c-.12 0-.17-.05-.22-.15l-1.3-2.59h-1.91v2.55c0 .1-.09.19-.19.19h-.39a.19.19 0 0 1-.19-.19Zm.79-3.21h1.94c.77 0 1.44-.64 1.44-1.46 0-.76-.67-1.41-1.44-1.41h-1.94v2.87Zm4.98 1.08c0-1.3 1.04-2.4 2.32-2.4 1.28 0 2.32 1.1 2.32 2.4 0 1.32-1.04 2.43-2.32 2.43-1.28 0-2.32-1.11-2.32-2.43Zm.73 0c0 .96.68 1.76 1.59 1.76s1.58-.8 1.58-1.76c0-.94-.67-1.73-1.58-1.73s-1.59.79-1.59 1.73Zm5.216 2.1v-6.56c0-.11.1-.22.22-.22h.3c.11 0 .22.11.22.22v6.56c0 .12-.11.22-.22.22h-.3c-.12 0-.22-.1-.22-.22Zm2.402 0v-6.56c0-.11.1-.22.22-.22h.3c.11 0 .22.11.22.22v6.56c0 .12-.11.22-.22.22h-.3c-.12 0-.22-.1-.22-.22Z"
    />
    <mask
      id="a"
      width={38}
      height={23}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h38v22.4H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1C1B1F"
        d="M19 20.533c-2.657 0-4.904-.361-6.742-1.085-1.839-.723-2.758-.781-2.758-2.648V5.6c0-1.027.928-1.906 2.784-2.637 1.856-.73 4.095-1.096 6.716-1.096 2.621 0 4.86.365 6.716 1.096 1.856.731 2.784 1.61 2.784 2.637v11.2c0 1.867-.92 1.925-2.758 2.648-1.838.724-4.085 1.085-6.742 1.085ZM19 7.49c1.566 0 3.14-.198 4.724-.595 1.61-.362 1.992-1.272 1.992-1.272s-.405-.879-1.98-1.283A18.971 18.971 0 0 0 19 3.733c-1.6 0-3.171.199-4.71.595-1.54.397-2.006 1.295-2.006 1.295s.466.895 2.006 1.284c1.539.389 3.11.583 4.71.583Zm0 11.177c.81 0 1.632-.055 2.467-.164a19.757 19.757 0 0 0 2.31-.431c.703-.18 1.081-.381 1.556-.607V8.4c-.457.218.093-.031-.461.14-.555.171-1.144.315-1.768.432a21.08 21.08 0 0 1-1.966.268c-.687.062-1.4.093-2.138.093-.739 0-1.46-.03-2.164-.093a20.871 20.871 0 0 1-1.992-.268 15.17 15.17 0 0 1-1.755-.432c-.546-.171.018.078-.422-.14V17.465c.466.218.826.428 1.53.607.704.179 1.478.323 2.322.431.845.11 1.672.164 2.481.164Z"
      />
    </g>
  </svg>
);
export default RollIcon;
