/**
 * Entry application component used to compose providers and render Routes.
 * */
import React, { FC, ReactElement } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import "@albelli/arc-3/lib/core.css";
import "@albelli/arc-3/lib/icon.css";

const App: FC<{ pca: any; store: any; basename: any; pathname: string; }> = ({
  pca,
  store,
  basename,
  pathname
}): ReactElement => {
  return (
    <MsalProvider instance={pca}>
      {/* Provide Redux store */}
      <Provider store={store}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes pathname={pathname} />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </Provider>
    </MsalProvider>
  );
};

export default App;
