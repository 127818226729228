import { Modal } from "react-bootstrap";
import { SelectionReason, useRoutingRuleContext } from "../../RoutingRuleContext";
import { Field, Form, Formik } from "formik";
import { Select } from "../../../../_metronic/_partials/controls";
import * as actions from '../../redux/RoutingRulesActions'
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../svg/DeleteIcon";
import * as Yup from "yup";
import { TestCase } from "../../models/TestSuiteModel";
import { GetPlants } from "../../../components/PlantSelectorDropdown";
import TestCaseAddressEditBlock from "./TestCaseAddressEditBlock";
import TestCaseProductEditBlock from "./TestCaseProductEditBlock";
import TestCaseContextEditBlock from "./TestCaseContextEditBlock";
import { Fragment } from "react/jsx-runtime";

type Props = {
}

type ProductOption = {
    option: string,
    optionValue: string
}

export interface EditTestCase extends TestCase {
    articleType: string
    productOptionsArray: ProductOption[]
}

const TestCaseEditModal = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext();
    const dispatch = useDispatch();
    const selectedTestCase = routingRulesContext.selectedTestCase.testCase;
    const brands = routingRulesContext.brands
    const factoryList = GetPlants().map((x) => x.plantCode)

    const handleCancelClick = () => {
        routingRulesContext.uiEvents.hideTestCaseEditModal()
    }

    const handleDeleteClick = () => {
        dispatch(actions.markTestCaseChanged(selectedTestCase.id, true))
        dispatch(actions.deleteTestCase(selectedTestCase.id))
        routingRulesContext.uiEvents.hideTestCaseEditModal()
    }

    const editableTestCase = {
        ...selectedTestCase,
        testProduct: {
            ...selectedTestCase.testProduct,
            articleCode: selectedTestCase.testProduct.articleCode.toLowerCase(),
            productOptions: {
                ...selectedTestCase.testProduct.productOptions
            }
        },
        testContext: {
            ...selectedTestCase.testContext
        },
        articleType: routingRulesContext.getArticleType(selectedTestCase.testProduct.articleCode)?.name ?? "",
        productOptionsArray: Object.entries(selectedTestCase.testProduct.productOptions).sort()
            .map((kvp) => {
                return {
                    option: kvp[0],
                    optionValue: kvp[1]
                } as ProductOption
            })
    } as EditTestCase

    type DirectlyWritable = { [key: string]: string }

    const toTestCase = (editTestCase: EditTestCase) => {
        const testCase = {
            ...editTestCase,
            testProduct: {
                ...editTestCase.testProduct,
                productOptions: {
                    //empty for now, will write properties directly in here bypassing any typing
                }
            },
            testContext: {
                ...editTestCase.testContext
            },
            testResult: undefined
        } as TestCase

        //We're explicitly updating the productOption with dynamic properties in-place in the testCase
        const directlyWritable = testCase.testProduct.productOptions as DirectlyWritable
        editTestCase.productOptionsArray.forEach(po => directlyWritable[po.option] = po.optionValue)
        return testCase
    }

    let header = "Edit Test Case"
    switch (routingRulesContext.selectedTestCase.selectionReason) {
        case SelectionReason.Clone:
            header = "Clone Test Case"
            break;
        case SelectionReason.New:
            header = "New Test Case"
            break;
        case SelectionReason.Default:
        default:
            break;
    }

    const testCaseValidation = Yup.object().shape({
        testProduct: Yup.object().shape({
            channelName: Yup.string().required("Please select a Channel Name"),
            receiverName: Yup.string().notRequired(),
            destinationPostCode: Yup.string().notRequired(),
            destinationCountry: Yup.string().required("Please enter a Country Code")
                .length(2, "Country Codes should be 2 characters long"),
            deliveryMethod: Yup.string().notRequired(),
            deliveryType: Yup.string().notRequired(),
            articleCode: Yup.string().required("Please select a Product Code")
        }),
        articleType: Yup.string().required("Please select a Product Type"),
        targetProductionSite: Yup.string().required("Please select a Target Factory"),
        testContext: Yup.object().shape({
            simulatedRandomValue: Yup.number().notRequired()
                .min(0.01, "Valid percentiles are from 1 through 100")
                .max(1, "Valid percentiles are from 1 through 100"),
            simulatedEvaluationDate: Yup.date().notRequired()
        }),
        productOptionsArray: Yup.array()
            .of(Yup.object().shape({
                option: Yup.string().required("Please select a Product Option"),
                optionValue: Yup.string().required("Please select an Option Value"),
            }))
            .test(
                'productOption',
                'ProductOption duplicate',
                (value, testContext) => {
                    const productOptions = (value ?? []) as ProductOption[]
                    const errors = productOptions.map((option1, index1) =>
                        (productOptions.some((option2, index2) => option2.option === option1.option && index2 !== index1))
                            ? testContext.createError({
                                message: `Product Option ${option1.option} is present multiple times`,
                                path: `productOptionsArray[${index1}].option`
                            })
                            : null
                    ).filter(error => error !== null) as Yup.ValidationError[]
                    return new Yup.ValidationError(errors)
                }
            )
    })

    return (
        <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {header}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={editableTestCase}
                validationSchema={testCaseValidation}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(editTestCase, formikBag) => {
                    const testCase = toTestCase(editTestCase)
                    dispatch(actions.markTestCaseChanged(testCase.id, false))
                    dispatch(actions.upsertTestCase(testCase))
                    formikBag.setSubmitting(false)
                    routingRulesContext.uiEvents.hideTestCaseEditModal()
                }}>
                <Form className="form">
                    <Modal.Body>
                        <div className="form-row">
                            <div className="form-group col-4">
                                <label htmlFor="testcase-edit-channel">Channel</label>
                                <Field
                                    component={Select}
                                    id="testcase-edit-channel"
                                    name="testProduct.channelName"
                                >
                                    <option key={"select"} value="">select Channel</option>
                                    {brands.map(brand => (
                                        <Fragment key={brand.name}>
                                            <optgroup key={brand.name} label={brand.name} />
                                            {brand.channels.map(channel => (
                                                <option key={channel} value={channel}>{channel}</option>
                                            ))}
                                        </Fragment>
                                    ))}
                                </Field>
                            </div>
                            <div className="form-group col-4">
                                <label htmlFor="testcase-edit-factory">Target Factory</label>
                                <Field
                                    component={Select}
                                    id="testcase-edit-factory"
                                    name="targetProductionSite"
                                >
                                    <option key={"select"} value="">select Target Factory</option>
                                    {factoryList.map(factory => (
                                        <option key={factory} value={factory}>{factory}</option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <TestCaseAddressEditBlock />
                        <TestCaseProductEditBlock />
                        <TestCaseContextEditBlock />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between width-set-100">
                            <button
                                type="button"
                                className="text-primary border-0 bg-transparent"
                                onClick={handleDeleteClick}>
                                <div className="d-flex text-danger mt-3">
                                    <u className="mr-1 mt-2">Delete Test Case</u>
                                    <DeleteIcon color="#F64E60" />
                                </div>
                            </button>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    onClick={handleCancelClick}
                                    className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className='btn btn-primary-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                                    Save
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Formik >
        </Modal >
    );
}

export default TestCaseEditModal;