import { IProduct } from "../../models/LeadTimes";
import { Product } from "../ProductsContext";
import { IPutProduct } from "../api/ProductsApi";

const mapProductToIProductPut = (
  source: Product,
  productGroup: string,
  plantCode: string
) => {
  return {
    articleCode: source.id,
    plantCode: plantCode,
    productionLeadTimeDays: source.leadTime,
    productionExtraLeadTimeDays: source.extraLeadTime,
  } as IPutProduct;
};

const mapIProductToProduct = (source: IProduct) => {
  return {
    id: source.productCode,
    desc: source.description,
    extraLeadTime: source.extraLeadTime,
    leadTime: source.leadTime,
  } as Product;
};

export { mapProductToIProductPut, mapIProductToProduct };
