import { Modal } from "react-bootstrap";
import { SelectionReason, useRoutingRuleContext } from "../../RoutingRuleContext";
import { Field, Form, Formik } from "formik";
import { activityRuleTypes, conditionOperators, conditionTypes, IRoutingRule, IProductionSiteSelectionData, deepCloneRoutingRule } from "../../models/RoutingRuleSetModel";
import { Input } from "../../../../_metronic/_partials/controls";
import * as actions from '../../redux/RoutingRulesActions'
import { useDispatch } from "react-redux";
import RoutingRuleTriggerEditBlock from "./RoutingRuleTriggerEditBlock";
import RoutingRuleDateRangeEditBlock from "./RoutingRuleDateRangeEditBlock";
import RoutingRuleFactoryEditBlock from "./RoutingRuleFactoryEditBlock";
import DeleteIcon from "../../../svg/DeleteIcon";
import * as Yup from "yup";

type Props = {
}

const RoutingRuleEditModal = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext();
    const dispatch = useDispatch();
    const selectedRoutingRule = routingRulesContext.selectedRoutingRule.routingRule;

    const handleCancelClick = () => {
        routingRulesContext.uiEvents.hideRuleEditModal()
    }

    const handleDeleteClick = () => {
        dispatch(actions.markRoutingRuleChanged(selectedRoutingRule.id, true))
        dispatch(actions.deleteRoutingRule(selectedRoutingRule.id))
        routingRulesContext.uiEvents.hideRuleEditModal()
    }

    const ensureDateRangeIsEditable = (rule?: IRoutingRule) => {
        const activityRules = rule?.activityRules?.map((ar) => { return { ...ar } }) ?? []
        const dateRangeIndex = rule?.activityRules?.findIndex(x => x.activityRuleType === activityRuleTypes.activeBetweenDatesRule)
        if (rule && (dateRangeIndex === undefined || dateRangeIndex === -1)) {
            activityRules?.push({
                activityRuleType: activityRuleTypes.activeBetweenDatesRule,
                activeFrom: undefined,
                activeUntil: undefined
            })
        }
        return activityRules
    }

    const ensureFactoryWeightsArePercentage = (rule?: IRoutingRule) => {
        let productionSiteSelectionData = [] as IProductionSiteSelectionData[]
        if (rule?.decision) {
            const totalWeight = rule?.decision?.map((site) => site.weight).reduce(function (a, b) { return a + b; }, 0);
            productionSiteSelectionData = rule?.decision?.map((site) => ({
                productionSite: site.productionSite,
                weight: Math.round((site.weight / totalWeight) * 100)
            }))
        }
        return productionSiteSelectionData
    }

    let editableRoutingRule = deepCloneRoutingRule(selectedRoutingRule)
    editableRoutingRule.activityRules = ensureDateRangeIsEditable(selectedRoutingRule)
    editableRoutingRule.decision = ensureFactoryWeightsArePercentage(selectedRoutingRule)

    let header = "Edit Routing Rule"
    switch (routingRulesContext.selectedRoutingRule.selectionReason) {
        case SelectionReason.Clone:
            header = "Clone Routing Rule"
            break;
        case SelectionReason.New:
            header = "New Routing Rule"
            break;
        case SelectionReason.Default:
        default:
            break;
    }

    const evaluationValuesValidation = Yup.array()
        .when("conditionOperator", {
            is: (val: string) => val === conditionOperators.isAny,
            then: (schema) => schema.compact((val) => val === '').length(0, "The IsAny operator does not take any evaluationValues")
        })
        .when("conditionOperator", {
            is: (val: string) => val === conditionOperators.in || val === conditionOperators.isSubsetOf,
            then: (schema) => schema.compact((val) => val === '').min(2, "The In and IsSubsetOf operators require multiple evaluationValues")
        })
        .when("conditionOperator", {
            is: (val: string) => val === conditionOperators.equal || val === conditionOperators.equalOrGreater || val === conditionOperators.equalOrLesser,
            then: (schema) => schema.compact((val) => val === '').length(1, "The Equal... operators require a single evaluationValue")
        })

    const routingRuleValidation = Yup.object().shape({
        name: Yup.string()
            .required("Please enter a descriptive rule name"),
        sequenceNumber: Yup.number()
            .required("Please enter a priority number")
            .integer("Priority numbers should be positive integers")
            .min(-1, "Priority numbers should be positive integers"),
        activityRules: Yup.array()
            .of(Yup.object().shape({
                activityRuleType: Yup.string(),
                activeFrom: Yup.date()
                    .notRequired()
                    .typeError("Invalid date"),
                activeUntil: Yup.date()
                    .notRequired()
                    .typeError("Invalid date")
                    .when(
                        "activeFrom",
                        (activeFrom, schema) =>
                            activeFrom && schema.min(activeFrom, "'Until' must be later than 'From'")
                    ),
            })),
        conditions: Yup.object().shape({
            conditionType: Yup.string().required(),
            logicalOperator: Yup.string().required(),
            conditions: Yup.array()
                .of(Yup.object().shape({
                    conditionType: Yup.string().required(),
                    logicalOperator: Yup.string()
                        .when("conditionType", {
                            is: conditionTypes.conditionGroup,
                            then: (schema) => schema.required()
                        }),
                    conditions: Yup.array()
                        .of(Yup.object().shape({
                            conditionType: Yup.string().required(),
                            variableName: Yup.string().required("Please enter a valid variable name"),
                            conditionOperator: Yup.string().required(),
                            evaluationValues: evaluationValuesValidation
                        })),
                    variableName: Yup.string()
                        .when("conditionType", {
                            is: conditionTypes.condition,
                            then: (schema) => schema.required("Please enter a valid variable name")
                        }),
                    conditionOperator: Yup.string()
                        .when("conditionType", {
                            is: conditionTypes.condition,
                            then: (schema) => schema.required()
                        }),
                    evaluationValues: evaluationValuesValidation
                }))
        }),
        decision: Yup.array()
            .of(Yup.object().shape({
                productionSite: Yup.string().required("Please enter a valid factory code"),
                weight: Yup.number()
                    .required("Percentages should lie between 1% and 100%")
                    .min(1, "Percentages should lie between 1% and 100%")
                    .max(100, "Percentages should lie between 1% and 100%")
            }))
            .test(
                'percentage',
                'Percentage incorrect',
                (value, testContext) => {
                    const productionSites = (value ?? []) as IProductionSiteSelectionData[]
                    const sum = productionSites.map((site) => site.weight ?? 0).reduce(function (a: number, b: number) { return a + b; }, 0)
                    if (sum === 100) {
                        return true
                    }
                    const errors = productionSites.map((site, index) => testContext.createError({
                        message: `ProductionSite percentages add up to ${sum}% instead of 100%`,
                        path: `decision[${index}].weight`
                    }))
                    return new Yup.ValidationError(errors)
                }
            )
    });

    return (
        <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {header}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={editableRoutingRule}
                validationSchema={routingRuleValidation}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(routingRule, formikBag) => {
                    dispatch(actions.markRoutingRuleChanged(routingRule.id, false))
                    dispatch(actions.upsertRoutingRule(routingRule))
                    formikBag.setSubmitting(false)
                    routingRulesContext.uiEvents.hideRuleEditModal()
                }}>
                <Form className="form">
                    <Modal.Body>
                        <div className="form-row">
                            <div className="form-group col-2">
                                <label htmlFor="routing-rule-edit-sequenceNumber">Priority</label>
                                <Field
                                    component={Input}
                                    customFeedbackLabel={<div className="lighter">-1: add at end</div>}
                                    id="routing-rule-edit-sequenceNumber"
                                    name="sequenceNumber"
                                    type="number"
                                    min="-1"
                                />
                            </div>
                            <div className="form-group col-10">
                                <label htmlFor="routing-rule-edit-name">Rule Name</label>
                                <Field
                                    component={Input}
                                    id="routing-rule-edit-name"
                                    name="name"
                                    placeholder='e.g. "Christmas Sale BeNeLux Books to YPB"'
                                    type="string"
                                />
                            </div>
                        </div>
                        <RoutingRuleTriggerEditBlock />
                        <RoutingRuleDateRangeEditBlock />
                        <RoutingRuleFactoryEditBlock />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between width-set-100">
                            <button
                                type="button"
                                className="text-primary border-0 bg-transparent"
                                onClick={handleDeleteClick}>
                                <div className="d-flex text-danger mt-3">
                                    <u className="mr-1 mt-2">Delete Rule</u>
                                    <DeleteIcon color="#F64E60" />
                                </div>
                            </button>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    onClick={handleCancelClick}
                                    className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className='btn btn-primary-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                                    Save
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Formik >
        </Modal >
    );
}

export default RoutingRuleEditModal;