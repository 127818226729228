import { useState, useEffect, FC, ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { CarrierOverride } from "../../models/CarrierOverrideModels";

export const CarrierOverrideEditDialogHeader: FC<{ carrierOverride: CarrierOverride }> = ({
  carrierOverride,
}): ReactElement => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    let _title = carrierOverride.id ? "Edit Carrier Override" : "New Carrier Override";
    setTitle(_title);
  }, [carrierOverride]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
};