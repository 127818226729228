import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { RunTestsResponse } from "../models/RoutingRulesModels";

async function getRoutingRules(): Promise<string> {
  const backendConfig = await getConfig("backend");
  return axios
    .get<string>(`${backendConfig.url}/routingrules`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function getTestCases(): Promise<string> {
  const backendConfig = await getConfig("backend");
  return axios
    .get<string>(`${backendConfig.url}/routingrules/tests`, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => response.data);
}

async function putTestCases(newTestCases: string): Promise<string> {
  const backendConfig = await getConfig("backend");

  return axios
    .put<string>(`${backendConfig.url}/routingrules/tests`,
      newTestCases,
      {
        headers: {
          "Content-Type": "text/plain",
        }
      })
    .then((response) => response.data);
}

async function putRoutingRules(newRoutingRules: string): Promise<string> {
  const backendConfig = await getConfig("backend");

  return axios
    .put<string>(`${backendConfig.url}/routingrules`,
      newRoutingRules,
      {
        headers: {
          "Content-Type": "text/yaml",
        }
      })
    .then((response) => response.data);
}

async function postRunRoutingRulesTests(routingRules: string, routingRuleTests: string): Promise<RunTestsResponse> {
  const backendConfig = await getConfig("backend");

  let requestBody = {
    routingRules: routingRules,
    testCases: routingRuleTests
  }

  return axios
    .post<RunTestsResponse>(`${backendConfig.url}/RoutingRules/tests/run`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data);
}


export { getRoutingRules, getTestCases, putTestCases, putRoutingRules, postRunRoutingRulesTests }