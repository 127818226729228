// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input
} from "../../../_metronic/_partials/controls";
import { useProductsContext } from "../ProductsContext";

// TODO: article -> product
const ProductEditForm = ({
  saveArticle,
  article,
  actionsLoading,
  onHide,
}:any) => {
  const productsContext = useProductsContext();
  const ArticleEditSchema = Yup.object().shape({
    leadtime: Yup.number()
      .required("leadtime is required")
      .max(productsContext.maxLeadtimeValue, `Must be less or equal to ${productsContext.maxLeadtimeValue}`),
    extraLeadtime: Yup.number()
      .required("extra Leadtime is required")
      .max(productsContext.maxLeadtimeValue, `Must be less or equal to ${productsContext.maxLeadtimeValue}`),
  });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={article}
        validationSchema={ArticleEditSchema}
        onSubmit={(values) => {
          saveArticle(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Article Code */}
                  <div className="col-lg-4">
                    <Field
                      name="id"
                      component={Input}
                      placeholder="id"
                      label="Article Code"
                      disabled={true}
                    />
                  </div>
                  {/* description */}
                  <div className="col-lg-4">
                    <Field
                      name="desc"
                      component={Input}
                      placeholder="desc"
                      label="description"
                      disabled={true}
                    />
                  </div>
                  {/* leadtime */}
                  <div className="col-lg-4">
                    <Field
                      name="leadtime"
                      component={Input}
                      placeholder="leadtime"
                      label="leadtime"
                      type="number"
                    />
                  </div>
                </div>
                {/* extraLeadtime */}
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="extraLeadtime"
                      component={Input}
                      placeholder="extraLeadtime"
                      label="extra leadtime"
                      type="number"
                    />
                  </div>

                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
export {ProductEditForm};