import { Modal } from "react-bootstrap";
import ProductCategoryDialogHeader from "./ProductCategoryDialogHeader";
import ProductCategoryAddForm from "./ProductCategoryAddFrom";

const PackagingGroupAddDialog = ({ onHide, onSuccess, plant, editProductGroup, productGroupNameUniquenessCheck }: any) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <ProductCategoryDialogHeader />
      <ProductCategoryAddForm
        onHide={onHide}
        onSuccess={onSuccess}
        plant={plant}
        editProductGroup={editProductGroup}
        productGroupNameUniquenessCheck={productGroupNameUniquenessCheck}
      />
    </Modal>
  );
};

export default PackagingGroupAddDialog;
