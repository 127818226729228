import { shallowEqual, useSelector } from "react-redux";
import { ProductionSiteList } from './redux/ComponentsSlice';

export const usePlant = (): string => {
  const { plantCode } = useSelector(
    (state: any) => ({ plantCode: state.components.plantCode }),
    shallowEqual
  );
  return plantCode;
};

export const useProductionSites = (): ProductionSiteList => {
  const { productionSites } = useSelector(
    (state: any) => ({ productionSites: state.components.productionSites }),
    shallowEqual
  );  
  return productionSites;
};

export const useCarrierConfigurationSupport = () => {
  const { plantSupportsCarrierConfiguration } = useSelector(
    (state: any) => ({ plantSupportsCarrierConfiguration: state.components.plantSupportsCarrierConfiguration }),
    shallowEqual
  );
  return plantSupportsCarrierConfiguration;
};
