import { SortOrder } from "react-bootstrap-table-next";

export const ArticleStatusCssClasses = ["danger", "success", "info", ""];
export const ArticleStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ArticleTypeCssClasses = ["success", "primary", ""];
export const ArticleTypeTitles = ["Business", "Individual", ""];
//export const defaultSorted = [{ dataField: "id", order: "asc" }];
type Sort = {dataField:string, order:SortOrder}
export const defaultSorted:[Sort] = [{ dataField: "id", order: "asc" }];

export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    id: "",
    desc:"",
    leadtime:0,
    extraLeadtime:0
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
