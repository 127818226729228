import { Modal } from "react-bootstrap";
import DeliveryGroupEditForm from "./DeliveryGroupEditForm";
import { DeliveryGroupItemDialogHeader } from "./DeliveryGroupDialogHeader";

const DeliveryGroupEditDialog = ({
  onHide,
  onSuccess,
  deliveryGroup,
  selectedDeliveryGroupItem,
  plant,
}: any) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <DeliveryGroupItemDialogHeader />
      <DeliveryGroupEditForm
        onHide={onHide}
        onSuccess={onSuccess}
        deliveryGroup={deliveryGroup}
        selectedDeliveryGroupItem={selectedDeliveryGroupItem}
        plant={plant}
      />
    </Modal>
  );
};

export default DeliveryGroupEditDialog;
