import { ChangeEvent, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";
import { Checkbox } from "../../../components/Checkbox";
import { Carrier } from "../../../carrierOverrides/models/CarrierModels";
import {
  CarrierOverride,
  CarrierOverrideStatus,
} from "../../models/CarrierOverrideModels";
import * as iso from "iso-3166-1";

Yup.addMethod(Yup.date, "dateTimeNotInThePast", function (errorMessage) {
  return this.test("dateTimeNotInThePast", errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value !== undefined && value >= new Date()) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.date, "dateNotInThePast", function (errorMessage) {
  return this.test("dateNotInThePast", errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value !== undefined && value >= new Date(new Date().toDateString())) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "isIso3166", function (errorMessage) {
  return this.test("isIso3166", errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value !== undefined && iso.whereAlpha2(value) !== undefined) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "areZipCodeRangesValid", function (errorMessage) {
  return this.test("areZipCodeRangesValid", errorMessage, function (value) {
    const { path, createError } = this;
    // Valid zipcode range examples:
    //    1000 - 2000
    //    100a - 2000
    //    1000-2000 / 3000-4000
    //    ab100-cd200 ...
    const regExp =
      /((^\s*[a-zA-Z0-9]*\s*-\s*[a-zA-Z0-9]*\s*)(\s*\/\s*[a-zA-Z0-9]*\s*-\s*\d[a-zA-Z0-9]*\s*)*)$/;
    let isValid = value === "" || value === undefined || regExp.test(value);
    return isValid || createError({ path, message: errorMessage });
  });
});

const CarrierOverrideEditValidationSchema = Yup.object().shape({
  carrierName: Yup.string().required("Carrier is required"),
  overrideCarrierName: Yup.string().required("'Override Carrier' is required"),
  validFrom: Yup.date()
    .typeError("Invalid date")
    .required("'Valid From' is required"),
  validTo: Yup.date()
    .typeError("Invalid date")
    .required("'Valid To' is required")
    .dateTimeNotInThePast("'Valid To' must not be in the past")
    .when(
      "validFrom",
      (validFrom, schema) =>
        validFrom &&
        schema.min(validFrom, "'Valid To' must be later than 'Valid From'")
    ),
  marketingPromised: Yup.boolean(),
  promisedDateFrom: Yup.date().when("marketingPromised", {
    is: false,
    then: Yup.date()
      .typeError("Invalid date")
      .required("'Promised Date From' is required"),
    otherwise: Yup.date().default(() => undefined),
  }),
  promisedDateTo: Yup.date().when("marketingPromised", {
    is: false,
    then: Yup.date()
      .typeError("Invalid date")
      .required("'Promised Date To' is required")
      .dateNotInThePast("'Promised Date To' must not be in the past")
      .when(
        "promisedDateFrom",
        (promisedDateFrom, schema) =>
          promisedDateFrom &&
          schema.min(
            promisedDateFrom,
            "'Promised Date To' must be later than 'Promised Date From'"
          )
      ),
    otherwise: Yup.date().default(() => undefined),
  }),
  country: Yup.string().isIso3166("Invalid country code"),
  zipCodeRangesString: Yup.string().areZipCodeRangesValid(
    "Invalid zip code ranges. (Valid example: 1000-2000 / 3000-4000 ...)"
  ),
});

function renderCarrierOptions(
  carriers: Carrier[],
  selectedCarrierName: string
) {
  return (
    <>
      {carriers.map((carrier: Carrier) => (
        <option
          key={carrier.carrierName}
          value={carrier.carrierName}
          selected={carrier.carrierName === selectedCarrierName}
        >
          {carrier.carrierName}
        </option>
      ))}
    </>
  );
}

export function CarrierOverrideEditForm({
  saveCarrierOverride,
  carrierOverride,
  onHide,
  carriers,
  deleteCarrierOverride,
}: any) {
  const [carrierOverrideState, setCarrierOverrideState] =
    useState(carrierOverride);

  const isInActiveStatus: boolean =
    carrierOverride.status === CarrierOverrideStatus.Active;

  function fieldOnChangeHandler(propertyName: string, value: any) {
    setCarrierOverrideState({
      ...carrierOverrideState,
      [propertyName]: value,
    });
  }

  useEffect(() => {
    const selectedCarrierName = !carrierOverride.carrierName ? carriers[0].carrierName : carrierOverride.carrierName;
    const selectedOverrideCarrierName = !carrierOverride.overrideCarrierName ? carriers[0].carrierName : carrierOverride.overrideCarrierName;
    setCarrierOverrideState({
      ...carrierOverrideState,
      ["carrierName"]: selectedCarrierName,
      ["overrideCarrierName"]: selectedOverrideCarrierName,
    });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={carrierOverrideState}
        validationSchema={CarrierOverrideEditValidationSchema}
        onSubmit={(values: CarrierOverride) => {
          saveCarrierOverride(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label htmlFor="carrierName">Carrier</label>
                    <Field
                      as="Select"
                      name="carrierName"
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        fieldOnChangeHandler("carrierName", event.target.value)
                      }
                      value={carrierOverrideState.carrierName}
                      className="form-control"
                      disabled={isInActiveStatus}
                    >
                      {renderCarrierOptions(
                        carriers,
                        carrierOverrideState.carrierName
                      )}
                    </Field>
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="overrideCarrierName">
                      Override Carrier
                    </label>
                    <Field
                      as="Select"
                      name="overrideCarrierName"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        fieldOnChangeHandler(
                          "overrideCarrierName",
                          event.target.value
                        )
                      }
                      value={carrierOverrideState.overrideCarrierName}
                      className="form-control"
                    >
                      {renderCarrierOptions(
                        carriers,
                        carrierOverrideState.overrideCarrierName
                      )}
                    </Field>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label htmlFor="validFrom">Valid From</label>
                    <Field
                      name="validFrom"
                      component={Input}
                      type="datetime-local"
                      disabled={isInActiveStatus}
                      value={carrierOverrideState.validFrom}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        fieldOnChangeHandler("validFrom", event.target.value)
                      }
                    />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="validTo">Valid To</label>
                    <Field
                      name="validTo"
                      component={Input}
                      type="datetime-local"
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        fieldOnChangeHandler("validTo", event.target.value)
                      }
                      value={carrierOverrideState.validTo}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label htmlFor="marketingPromised">
                      Apply only for promised
                    </label>
                    <Field
                      name="marketingPromised"
                      type="checkbox"
                      component={Checkbox}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        fieldOnChangeHandler(
                          "marketingPromised",
                          event.target.checked
                        )
                      }
                      isSelected={carrierOverrideState.marketingPromised}
                      showFeedbackLabel={carrierOverrideState.marketingPromised}
                      feedbackLabel="*Promised dates will not be taken into consideration!"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label htmlFor="promisedDateFrom">Promised Date From</label>
                    <Field
                      name="promisedDateFrom"
                      component={Input}
                      disabled={
                        isInActiveStatus ||
                        carrierOverrideState.marketingPromised
                      }
                      type="date"
                      value={carrierOverrideState.promisedDateFrom}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        fieldOnChangeHandler("promisedDateFrom", event.target.value)
                      }
                    />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="promisedDateTo">Promised Date To</label>
                    <Field
                      name="promisedDateTo"
                      component={Input}
                      disabled={
                        isInActiveStatus ||
                        carrierOverrideState.marketingPromised
                      }
                      type="date"
                      value={carrierOverrideState.promisedDateTo}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        fieldOnChangeHandler("promisedDateTo", event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label htmlFor="country">Country Code</label>
                    <Field
                      name="country"
                      component={Input}
                      disabled={isInActiveStatus}
                      type="string"
                      placeholder="Pick one: NL, DE, GB, NO, SE, FR, ..."
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        fieldOnChangeHandler("country", event.target.value)
                      }
                      value={carrierOverrideState.country}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="description">Description</label>
                    <Field
                      name="description"
                      component={Input}
                      type="string"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        fieldOnChangeHandler("description", event.target.value)
                      }
                      value={carrierOverrideState.description}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label htmlFor="zipCodeRanges">Zip Code Ranges</label>
                    <Field
                      name="zipCodeRangesString"
                      component={Input}
                      disabled={isInActiveStatus}
                      type="string"
                      placeholder="Example: 1000-2000 / 3000-4000 ..."
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        fieldOnChangeHandler(
                          "zipCodeRangesString",
                          event.target.value
                        )
                      }
                      value={carrierOverrideState.zipCodeRangesString}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {carrierOverride.id && (
                    <div className="col-lg-6">
                      <label htmlFor="enabled">Enabled</label>
                      <> </>
                      <Field
                        name="enabled"
                        type="checkbox"
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          fieldOnChangeHandler("enabled", event.target.checked)
                        }
                        isSelected={carrierOverrideState.enabled}
                      />
                    </div>
                  )}
                </div>
                {isInActiveStatus && (
                  <div>
                    <label>
                      Properties that define which tickets this rule affects
                      need to stay constant once the rule activates.
                    </label>
                  </div>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {carrierOverride.id && (
                <button
                  type="button"
                  onClick={() => deleteCarrierOverride()}
                  className="btn btn-danger btn-elevate mr-auto"
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
                disabled={
                  carrierOverride.status === CarrierOverrideStatus.Expired
                }
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
