import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import {
  IProductCategories,
  IProductCategory,
  IProductCategoryResponse,
} from "../models/ProductGroups";

import {  IExportProductsAndModifiersCommand } from "../models/ProductGroup";

async function getProductCategories(
  plantCode: string
): Promise<IProductCategories> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IProductCategories>(
      `${backendConfig.url}/productcategories/${plantCode}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getProductCategories:", error);
    throw error;
  }
}

async function getProductCategoriesAsLookup(plant: string): Promise<{ key: string, description: string }[]> {
  return getProductCategories(plant).then((results: IProductCategories) => {
    return results.productCategories.map((item: IProductCategory) => {
      return {
        key: item.name, 
        description: item.name
      };
    });
  });
}


async function getProductCategory(
  plantCode: string,
  productCategory: string
): Promise<IProductCategoryResponse> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IProductCategoryResponse>(
      `${backendConfig.url}/productcategories/${plantCode}/${productCategory}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getProductCategory:", error);
    throw error;
  }
}

async function getProductCategoryItems(
  plantCode: string,
  productCategory: string
): Promise<IProductCategoryResponse> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.get<IProductCategoryResponse>(
      `${backendConfig.url}/articles/${plantCode}/${productCategory}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in getProductCategoryItems:", error);
    throw error;
  }
}

async function setProductCategory(
  plantCode: string,
  productCategory: string,
  productCategoryData: any
): Promise<IProductCategoryResponse> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.put<IProductCategoryResponse>(
      `${backendConfig.url}/productcategories/${plantCode}/${productCategory}`,
      productCategoryData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in setProductCategory:", error);
    throw error;
  }
}

async function deleteProductCategory(
  plantCode: string,
  productCategory: string
): Promise<IProductCategoryResponse> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.delete<IProductCategoryResponse>(
      `${backendConfig.url}/productcategories/${plantCode}/${productCategory}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in deleteProductCategory:", error);
    throw error;
  }
}

async function deleteProductCategoryItem(
  plantCode: string,
  productCategory: string,
  articleCode: string
): Promise<IProductCategoryResponse> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.delete<IProductCategoryResponse>(
      `${backendConfig.url}/articles/${plantCode}/${productCategory}/${articleCode}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in deleteProductCategoryItem:", error);
    throw error;
  }
}

async function setProductCategoryItem(
  plantCode: string,
  productCategory: string,
  productCategoryItem: any
): Promise<IProductCategoryResponse> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.put<IProductCategoryResponse>(
      `${backendConfig.url}/articles/${plantCode}/${productCategory}`,
      productCategoryItem,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in setProductCategoryItem", error);
    throw error;
  }
}

async function exportProductGroup(factory: string, productGroupName: string) {
  const backendConfig = await getConfig("backend");  
  return axios.get(
      `${backendConfig.url}/productgroups/${factory}/${productGroupName}/export/all`,
      {
        responseType: "blob",
      }
  ).then((response) => response.data);
}

async function exportProductsAndModifiers(factory: string, productGroupName: string, exportCommand: IExportProductsAndModifiersCommand) {
  const backendConfig = await getConfig("backend");  
  return axios.post(
      `${backendConfig.url}/productgroups/${factory}/${productGroupName}/export`,
      exportCommand,
      {
          headers: {
              "Content-Type": "application/json",
          },
          responseType: "blob",
      }
  ).then((response) => response.data);
}

async function importProductGroup(factory: string, productGroupName: string, file:any, dryRun:boolean) {
  const backendConfig = await getConfig("backend");  
  const formData = new FormData();
  formData.append("file", file);
  return axios.put(
    `${backendConfig.url}/productgroups/${factory}/${productGroupName}/import?dryRun=${dryRun}`, formData,{
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  ).then((response) => response.data);
}

export {
  getProductCategories,
  getProductCategoriesAsLookup,
  getProductCategory,
  getProductCategoryItems,
  setProductCategory,
  deleteProductCategory,
  setProductCategoryItem,
  deleteProductCategoryItem,
  exportProductGroup,
  exportProductsAndModifiers,
  importProductGroup,
};
