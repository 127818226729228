import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product, Schedule } from '../ProductsContext';
import { IDeleteScheduleResult, IExportScheduleResult, IProductGroup } from '../../models/LeadTimes';

type SliceProps = {
  productGroups: IProductGroup[];
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  entities: Product[];
  schedules: Schedule[];
  deleteScheduleResult?: IDeleteScheduleResult;
  exportScheduleResult?: IExportScheduleResult
  productForEdit?: Product;
  lastError: string | null;
  error: string | null;
  apiCallInProgress: boolean;
};

const initialProductsState: SliceProps = {
  productGroups: [],
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  schedules: [],
  deleteScheduleResult: undefined,
  exportScheduleResult: undefined,
  productForEdit: undefined,
  lastError: null,
  error: null,
  apiCallInProgress: false,
};
export const callTypes = {
  list: 'list',
  action: 'action',
  download: 'download',
};

export const productsSlice = createSlice({
  name: 'products',
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.download) {
        state.error = action.payload.error;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        // state.apiCallInProgress = true;
      } else if (action.payload.callType === callTypes.download) {
        state.apiCallInProgress = true;
      } else {
        state.apiCallInProgress = false;
      }
    },
    // getProductById
    productFetched: (state, action) => {
      state.actionsLoading = false;
      state.productForEdit = action.payload.productForEdit;
      state.error = null;
    },
    // findProducts
    productsFetched: (state, action) => {
      const { totalCount, entities, schedules } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.schedules = schedules || [];
      state.totalCount = totalCount;
    },
    // findProducts
    productGroupLeadTimesFetched: (state, action) => {
      const { leadTimesPerGroup } = action.payload;
      state.productGroups = leadTimesPerGroup;
      // TODO: figure out why if we don't reset this entities get set
      // state.entities = [];
      state.error = null;
    },
    // download lead times
    leadTimesDownloaded: (state) => {
      state.apiCallInProgress = false;
      state.error = null;
    },
    // updateProduct
    productUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.product.id) {
          console.log('***action.payload.product.id', action.payload.product.id)
          return action.payload.product;
        }
        return entity;
      });
    },
    // productsUpdateState
    productsLeadtimeUpdated: (
      state,
      action: PayloadAction<{ ids: string[]; leadtime: number }>
    ) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, leadtime } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.leadTime = leadtime;
        }
        return entity;
      });
    },
    // products Extra LeadTime Update State
    productsExtraLeadtimeUpdated: (
      state,
      action: PayloadAction<{ ids: string[]; extraLeadTime: number }>
    ) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, extraLeadTime } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.extraLeadTime = extraLeadTime;
        }
        return entity;
      });
    },
    // products Extra LeadTime Update State
    scheduleDeleted: (
      state,
      action: PayloadAction<{ scheduleId: string, deleteScheduleResult: IDeleteScheduleResult }>
    ) => {
      state.actionsLoading = false;
      state.error = null;
      const { scheduleId, deleteScheduleResult } = action.payload;
      state.schedules = state.schedules.filter((schedule) => schedule.id !== scheduleId);
      state.deleteScheduleResult = deleteScheduleResult;
    },
    deleteScheduleClosed: (
      state
    ) => {
      state.deleteScheduleResult = undefined;
    },
    scheduleExported: (
      state,
      action: PayloadAction<{ scheduleId: string, exportScheduleResult: IExportScheduleResult }>
    ) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
