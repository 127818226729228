import BootstrapTable from "react-bootstrap-table-next";
import { useState, useEffect } from "react";
import { Card, CardHeader } from "../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import isEmpty from "../../utils/isEmpty";
import { usePlant } from "../../components/PlantHook";
import BoxIcon from "../../svg/BoxIcon";
import TubeIcon from "../../svg/TubeIcon";
import { calculateProductDimensions, calculateProductDimensionsForExport } from "../api/CalculateProductDimensions";
import './TestProductDimensions.scss';
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import { getProductCategories } from "../../products/api/ProductsApi";
import moment from "moment";
import {
  IProductCategory,
} from "../../products/models/ProductGroups";
import SelectProductCombinations from "../../components/SelectProductCombinations";

const TestProductDimensions = (props: any) => {
  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [productGroupError, setProductGroupError] = useState<string | null>(null);
  const [showAppliedModifiers, setShowAppliedModifiers] = useState(false);
  const [selectedProductGroup, setSelectedProductGroup] = useState<{ key: string; description: string}| null>(null);
  const [productCombinations, setProductCombinations] = useState<any>(null);

  const plant = usePlant();

  const handleCalculate = async (values: any) => {
    setIsLoading(true);
    setError(null);

    values.plantCode = plant;

    try {
      const requestBody = {
        items: [productCombinations]
      };

      const response = await calculateProductDimensions(requestBody, values.plantCode);

      const sortedData = response.sort((a: any, b: any) => (a.shape + a.dimensionsAndWeight) > (b.shape + b.dimensionsAndWeight) ? 1 : -1)
      let prevRow: any = undefined;
      for (const row of sortedData) {
        if (prevRow !== undefined && row.shape === prevRow.shape && row.dimensionsAndWeight === prevRow.dimensionsAndWeight) {
          prevRow.rowSpan = (prevRow.rowSpan + 1) || 2;
          row.hidden = true;
        } else {
          prevRow = row;
        }
      }
      setData(null);
      setData(sortedData);
    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.Message || (error as Error).message || "An unknown error occurred.";

      console.error("Error in calculateProductDimensions:", error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async (values: any) => {
    setIsLoading(true);
    setError(null);

    values.plantCode = plant;

    try {
      const requestBody = {
        items: [productCombinations]
      };

      const response = await calculateProductDimensionsForExport(requestBody, values.plantCode);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
      link.download = "calculated-product-dimensions-" + dateTime + ".csv";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.Message || (error as Error).message || "An unknown error occurred.";

      console.error("Error in calculateProductDimensionsExport:", error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearOutput = () => {
    setData(null);
  };

  const mergeIfNeededAttrs = (cell: any, row: any, rowIndex: any, colIndex: any) => {
    if (row.rowSpan)
      return { rowSpan: row.rowSpan }
    else if (row.hidden)
      return { hidden: row.hidden }
  };

  const rowStyle = (row: any, rowIndex: number): React.CSSProperties => {
    if (row.rowSpan || !row.hidden)
      return { borderTop: '1px solid #EBEDF3' }
    else
      return {}
  };

  const columnsForOutput = [
    {
      dataField: "shape",
      text: "Shape",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: unknown, row: { shape: string }) => {
        if (row.shape.toLowerCase() === "box") {
          return <BoxIcon />;
        }
        if (row.shape.toLowerCase() === "tube") {
          return <TubeIcon />;
        }
        return row.shape;
      },
      attrs: mergeIfNeededAttrs,
    },
    {
      dataField: "dimensionsAndWeight",
      text: "Dimensions and weight",
      classes: "text-center",
      headerClasses: "text-center",
      attrs: mergeIfNeededAttrs
    },
    {
      dataField: "productOptions",
      text: "contents",
      headerClasses: "text-center",
      formatter: (cell: any, row: any) => (
        <span className="output-content"><span className="output-content-article-code">{row.productCode}</span> {row.options ? Object.entries(row.options).map(([key, value]) => (
          <span>{key}: <strong><>{value}</></strong></span>
        )) : null}</span>
      ),
      classes: "text-left",
    },
    ...(showAppliedModifiers ? [{
      dataField: "appliedModifiers",
      text: "Applied Modifiers",
      headerClasses: "text-center",
      formatter: (cell: any, row: any) => (
        <>{row.appliedModifiers ? row.appliedModifiers.map((appliedModifier: string) => (
          <div>{appliedModifier}</div>
        )) : null}</>
      ),
    }] : [])
  ];

  //initial state
  const [isUntouchedYet, setIsUntouchedYet] = useState<boolean>(true);

  //allValues
  const [productGroups, setProductGroups] = useState<IProductCategory[]>([]);
  const [productCodes, setProductCodes] = useState<Array<{ key: string; description: string }>>([]);

  const getProductGroupNames = (): Array<{key: string; description: string}> => {
    return productGroups.map(category => ({
      key: category.name,
      description: category.name,
    }));
  }

  const getProductCodeNamesForSelectedGroup = (): Array<{key: string; description: string}> => {
    const allProductCodes = productGroups.find(category => category.name === selectedProductGroup?.key)?.articles;
  
    return allProductCodes?.map(article => ({
      key: article.articleCode,
      description: article.articleCode,
    })) ?? [];
  }

  //Triggers only on first render
  useEffect(() => {
    async function fetchData() {
      try {
        const categories = await getProductCategories(plant);
        setProductGroups(categories.productCategories);
      } catch (error) {
        console.error('Error fetching product categories:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setProductGroupError(null);
    setProductCodes(getProductCodeNamesForSelectedGroup());
  }, [selectedProductGroup]);

  //Dropdown handlers
  const handleProductCategoryDropdownChange = (selectedProductGroup: { key: string; description: string }) => {
    setSelectedProductGroup(selectedProductGroup);
    if (isUntouchedYet) {
      setIsUntouchedYet(false);
    }
  };

  return (
    <>
      <Card {...props}>
        <CardHeader title="INPUT - Test product dimensions (options and modifiers)" {...props}></CardHeader>
        <div className="row">
          <div className="col-lg-12">
            <Formik
              initialValues={{
                plantCode: plant,
                articleCodes: "",
                optionItems: [
                  {
                    optionName: "",
                    isRequired: false,
                    optionValues: "",
                  },
                ],
              }}
              onSubmit={handleCalculate}
            >
              {({ values }) => (
                <Form>
                  <div className="form-group p-10">
                  <label className="h5">Product group:</label>
                    <div>
                      <SingleSelectDropdown
                        options={getProductGroupNames()}
                        onSelect={handleProductCategoryDropdownChange}
                        isLocked={false} />
                    </div>
                    <div className="text-left mt-2 text-danger">{productGroupError}</div>
                    <SelectProductCombinations
                      productCodes={productCodes}
                      onProductCombinationsChanged={setProductCombinations}
                      isLocked={isUntouchedYet}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-elevate m-10"
                  >
                    View Results
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-elevate m-10"
                    onClick={handleExport}
                  >
                    Download Results
                  </button>
                  <span>Output might be outdated within 10 minutes after modifying Packaging Configuration</span>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card>
      {isLoading && (
        <div className="text-center mt-5">
          <Spinner animation="border" role="status" variant="primary"></Spinner>
          <div>Calculating...</div>
        </div>
      )}
      <button
        onClick={handleClearOutput}
        className="btn btn-danger btn-elevate ml-10 mb-5"
        type="button"
      >
        Clear output
      </button>
      <div className="text-center mt-5 mb-10 text-danger">{error}</div>

      <Card {...props}>
        <CardHeader title="OUTPUT - Product Dimensions (for all article+options combinations)" {...props}> </CardHeader>
        {isEmpty(data) ? (
          <div className="text-center mt-10 p-10">
            <p>
              No product codes and options have been input yet. Please enter what you'd like to check: product codes and options.
            </p>
          </div>
        ) : (
          <>
            <h5 className="m-5">
              <input
                type="checkbox"
                checked={showAppliedModifiers}
                onChange={() => setShowAppliedModifiers(!showAppliedModifiers)}
                id="showAppliedModifiers"
              />
              <label htmlFor="showAppliedModifiers">Show Applied Modifiers</label>
            </h5>
            <BootstrapTable
              wrapperClasses="table-responsive column-width-control"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={true}
              striped={false}
              remote
              keyField="productCode"
              data={data || []}
              columns={columnsForOutput}
              rowStyle={rowStyle}
            ></BootstrapTable>
          </>
        )}
      </Card>
    </>
  )
}

export default TestProductDimensions;