import React, { useMemo } from "react";
import { useProductsContext } from "../ProductsContext";


const ProductsGrouping = () => {
  // Products UI Context
  const productsContext = useProductsContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsContext.ids,
      setIds: productsContext.setIds,
      openUpdateArticlesLeadtimeBatchDialog: productsContext.openUpdateArticlesLeadtimeBatchDialog,
      openUpdateArticlesExtraLeadtimeBatchDialog: productsContext.openUpdateArticlesExtraLeadtimeBatchDialog
    };
  }, [productsContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{productsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>          
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={productsUIProps.openUpdateArticlesLeadtimeBatchDialog}
              >
                <i className="fa fa-sync-alt"></i> Batch Update LeadTime
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-info font-weight-bolder font-size-sm"
                onClick={productsUIProps.openUpdateArticlesExtraLeadtimeBatchDialog}
              >
                <i className="fa fa-sync-alt"></i> Batch Update Extra LeadTime
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductsGrouping;