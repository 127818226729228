import { createSlice } from "@reduxjs/toolkit";
import { IAlertModel } from "../models/PackagingGroups";

type PackagingGroupState = {   
  alert?: IAlertModel,
  packagingGroup: any,
  apiCallInProgress: boolean
}

const initialPackagingGroupState : PackagingGroupState = {  
  alert: undefined,
  packagingGroup: undefined,
  apiCallInProgress: false
};

export const packagingGroupSlice = createSlice({
  name: "packagingGroup",
  initialState: initialPackagingGroupState,
  reducers: {
    startCall: (state) => {      
      state.alert = undefined;      
      state.packagingGroup = undefined;
      state.apiCallInProgress = true;
    },
    exported: (state, action) => {
      const { successAlert } = action.payload;      
      state.alert = successAlert;
      state.apiCallInProgress = false;
    },
    catchError: (state, action) => {      
      const { error } = action.payload;      
      state.alert = error;
      state.packagingGroup = undefined;
      state.apiCallInProgress = false;
    },
    alertDismissed: (state) => {      
      state.alert = undefined;
    },
  },
});
