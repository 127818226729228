function createDictionaryFromString(
  inputString: string,
  delimiter: string = ","
): { [key: string]: string } {
  const keyValuePairs = inputString.split(delimiter);
  const result: { [key: string]: string } = {};

  for (const pair of keyValuePairs) {
    const [key, value] = pair.split(":").map((s) => s.trim());
    result[key] = value;
  }
  return result;
}

export default createDictionaryFromString;
