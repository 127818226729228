import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import {
  getProductCategories,
  setProductCategoryItem,
} from "../../../api/ProductsApi";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {  getCatalogProductCodes } from "../../../api/ProductCatalogApi";
import { IProductCatalogLookupDto } from "../../../models/ProductCatalog";

const validationSchema = Yup.object().shape({
  lengthMm: Yup.number()
    .required("Length (mm) is required")
    .min(0, "Length must be greater than or equal to 0"),
  breadthMm: Yup.number()
    .required("Breadth (mm) is required")
    .min(0, "Breadth must be greater than or equal to 0"),
  heightMm: Yup.number()
    .required("Height (mm) is required")
    .min(0, "Height must be greater than or equal to 0"),
  weightGr: Yup.number()
    .required("Weight (gr) is required")
    .min(0, "Weight must be greater than or equal to 0"),
  articleCode: Yup.string().required("Product Code is required"),
});

const ProductCategoryItemAddForm = ({
  onHide,
  onSuccess,
  plant,
  productGroup,
  productType,
  singleProduct,
}: any) => {

  //Single select
  //If we want to use an initial value, ensure to initialise useState with the initial
  const [selectedProductCode, setSelectedProductCode] = useState<{ key: string; description: string } | null>(null);
  const [papPairs, setPapPairs] = useState<Array<{ key: string; description: string }>>([]);

  //Single select
  const handleDropdownChange = (selectedProductCode: { key: string; description: string }) => {
    setSelectedProductCode(selectedProductCode);
  };

  useEffect(() => {
    loadProductCodes(productType).then(setPapPairs).catch(error => console.error('Failed to load product codes:', error));
  }, []);

  const loadProductCodes = (productType: string): Promise<{ key: string, description: string }[]> => {
    return getCatalogProductCodes(productType).then((results: IProductCatalogLookupDto[]) => {
      return results.map((item: IProductCatalogLookupDto) => {
        return {
          key: item.key,  // Using the 'key' from the item directly
          description: item.description,
        };
      });
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          lengthMm: singleProduct?.size?.lengthMm,
          breadthMm: singleProduct?.size?.breadthMm,
          heightMm: singleProduct?.size?.heightMm,
          weightGr: singleProduct?.weightGr,
          articleCode: singleProduct?.articleCode,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setStatus }) => {
          try {
            await setProductCategoryItem(plant, productGroup, {
              articles: [
                {
                  size: {
                    lengthMm: values.lengthMm,
                    breadthMm: values.breadthMm,
                    heightMm: values.heightMm,
                    weightGr: values.weightGr,
                  },
                  articleCode: values.articleCode,
                },
              ],
            });
            setStatus("success");
            setTimeout(() => {
              onHide(); // Close the modal after a successful submission
              onSuccess();
            }, 1500);
            getProductCategories(plant);
          } catch (error) {
            setStatus("error");
          }
        }}
      >
        {({ handleSubmit, handleChange, status }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group">
                  <div>
                  <Field name="articleCode" as="select" 
                   placeholder="ex. PAP_333"
                   label="(Product Category Item)"
                   onChange={handleChange}
                   disabled={!!singleProduct}
                   >
                    <option>(select product code)</option>
                    {papPairs.map((dto: {key: string, description: string}) => (
                      <option value={dto.key}>{dto.description}</option>
                    )
                    )}
                  </Field>
                  <div className="text-danger">
                    {productType === undefined && (
                      <span>No product codes found. Please specify a Product Type for this group (use Pencil icon from Groups Overview page)</span>
                    )}
                    <ErrorMessage
                      name="articleCode"
                      />
                  </div>
                </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="lengthMm"
                      component={Input}
                      placeholder="Length(mm)"
                      label="Length(mm)"
                      type="number"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="breadthMm"
                      component={Input}
                      placeholder="Width(mm)"
                      label="Width(mm)"
                      type="number"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="heightMm"
                      component={Input}
                      placeholder="Height(mm)"
                      label="Height(mm)"
                      type="number"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="weightGr"
                      component={Input}
                      placeholder="weightGr"
                      label="weightGr"
                      type="number"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            {status === "success" && (
              <div className="text-success text-center mt-3 p-10">
                Saved successfully!
              </div>
            )}
            {status === "error" && (
              <div className="text-danger text-center mt-3 p-10">
                An error occurred.
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default ProductCategoryItemAddForm;
