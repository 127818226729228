import { useState, useEffect, useCallback } from "react";
import { getProductCategories, getProductCategory } from "../api/ProductsApi";
import {
  IProductCategory,
  IProductCategoryResponse,
} from "../models/ProductGroups";

const useFetchProductCategories = (plant: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [productCategories, setProductCategories] = useState<
    IProductCategory[] | null
  >(null);
  const [error, setError] = useState(false);

  const fetchProductCategories = useCallback(async () => {
    try {
      const data = await getProductCategories(plant);
      setProductCategories(data.productCategories);
      setIsLoading(false);
      setError(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(true);
    }
  }, [plant]);

  useEffect(() => {
    fetchProductCategories();
  }, [fetchProductCategories, plant]);

  const refetchProductCategories = async () => {
    setIsLoading(true);
    setError(false);
    try {
      await fetchProductCategories();
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (error) {
    return {
      isLoading: false,
      productCategories: null,
      error,
      refetchProductCategories,
    };
  }

  return { isLoading, productCategories, refetchProductCategories };
};

const useFetchProductCategory = (plant: string, product: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [productCategory, setProductCategory] =
    useState<IProductCategoryResponse | null>(null);

  const fetchProductCategory = useCallback(async () => {
    try {
      const data = await getProductCategory(plant, product);
      setProductCategory(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [plant, product]);

  useEffect(() => {
    fetchProductCategory();
  }, [fetchProductCategory]);
  const refetchProductCategory = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchProductCategory();
    } catch (error) {
      console.error(error);
    }
  }, [fetchProductCategory]); // Wrap refetchProductCategory in useCallback to avoid unecessary rerenders

  return { isLoading, productCategory, refetchProductCategory };
};

export { useFetchProductCategories, useFetchProductCategory };
