import { useState, useEffect, useCallback } from "react";
import { getPackagingGroup, getPackagingGroups } from "../api/PackagingApi";
import { IPackagingGroup, IPackagingItem } from "../models/PackagingGroups";

const useFetchPackagingGroups = (plant: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [packagingGroups, setPackagingGroups] = useState<
    IPackagingGroup[] | null
  >(null);
  const [error, setError] = useState(false);

  const fetchPackagingGroups = useCallback(async () => {
    try {
      const data = await getPackagingGroups(plant);
      setPackagingGroups(data.packagingGroups);
      setIsLoading(false);
      setError(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(true);
    }
  }, [plant]);

  useEffect(() => {
    fetchPackagingGroups();
  }, [fetchPackagingGroups, plant]);

  const refetchPackagingGroups = async () => {
    setIsLoading(true);
    setError(false);
    try {
      await fetchPackagingGroups();
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (error) {
    return {
      isLoading: false,
      packagingGroups: null,
      error,
      refetchPackagingGroups,
    };
  }

  return { isLoading, packagingGroups, refetchPackagingGroups };
};

const useFetchPackagingGroup = (plant: string, packagingItem: string) => {
  const [packagingGroupItems, setPackagingGroupItems] = useState<
    IPackagingItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPackagingGroup = useCallback(async () => {
    try {
      const data = await getPackagingGroup(plant, packagingItem);
      setPackagingGroupItems(data.packagingItems);
      setIsLoading(false);
      setError(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(true);
    }
  }, [plant, packagingItem]);

  useEffect(() => {
    fetchPackagingGroup();
  }, [fetchPackagingGroup, plant]);

  const refetchPackagingGroup = async () => {
    setIsLoading(true);
    setError(false);
    try {
      await fetchPackagingGroup();
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (error) {
    return {
      isLoading: false,
      packagingGroupItems: null,
      error,
      refetchPackagingGroup,
    };
  }

  return { isLoading, packagingGroupItems, refetchPackagingGroup };
};

export { useFetchPackagingGroups, useFetchPackagingGroup };
