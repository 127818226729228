import { IAlertModel } from "../models/ProductGroup";
import { productGroupSlice } from "./ProductGroupSlice";
import { exportProductGroup as exportProductGroupApi, exportProductsAndModifiers as exportProductsAndModifiersApi, importProductGroup as importProductGroupApi } from "../api/ProductsApi";
import moment from "moment";

const { actions } = productGroupSlice;
const errorTitle = "Error while calling API";

export const showAlert = (error: IAlertModel) => (dispatch: (args: { payload: any; type: string }) => void) => {  
  dispatch(actions.catchError({ error }));
};
//(state: any, action: any)
export const dismissAlert = () => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.alertDismissed());
};

export const exportProductGroup = (factory: string, productGroupName: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const response = await exportProductGroupApi(factory, productGroupName);    
    const successAlert = {
      title: "Export Successful",
      message: "Product group has been exported successfully.",
      variant: "success",
    } as IAlertModel;
    dispatch(actions.exported({ successAlert }));
    const url = window.URL.createObjectURL(new Blob([response]));    
    const link = document.createElement("a");
    link.href = url;
    const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
    link.download = `productgroup-${factory}-${productGroupName}-${dateTime}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error(err);
    const error = {
      title: errorTitle,
      message: "Failed to export product group",
      variant: "danger",
    } as IAlertModel;
    dispatch(actions.catchError({ error }));
  }
};

export const exportProductsAndModifiers = (factory: string, productGroupName: string, commandData: any) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const response = await exportProductsAndModifiersApi(factory, productGroupName, commandData);         
    const successAlert = {
      title: "Export Successful",
      message: "Products and/or modifiers have been exported successfully.",
      variant: "success",
    } as IAlertModel;
    dispatch(actions.exported({ successAlert }));
    const url = window.URL.createObjectURL(new Blob([response]));    
    const link = document.createElement("a");
    link.href = url;
    const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
    link.download = `productgroup-${factory}-${productGroupName}-${dateTime}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error(err);
    const error = {
      title: errorTitle,
      message: "Failed to export products and modifiers",
      variant: "danger",
    } as IAlertModel;
    dispatch(actions.catchError({ error }));
  }
};

export const importProductGroup = async (factory: string, productGroupName: string, file: any, dryRun: boolean): Promise<{ articlesCount: number, productModifiersCount: number } | undefined> => {
  try {
    var uploadResult = await importProductGroupApi(factory, productGroupName,file, dryRun);
    return uploadResult;
  } catch (err) {
    console.error(err);
  }
};
