import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { carrierOverrideSlice } from "../app/carrierOverrides/redux/CarrierOverrideSlice";
import { componentsSlice } from "../app/components/redux/ComponentsSlice";
import { productsSlice } from "../app/leadTimes/redux/ProductsSlice";
import { routingRulesSlice } from "../app/routingRules/redux/RoutingRulesSlice";
import { skuMappingsSlice } from "../app/skuMappings/redux/SkuMappingsSlice";
import { productGroupSlice } from "../app/products/redux/ProductGroupSlice";
import { packagingGroupSlice } from "../app/packaging/redux/PackagingGroupSlice";
import { routingRulesSlice as newRoutingRulesSlice } from "../app/newRoutingRules/redux/RoutingRulesSlice";

export const rootReducer = combineReducers({
  products: productsSlice.reducer,
  carrierOverrides: carrierOverrideSlice.reducer,
  components: componentsSlice.reducer,
  routingRules: routingRulesSlice.reducer,
  skuMappings: skuMappingsSlice.reducer,
  productGroup: productGroupSlice.reducer,
  packagingGroup: packagingGroupSlice.reducer,
  routingRulesData: newRoutingRulesSlice.reducer
});

export function* rootSaga() {
  yield all([]);
}
