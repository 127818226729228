import { IAlertModel } from "../models/PackagingGroups";
import { packagingGroupSlice } from "./PackagingGroupSlice";
import { exportPackagingGroup as exportPackagingGroupApi, exportPackagingGroupItems as exportPackagingGroupItemsApi, importPackagingGroup as importPackagingGroupApi } from "../api/PackagingApi";
import moment from "moment";

const { actions } = packagingGroupSlice;
const errorTitle = "Error while calling API";

export const showAlert = (error: IAlertModel) => (dispatch: (args: { payload: any; type: string }) => void) => {  
  dispatch(actions.catchError({ error }));
};

export const dismissAlert = () => (dispatch: (args: { payload: any; type: string }) => void) => {
  dispatch(actions.alertDismissed());
};

export const exportPackagingGroup = (factory: string, packagingGroupName: string) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const response = await exportPackagingGroupApi(factory, packagingGroupName);    
    const successAlert = {
      title: "Export Successful",
      message: "Packaging group has been exported successfully.",
      variant: "success",
    } as IAlertModel;
    dispatch(actions.exported({ successAlert }));
    const url = window.URL.createObjectURL(new Blob([response]));    
    const link = document.createElement("a");
    link.href = url;
    const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
    link.download = `packaginggroup-${factory}-${packagingGroupName}-${dateTime}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error(err);
    const error = {
      title: errorTitle,
      message: "Failed to export packaging group",
      variant: "danger",
    } as IAlertModel;
    dispatch(actions.catchError({ error }));
  }
};

export const exportPackagingGroupItems = (factory: string, packagingGroupName: string, commandData: any) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const response = await exportPackagingGroupItemsApi(factory, packagingGroupName, commandData);         
    const successAlert = {
      title: "Export Successful",
      message: "Packaging group items have been exported successfully.",
      variant: "success",
    } as IAlertModel;
    dispatch(actions.exported({ successAlert }));
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    const dateTime = moment().format('YYYY-MM-DD_HH-mm-ss');
    link.download = `packaginggroup-${factory}-${packagingGroupName}-${dateTime}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error(err);
    const error = {
      title: errorTitle,
      message: "Failed to export packaging group items",
      variant: "danger",
    } as IAlertModel;
    dispatch(actions.catchError({ error }));
  }
};

export const importPackagingGroup = async (factory: string, packagingGroupName: string, file: any, dryRun: boolean): Promise<{ packagingGroupItemsCount: number } | undefined> => {
  try {
    var uploadResult = await importPackagingGroupApi(factory, packagingGroupName,file, dryRun);
    return uploadResult;
  } catch (err) {
    console.error(err);
  }
};
