import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { CardActions } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { IDeliveryGroup } from "../models/DeliveryGroup";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFetchDeliveryGroups } from "../hooks/useFetchDeliveryGroupsData";
import { usePlant } from "../../components/PlantHook";
import isEmpty from "../../utils/isEmpty";
import EditIcon from "../../svg/EditIcon";
import { useState } from "react";
import DeliveryGroupAddDialog from "../components/DeliveryGroupAddDialog";

const BundleGroupPage = (props: any) => {
  const history = useHistory();

  const plant = usePlant();

  const { isLoading, deliveryGroups, error, refetchDeliveryGroups } =
    useFetchDeliveryGroups(plant);

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const handleAddNewDeliveryGroupClick = (x: any) => {
    setAddDialogOpen(true);
  };

  const handleFormCancelClick = () => {
    setAddDialogOpen(false);
  };

  const handleFormSubmitSuccess = async () => {
    try {
      // Call the refetch function to update the delivery group data
      refetchDeliveryGroups();
    } catch (error) {
      console.error(
        "Error in handleFormSubmitSuccess on DeliveryGroupPage.tsx :",
        error
      );
    }
  };

  const handleEditClick = (row: { group: string }) => {
    const newUrl = `/singlebundlegroup/${row.group}`;
    history.push(newUrl);
  };

  const columns: ColumnDescription<any, any>[] = [
    {
      dataField: "group",
      text: "Group",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "numberOfItemsInGroup",
      text: "Number of Items",
      headerClasses: "text-center",
      classes: "text-center",
    },
    {
      dataField: "edit",
      text: "View",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: any, row: { group: string }) => {
        if (row.group && row.group !== "") {
          return (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => handleEditClick(row)}
            >
              View
            </button>
          );
        }
        return "";
      }
    }
  ];

  const flattenedDeliveryGroups = deliveryGroups?.map(
    (deliveryGroup: IDeliveryGroup) => {
      return {
        group: deliveryGroup.name,
        numberOfItemsInGroup:
          deliveryGroup.items && deliveryGroup.items.length,
      };
    }
  );

  if (error) {
    return (
      <Card {...props}>
        <CardHeader title="Delivery Groups" {...props}>
          <CardActions></CardActions>
        </CardHeader>
        <CardBody {...props}>
          <div className="text-center">
            <p>Oops! Something went wrong. Please try again later.</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <Card {...props}>
        <CardHeader title="Bundle Groups" {...props}>
          <CardActions></CardActions>
        </CardHeader>
        <CardBody {...props}>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p className="mt-2">Loading...</p>
            </div>
          ) : isEmpty(deliveryGroups) ? (
            <div className="text-center">
              <p>
                Oops! The factory is not yet configured. Please add the
                necessary data.
              </p>
            </div>
          ) : (
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              striped={true}
              keyField="description"
              data={flattenedDeliveryGroups || []}
              columns={columns}
              defaultSorted={[{ dataField: "description", order: "asc" }]}
            ></BootstrapTable>
          )}
          {/* <button
            title="Add Delivery Group"
            className="btn btn-light btn-hover-primary btn-sm mx-4"
            onClick={handleAddNewDeliveryGroupClick}
            style={{
              borderRadius: "12px",
              border: "2px solid #38BFC4",
              backgroundColor: "white",
              color: "#38BFC4",
              display: "flex",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <span className="svg-icon svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                title="New product group"
                className="svg-icon-md"
              />
            </span>
            <span style={{ marginLeft: "8px" }}>Add Delivery Group</span>
          </button> */}
          {isAddDialogOpen && (
            <DeliveryGroupAddDialog
              onSuccess={handleFormSubmitSuccess}
              onHide={handleFormCancelClick}
              plant={plant}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default BundleGroupPage;
