import { debounce } from "lodash";
import { ChangeEvent, FC, ReactElement, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../redux/RoutingRulesActions";

export const RoutingRulesEdit: FC<{
  currentRules: string;
  canSaveRules: boolean;
  saveRulesHandler: () => void;
}> = ({ currentRules, canSaveRules, saveRulesHandler }): ReactElement => {
  const dispatch = useDispatch();
  const textChangedHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let routingRules = event.target.value;
    dispatch(actions.changeRoutingRules(routingRules));
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(textChangedHandler, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => debouncedChangeHandler.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <h4>Routing Rules</h4>
          </div>
          <div className="col-lg-2">
            <button
              type="submit"
              onClick={saveRulesHandler}
              className="btn btn-primary btn-elevate"
              disabled={!canSaveRules}>
              Save rules
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <textarea
              rows={15}
              cols={144}
              style={{
                fontFamily: "Courier",
                font: "Courier",
                wordWrap: "normal",
                whiteSpace: "pre",
              }}
              id="currentRules"
              defaultValue={currentRules}
              onChange={debouncedChangeHandler}
              key={"currentRules_" + (10000 + Math.random() * (1000000 - 10000))}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};