import { Modal } from "react-bootstrap";
import PackagingGroupEditForm from "./PackagingGroupEditForm";
import { PackagingItemDialogHeader } from "./PackagingGroupDialogHeader";
import { IPackagingItem } from "../models/PackagingGroups";

const PackagingGroupEditDialog = ({
  onHide,
  onSuccess,
  packagingGroup,
  selectedPackagingItem,
  plant,
}: {onHide:any,
onSuccess:any,
packagingGroup: any,
selectedPackagingItem: IPackagingItem|null|undefined,
plant: any}) => {
  return (
    <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg">
      <PackagingItemDialogHeader itemName={selectedPackagingItem?.name}/>
      <PackagingGroupEditForm
        onHide={onHide}
        onSuccess={onSuccess}
        packagingGroup={packagingGroup}
        selectedPackagingItem={selectedPackagingItem}
        plant={plant}
      />
    </Modal>
  );
};

export default PackagingGroupEditDialog;
