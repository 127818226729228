import BootstrapTable from "react-bootstrap-table-next";
import { useState, useEffect } from "react";
import { Card, CardHeader } from "../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { toZonedTime } from "date-fns-tz";
import isEmpty from "../../utils/isEmpty";
import { usePlant } from "../../components/PlantHook";
import './TestLeadTimesPage.scss';
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import SelectProductCombinations from "../../components/SelectProductCombinations";
import { calculateProductLeadTimes, calculateProductLeadTimesForExport, getLeadTimes } from "../api/ProductLeadTimeApi";
import { IProductGroup } from "../../models/LeadTimes";
import { GetPlants } from "../../components/PlantSelectorDropdown";
import { format } from 'date-fns';

const TestLeadTimesPage = (props: any) => {
  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedProductGroup, setSelectedProductGroup] = useState<{ key: string; description: string } | null>(null);
  const [productCombinations, setProductCombinations] = useState<any>(null);
  const [requiredOptions, setRequiredOptions] = useState<Array<string>>([]);

  const plant = usePlant();
  let plants = GetPlants();

  const currentPlant = plants.find((x) => x.plantCode === plant);
  const plantTimeNow = currentPlant ? toZonedTime(new Date(), currentPlant.timeZoneLocation) : new Date();

  const [effectiveDate, setEffectiveDate] = useState<any>(plantTimeNow);

  const handleCalculate = async (values: any) => {
    setIsLoading(true);
    setError(null);

    values.plantCode = plant;

    try {
      const requestBody = {
        effectiveDate: effectiveDate,
        testLeadTimeCombination: productCombinations
      };

      const response = await calculateProductLeadTimes(requestBody, values.plantCode);

      const sortedData = response.sort((a: any, b: any) => a.leadTimeValue > b.leadTimeValue ? 1 : -1)
      let prevRow: any = undefined;
      for (const row of sortedData) {
        if (prevRow !== undefined && row.leadTimeValue === prevRow.leadTimeValue) {
          prevRow.rowSpan = (prevRow.rowSpan + 1) || 2;
          row.hidden = true;
        } else {
          prevRow = row;
        }
      }
      setData(null);
      setData(sortedData);
    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.Message || (error as Error).message || "An unknown error occurred.";

      console.error("Error in calculateProductLeadTimes:", error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async (values: any) => {
    setIsLoading(true);
    setError(null);

    values.plantCode = plant;

    try {
      const requestBody = {
        effectiveDate: effectiveDate,
        testLeadTimeCombination: productCombinations
      };

      const response = await calculateProductLeadTimesForExport(requestBody, values.plantCode);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = "calculated-leadtimes-on-" + effectiveDate + ".csv";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.Message || (error as Error).message || "An unknown error occurred.";

      console.error("Error in calculateProductLeadTimesForExport:", error);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearOutput = () => {
    setData(null);
  };

  const mergeIfNeededAttrs = (cell: any, row: any, rowIndex: any, colIndex: any) => {
    if (row.rowSpan)
      return { rowSpan: row.rowSpan }
    else if (row.hidden)
      return { hidden: row.hidden }
  };

  const rowStyle = (row: any, rowIndex: number): React.CSSProperties => {
    if (row.rowSpan || !row.hidden)
      return { borderTop: '1px solid #EBEDF3' }
    else
      return {}
  };

  const columnsForOutput = [
    {
      dataField: "leadTimeValue",
      text: "Lead Time (days)",
      classes: "text-center lead-time-value",
      headerClasses: "text-center lead-time-header",
      attrs: mergeIfNeededAttrs
    },
    {
      dataField: "productOptions",
      text: "Combinations",
      headerClasses: "text-center",
      formatter: (cell: any, row: any) => {
        const invalid = requiredOptions.some(r => !Object.entries(row.options).some(([key, value]) => r === key));
        return (
          <span
            className={`output-content${invalid ? " invalid-output-content" : ""}`}
            title={invalid ? "Invalid combination!" : ""}
          >
            <span className="output-content-article-code">{row.productCode}</span>
            {row.options && Object.entries(row.options).map(([key, value]) => (
              <span key={key} className="output-content-option">
                {key}:<strong>{value}</strong>
              </span>
            ))}
            {invalid && <span className="output-content-invalid-text">⚠️</span>}
          </span>
        );
      },
      classes: "text-left combination-column",
    },
  ];

  //initial state
  const [isUntouchedYet, setIsUntouchedYet] = useState<boolean>(true);

  //allValues
  const [productGroups, setProductGroups] = useState<IProductGroup[]>([]);
  const [productCodes, setProductCodes] = useState<Array<{ key: string; description: string }>>([]);

  const getProductGroupNames = (): Array<{ key: string; description: string }> => {
    return productGroups.map(productGroup => ({
      key: productGroup.name,
      description: productGroup.name,
    }));
  }

  const getProductCodeNamesForSelectedGroup = (): Array<{ key: string; description: string }> => {
    console.log(selectedProductGroup?.key);
    const allProductCodes = productGroups.find(productGroup => productGroup.name === selectedProductGroup?.key)?.leadTimes;
    console.log(allProductCodes);

    return allProductCodes?.map(product => ({
      key: product.productCode,
      description: product.description,
    })) ?? [];
  }

  //Triggers only on first render
  useEffect(() => {
    async function fetchData() {
      try {
        const groups = await getLeadTimes(plant);
        const leadTimesPerGroup = groups.leadTimesPerGroup.filter(g => g.leadTimes?.length).sort((a, b) => a.name.localeCompare(b.name))
        setProductGroups(leadTimesPerGroup);
      } catch (error) {
        console.error('Error fetching product groups:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setProductCodes(getProductCodeNamesForSelectedGroup());
  }, [selectedProductGroup]);

  //Dropdown handlers
  const handleProductCategoryDropdownChange = (selectedProductGroup: { key: string; description: string }) => {
    setSelectedProductGroup(selectedProductGroup);
    if (isUntouchedYet) {
      setIsUntouchedYet(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Card {...props}>
            <CardHeader title="Test Lead Times: INPUT" {...props}></CardHeader>
            <Formik
              initialValues={{
                plantCode: plant,
                articleCodes: "",
                optionItems: [
                  {
                    optionName: "",
                    isRequired: false,
                    optionValues: "",
                  },
                ],
              }}
              onSubmit={handleCalculate}
            >
              {({ values }) => (
                <Form>
                  <div className="form-group p-10">
                    <label className="h5">Effective date and time:</label>
                    <div>
                      <DatePicker
                        className='form-control form-control-sm'
                        showIcon
                        todayButton='Today'
                        selected={effectiveDate}
                        onChange={setEffectiveDate}
                        showTimeSelect
                        dateFormat='d/MM/yyyy h:mm aa'
                        placeholderText="Select a start date"
                        required
                      />
                      <label className="ml-1">in {plant} time zone</label>
                    </div>
                    <label className="h5 mt-7">Lead Time group:</label>
                    <div>
                      <SingleSelectDropdown
                        options={getProductGroupNames()}
                        onSelect={handleProductCategoryDropdownChange}
                        isLocked={false} />
                    </div>
                    <div className="mt-2">
                      <SelectProductCombinations
                        productCodes={productCodes}
                        excludedProductOptions={["Pages", "CoverText"]}
                        onProductCombinationsChanged={setProductCombinations}
                        setRequiredOptions={setRequiredOptions}
                        isLocked={isUntouchedYet}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate m-1"
                    >
                      View Results
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-elevate m-1"
                      onClick={handleExport}
                    >
                      Download Results
                    </button>
                    <button
                      onClick={handleClearOutput}
                      className="btn btn-danger btn-elevate m-1"
                      type="button"
                    >
                      Clear output
                    </button>
                    <div className="text-center mt-5 mb-10 text-danger">{error}</div>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
        <div className="col-md-6">
          <Card {...props}>
            <CardHeader title={`OUTPUT (as of ${format(effectiveDate, 'd/MM/yyyy h:mm aa')})`} {...props}> </CardHeader>
            {isLoading && (
              <div className="text-center mt-5">
                <Spinner animation="border" role="status" variant="primary"></Spinner>
                <div>Calculating...</div>
              </div>
            )}
            {isEmpty(data) ? (
              <div className="text-center mt-10 p-10">
                <p>
                  No lead times found for this combination. Please, make sure all required options are selected.
                </p>
              </div>
            ) : (
              <>
                <BootstrapTable
                  wrapperClasses="table-responsive column-width-control"
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  bordered={true}
                  striped={false}
                  remote
                  keyField="productCode"
                  data={data || []}
                  columns={columnsForOutput}
                  rowStyle={rowStyle}
                ></BootstrapTable>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  )
}

export default TestLeadTimesPage;
