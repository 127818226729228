import React from 'react';

type Props = {
  customStyle?: string;
  color?: string;
};

const HelpIcon = ({ customStyle, color }: Props) => (
  <div className={customStyle}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1992 2.26758C14.8659 2.26758 17.3992 3.33424 19.2659 5.20091C21.1326 7.06758 22.1992 9.60091 22.1992 12.2676C22.1992 14.9342 21.1326 17.4676 19.2659 19.3342C17.3992 21.2009 14.8659 22.2676 12.1992 22.2676C9.53255 22.2676 6.99922 21.2009 5.13255 19.3342C3.26589 17.4676 2.19922 14.9342 2.19922 12.2676C2.19922 9.60091 3.26589 7.06758 5.13255 5.20091C6.99922 3.33424 9.53255 2.26758 12.1992 2.26758ZM12.1992 0.267578C5.59922 0.267578 0.199219 5.66758 0.199219 12.2676C0.199219 18.8676 5.59922 24.2676 12.1992 24.2676C18.7992 24.2676 24.1992 18.8676 24.1992 12.2676C24.1992 5.66758 18.7992 0.267578 12.1992 0.267578Z"
        fill={color ?? "#B8B8B8"} />
      <path
        d="M11.0668 13.0669V12.3336C11.0668 10.7336 13.4001 10.1336 13.4001 8.93359C13.4001 8.33359 12.8668 7.80026 12.0668 7.80026C11.0668 7.80026 10.6001 8.46693 10.2668 8.66693C10.1335 8.73359 10.0001 8.73359 9.8668 8.66693L9.0668 7.86693C8.93346 7.73359 8.93346 7.53359 9.0668 7.40026C9.0668 7.40026 10.2001 5.93359 12.4001 5.93359C14.2001 5.93359 15.6668 7.13359 15.6668 8.80026C15.6668 10.8669 13.0668 11.4669 13.0668 12.4669V13.0003C13.0668 13.2003 12.9335 13.3336 12.7335 13.3336H11.3335C11.2668 13.4003 11.0668 13.2669 11.0668 13.0669ZM12.0668 15.3336C12.8001 15.3336 13.4668 16.0003 13.4668 16.7336C13.4668 17.4669 12.8001 18.1336 12.0668 18.1336C11.3335 18.1336 10.6668 17.5336 10.6668 16.7336C10.6668 16.0003 11.3335 15.3336 12.0668 15.3336Z"
        fill={color ?? "#B8B8B8"} />
    </svg>
  </div>
);

export default HelpIcon;
