import axios from "axios";
import { getConfig } from "../../modules/Config/Config";

async function setProductModifier(
  plantCode: string,
  productCategory: string,
  modifier: any
): Promise<any> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.put<any>(
      `${backendConfig.url}/productmodifiers/${plantCode}/${productCategory}`,
      modifier,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in setProductModifier:", error);
    throw error;
  }
}

async function deleteProductModifier(
  plantCode: string,
  productCategory: any,
  modifierName: string
): Promise<any> {
  try {
    if (!plantCode || typeof plantCode !== "string") {
      throw new Error(
        `Invalid plantCode: ${plantCode}. Please provide a valid plantCode.`
      );
    }

    const backendConfig = await getConfig("backend");

    const response = await axios.delete<any>(
      `${backendConfig.url}/productmodifiers/${plantCode}/${productCategory}/${modifierName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in deleteProductModifier:", error);
    throw error;
  }
}

export { setProductModifier, deleteProductModifier };
