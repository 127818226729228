import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { IAuditLogList } from "../models/AuditLogModel";

async function getAuditLogs(
  plantCode: string,
  resource?: string,
  limit?: number,
  paginationToken?: string) : Promise<IAuditLogList> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<IAuditLogList>(`${backendConfig.url}/auditlogs/${plantCode}`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        resource: resource,
        limit: limit,
        paginationToken: paginationToken,
      },
    })
    .then((response) => response.data);
}

export { getAuditLogs };
